import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import { AuthContext } from "../../../context/AuthContext";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import SuccessTick from "../../../assets/images/icons/tick.png"
import axios from 'axios';
import moment from 'moment';
import WalletQRCode from '../../../components/ClientArea/WalletQRCode/WalletQRCode';

const Subscription = (
  {
    toggleActive,
    setToggleActive,
    name,
    darkMode,
  }
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [auth] = useState(localStorage.getItem('auth'));
  const [role] = useState(localStorage.getItem('role'));
  const [userId] = useState(localStorage.getItem("userId"));
  const [copied, setCopied] = useState(false);
  const [address, setAddress] = useState('');
  const [subscriptions, setSubcriptions] = useState([]);
  const [subscriptionsHistory, setSubscriptionsHistory] = useState([]);
  const [userObj, setUserObj] = useState({});
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successModalActive, setSuccessModalActive] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const inputRef = useRef(null);
  const [balance, setBalance] = useState(null);
  const [latestTransactions, setLatestTransactions] = useState([]);

  const getSubscriptions = () => {
    axios.get(`${apiUrl}/subscriptions`, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setSubcriptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching subscriptions:', error);
      });
  }

  const getSubscriptionHistory = () => {
    axios.get(`${apiUrl}/subscriptions/${userId}/all`, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setSubscriptionsHistory(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching subscriptions history:', error);
      });
  }

  const getUser = () => {
    axios.get(`${apiUrl}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setUserObj(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching subscriptions:', error);
      });
  }

  const buySubscriptionReq = async (e, subscriptionId) => {
    e.preventDefault();

    setSuccessMsg('');
    setErrorMessage('');
    setSuccessModalActive(true);

    const subscriptionObj = {
      userId,
      id: subscriptionId
    }
    axios.post(`${apiUrl}/subscriptions/buy`, subscriptionObj,  {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setSuccessMsg(response.data.message);
          setAddress(response.data.address);
        }
      })
      .catch((error) => {
        console.error('Error when buying subscription:', error);
        setErrorMessage(error.response.data.message);
      });
  }

  const handleCopyClick = () => {
    // Select the text inside the input
    inputRef.current.select();
    document.execCommand('copy');

    // Set the copied state to true
    setCopied(true);

    // Resetting the copied state after a short delay (for feedback)
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getWalletAddressBalance = () => {
    axios.get(`${apiUrl}/account/wallet/${address}`, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setBalance(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching balance:', error);
      });
  }

  const getWalletAddressLatestTransactions = () => {
    axios.get(`${apiUrl}/account/wallet/${address}/transactions/list`, {
      headers: {
        Authorization: `Bearer ${auth}`
      }
    })
      .then((response) => {
        if(response.data) {
          setLatestTransactions(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching transactions:', error);
      });
  }

  useEffect(() => {
    if(auth) {
      getSubscriptions();
      getSubscriptionHistory();
      getUser();
    }
  }, [auth])

  useEffect(() => {
    if(address) {
      getWalletAddressBalance();
      getWalletAddressLatestTransactions();
    }
  }, [address])

  useEffect(() => {
    if(Object.keys(userObj).length) {
      setAddress(userObj.address);
    }
  }, [userObj])
  const [isCardOpen, setIsCardOpen] = useState(true);

  // Function to toggle the card state
  const toggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  // console.log('subscriptions:', subscriptions);
  // console.log('subscription history:', subscriptionsHistory)
  // console.log('userObj:', userObj);
  // console.log('balance:', balance);
  // console.log('transactions', latestTransactions);
  return (
    <AuthContext.Consumer>
      {(context) => (
      <>
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
        <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          />
        <div className="z-theme-wrapper">
          <Header 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
            name={name}
            darkMode={darkMode}
          />
          <div className='section-padding'>
            <div className="container-fluid">
              <div className="row mb-30">
                <div className="col-lg-12">
                  <div className="page-bradcrumbs">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Subscription</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="row mb-60">
                <div className="col-lg-10 col-md-10 col-xl-6 col-xxl-6">
                  <div className={`ds-main-card wallet-address-card ${isCardOpen ? 'open' : 'closed'}`}>
                      <button className="toggle-card" onClick={toggleCard}>
                        {/* Conditional rendering of icons based on the card state */}
                        {isCardOpen ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )}
                      </button>
                    <h3>Wallet Address</h3>
                    <div className="scan-wallet-img">
                      {/* <img src={WalletScan} alt="Wallet Scan" className='bg-dark-wallet' />
                      <img src={WhiteWalletScan} alt="Wallet Scan" className='bg-white-wallet' /> */}
                      <WalletQRCode walletAddress={address} className='bg-white-wallet' />
                    </div>
                    <div className="wallet-or-holder">
                      <span>OR</span>
                    </div>
                    <div className="input-holder copy-ip-input mb-30 copy-wallet-subscription">
                      <label>Copy Wallet Address</label>
                      <input
                        type="text" 
                        className="from-control"
                        id="myInput"
                        ref={inputRef}
                        value={address}
                        placeholder='0xf08e0440Fd128d43F493C701277488Dbe650d6F2'
                      />
                      <span 
                        className={`btn btn-theme btn-sm ${copied ? 'copied' : ''}`}
                        onClick={handleCopyClick}
                      >
                        {copied ? 
                          <FontAwesomeIcon icon={faCheck} />
                          :
                          <svg width="16px" height="16px" className="svg-icon" viewBox="0 0 16 16" fill="none"><path fill="currentColor" d="M4 4V0.8C4 0.587827 4.08429 0.384344 4.23432 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H15.2C15.4122 0 15.6157 0.0842854 15.7657 0.234315C15.9157 0.384344 16 0.587827 16 0.8V11.2C16 11.4122 15.9157 11.6157 15.7657 11.7657C15.6157 11.9157 15.4122 12 15.2 12H12V15.1944C12 15.6392 11.6408 16 11.1944 16H0.8056C0.699778 16.0001 0.594974 15.9793 0.497186 15.9389C0.399399 15.8984 0.310548 15.8391 0.23572 15.7643C0.160893 15.6895 0.101557 15.6006 0.0611086 15.5028C0.0206607 15.405 -0.000104846 15.3002 3.98048e-07 15.1944L0.00240041 4.8056C0.00240041 4.3608 0.3616 4 0.808 4H4ZM5.6 4H11.1944C11.6392 4 12 4.3592 12 4.8056V10.4H14.4V1.6H5.6V4Z"></path></svg>
                          }
                      </span>
                    </div>
                    <div className="note-wallet-holder">
                      <p><span>Crucial Notice:</span> To prevent any risk of fund loss, it is imperative to choose the BNB Smart Chain (BEP20) network for your transactions. Ensure the safety of your assets by making this selection promptly</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="subscription-main-tabs">
                   <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-Tab1-tab" data-bs-toggle="pill" data-bs-target="#pills-Tab1" type="button" role="tab" aria-controls="pills-Tab1" aria-selected="true">Plan & Pricing</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-2-tab" data-bs-toggle="pill" data-bs-target="#pills-2" type="button" role="tab" aria-controls="pills-2" aria-selected="false">Subscription History</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      {
                        subscriptions.length ?
                        <>
                          {
                            subscriptions.map(subscription => (
                              <div className="tab-pane fade show active" id="pills-Tab1" role="tabpanel" aria-labelledby="pills-Tab1-tab" tabIndex="0" key={subscription._id}>
                                {/* <form onSubmit={e => buySubscriptionReq(e, subscription._id)}> */}
                                  <div className="subscription-inner-tabs">
                                    <div className="col-lg-6 col-xl-4 col-xxl-4 col-md-8">
                                      <div className="pricing-card-popular pricing-card-holder">
                                        <div className="pricing-label">
                                          <span>Pro</span>
                                        </div>
                                        <div className="pricing-value">
                                          <h3>USDT {subscription.rate} / {subscription.type}</h3>
                                          <button
                                            className='btn-buy'
                                            data-bs-toggle="modal"
                                            data-bs-target="#BuySubscriptionRequest"
                                            onClick={e => buySubscriptionReq(e, subscription._id)}
                                          >
                                            Buy
                                          </button>
                                        </div>
                                        <div className="pricing-list-item">
                                          <p> <span>10</span> Active SmartTrades</p>
                                          <p> <span>17</span> Running Signal Bots</p>
                                          <p> <span>8</span> Running Grid</p>
                                          <p> <span>5</span> Running Single-Pair DCA Bots</p>
                                          <p> <span>25</span> Active DCA Deals</p>
                                        </div>
                                        <div className="pricing-card-divider"></div>
                                        <div className="pricing-more-list">
                                          <span>Multi-pair DCA <div className="only-bot">Only in MM</div> </span>
                                          <p>Reinvest Profit</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                {/* </form> */}
                              </div>
                            ))
                          }
                        </>
                        :
                        <div className='text-center'>
                          <p className='text-gray'>Loading...</p>
                        </div>
                        }
                      <div className="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-2-tab" tabIndex="0">
                        <div className="row mb-50">
                          <div className="col-lg-12">
                            <div className="ds-card-title">
                              <h3>Subscription history</h3>
                            </div>
                            <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                              {
                                subscriptionsHistory.length ?
                                <table  className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Type</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Name</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Duration</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Status</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Start Date</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>End Date</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      {
                                        role === "admin" ?
                                        <th scope="col">
                                          <div className="table-head-th">
                                            <span>Actions</span>
                                            <button>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </th>
                                        :
                                        null
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      subscriptionsHistory.map(subscription => (
                                      <tr className="tr-bg-dark" key={subscription._id}>
                                        <td>{subscription.type}</td>
                                        <td>{subscription.name}</td>
                                        <td>{subscription.rate}</td>
                                        <td>{subscription.duration}</td>
                                        <td>
                                          <div className="account-type-td">
                                            <ul>
                                              <li>
                                                <span>{subscription.status}</span>
                                              </li>
                                            </ul>
                                          </div>	
                                        </td>
                                        <td>{moment(subscription.startDate).format("LL")}</td>
                                        <td>{moment(subscription.endDate).format("LL")}</td>
                                        {
                                          role === "admin" ?
                                          <td>
                                            <div className="connect-wallet-td">
                                              <ul>
                                                <li>
                                                  <button 
                                                    className='connect-direct'
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#EditModal"
                                                    onClick={e => {
                                                      e.preventDefault();

                                                      setSubscriptionStatus(subscription.status);
                                                    }}
                                                  >
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M13.9681 2.03174C13.0364 1.10006 11.5258 1.1001 10.5942 2.03184L2.62752 9.99947C2.35663 10.2704 2.16623 10.6111 2.0775 10.9838L1.3471 14.0515C1.30689 14.2204 1.35718 14.3981 1.47995 14.5208C1.60273 14.6436 1.78041 14.6939 1.94932 14.6537L5.01682 13.9233C5.38962 13.8345 5.73047 13.6441 6.00144 13.3731L13.9682 5.40541C14.8997 4.47375 14.8997 2.96334 13.9681 2.03174ZM11.3014 2.7389C11.8424 2.19773 12.7198 2.19771 13.261 2.73885C13.802 3.27994 13.8021 4.15722 13.261 4.69835L12.6668 5.29265L10.7072 3.33309L11.3014 2.7389ZM10.0002 4.04023L11.9598 5.99979L5.29429 12.666C5.15419 12.8061 4.97795 12.9046 4.7852 12.9505L2.5081 13.4927L3.0503 11.2155C3.09618 11.0227 3.19462 10.8466 3.33466 10.7065L10.0002 4.04023Z" fill="#415B73"/>
                                                  </svg>
                                                  </button>
                                                </li>
                                                {/* <li>
                                                  <button className='connect-direct' data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none">
                                                    <path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"/>
                                                  </svg>
                                                  </button>
                                                </li> */}
                                              </ul>
                                            </div>
                                          </td>
                                          :
                                          null
                                        }
                                      </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                                :
                                <div className='text-center'>
                                  <p className='text-gray'>No data found!</p>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="DeleteModalLabel">Delete Subscription</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure? You can't undo this action afterwards.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-danger">Delete</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            {/* Edit Modal */}
            <div className="modal fade" id="EditModal" tabIndex="-1" aria-labelledby="EditModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg Edit-modal-holder">
                <form action="">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="EditModalLabel">Change Status</h1>
                    </div>
                    <div className="modal-body">
                        <div className="input-holder mb-20">
                          <label htmlFor="">Status</label>
                          <select className="form-select" aria-label="Default select example" value={subscriptionStatus}>
                            <option defaultValue disabled value="">Select status</option>
                            <option value="Inactive">Inactive</option>
                            <option value="Active">Active</option>
                          </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="submit" className="btn btn-theme">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* Edit Modal */}
            {/* Create subscription request */}
            {
              successModalActive &&
              <div className="modal fade" id="BuySubscriptionRequest" tabIndex="-1" aria-labelledby="BuySubscriptionRequestLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg Edit-modal-holder">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="BuySubscriptionRequestLabel">
                        {
                          successMsg ? "Success" : null
                        }
                        {
                          errorMessage ? "Alert" : null
                        }
                      </h1>
                    </div>
                    <div className="modal-body">
                      <div className='success-request-alert'>
                        {
                          errorMessage ?
                          <p className='text-danger'>
                            {errorMessage}
                          </p>
                          :
                          null
                        }
                        {
                          successMsg ?
                          <p className='text-success'> <img src={SuccessTick} alt="tick" />
                            {successMsg}
                          </p>
                          :
                          null
                        }
                      </div>
                      <div className="input-holder copy-ip-input mb-30">
                        <label className='text-dark'>Please deposit subscription amount in the following address</label>
                        <input
                          type="text" 
                          className="from-control text-dark"
                          id="myInput"
                          readOnly
                          ref={inputRef}
                          value={address}
                          placeholder='Wallet address'
                        />
                        <span 
                          className={`btn btn-theme btn-sm ${copied ? 'copied' : ''}`}
                          onClick={handleCopyClick}
                        >
                          {copied ? 
                            <FontAwesomeIcon icon={faCheck} />
                            :
                            <svg width="16px" height="16px" className="svg-icon" viewBox="0 0 16 16" fill="none"><path fill="currentColor" d="M4 4V0.8C4 0.587827 4.08429 0.384344 4.23432 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H15.2C15.4122 0 15.6157 0.0842854 15.7657 0.234315C15.9157 0.384344 16 0.587827 16 0.8V11.2C16 11.4122 15.9157 11.6157 15.7657 11.7657C15.6157 11.9157 15.4122 12 15.2 12H12V15.1944C12 15.6392 11.6408 16 11.1944 16H0.8056C0.699778 16.0001 0.594974 15.9793 0.497186 15.9389C0.399399 15.8984 0.310548 15.8391 0.23572 15.7643C0.160893 15.6895 0.101557 15.6006 0.0611086 15.5028C0.0206607 15.405 -0.000104846 15.3002 3.98048e-07 15.1944L0.00240041 4.8056C0.00240041 4.3608 0.3616 4 0.808 4H4ZM5.6 4H11.1944C11.6392 4 12 4.3592 12 4.8056V10.4H14.4V1.6H5.6V4Z"></path></svg>
                            }
                        </span>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            }
            {/* Create subscription request */}
          </div>
        </div>
      </div>
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default Subscription