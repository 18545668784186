import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';

const WalletQRCode = ({ walletAddress }) => {
  const [qrCodeValue, setQRCodeValue] = useState('');

  useEffect(() => {
    setQRCodeValue(walletAddress);
  }, [walletAddress]);

  return (
    <div>
      {qrCodeValue && (
        <QRCode
          value={qrCodeValue}
          size={256} // Adjust the size as needed
          level={'H'} // Error correction level (L, M, Q, H)
          includeMargin={true}
        />
      )}
    </div>
  );
};

export default WalletQRCode;