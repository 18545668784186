import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../../../components/ClientArea/sidebar/Sidebar';
import Header from '../../../../components/ClientArea/header/Header';
import { AuthContext } from '../../../../context/AuthContext';
import SuccessMessageModal from '../../../../components/ClientArea/Modals/SuccessModal';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';

const EditTicket = ({
  toggleActive,
  setToggleActive,
  name,
  darkMode,
}) => {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const {
    alertDialogModalStatus,
    setAlertDialogModalStatus,
    setAlertDialogModalMessage,
  } = context;
  const validator = useRef(new SimpleReactValidator());
  const [auth] = useState(localStorage.getItem('auth'));
  const [role] = useState(localStorage.getItem('role'));
  const [email] = useState(localStorage.getItem('email'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [priority, setPriority] = useState('');
  const [status, setStatus] = useState('');
  const [file, setFile] = useState('');
  const [oldFile, setOldFile] = useState('');
  const [, forceUpdate] = useState();

  useEffect(() => {
    if (id) {
      getTicket(id);
    }
  }, [id]);

  const getTicket = async (ticketId) => {
    try {
      const response = await axios.get(`${apiUrl}/support/${ticketId}?userId=${userId}&role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });

      if (response.data.success) {
        const { data } = response.data;
        // Set the ticket details to state
        setSubject(data.subject);
        setMessage(data.message);
        setOldFile(data.docUrl)
        setPriority(data.priority);
        setStatus(data.status);
      } else {
        // Handle failure to fetch ticket details
        console.error('Failed to fetch ticket details:', response.data.message);
      }
    } catch (error) {
      // Handle network or other errors
      console.error('Error fetching ticket details:', error.message);
    }
  };

  const handleKycDocView = (e, docUrl) => {
    e.preventDefault();

    // Open the document in a new tab while uploading on the backend server
    // window.open(apiUrl + "/" + docUrl, '_blank');

    window.open(docUrl, '_blank');
  };

  const supportSubmitHandler = async (e) => {
    e.preventDefault();

    const formValid = validator.current.allValid();

    if (!formValid) {
      validator.current.showMessages(true);
      forceUpdate(1);
      return;
    } else {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('userId', userId);
      formData.append('subject', subject);
      formData.append('priority', priority);
      formData.append('status', status);
      formData.append('file', file);
      formData.append('message', message);

      try {
        const url = `${apiUrl}/support/${id}/update?userId=${userId}&role=${role}`;

        const response = await axios.put(url, formData, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });

        if (response.data) {
          setSubject('');
          setPriority('');
          setStatus('');
          setFile('');
          setMessage('');
          setAlertDialogModalStatus(true);
          setAlertDialogModalMessage('Ticket updated successfully');
          navigate('/tickets');
        }
      } catch (error) {
        console.error('Update failed:', error);
      }
    }
  };

  return (
    <AuthContext.Consumer>
      {(context) => (
        <>
          {alertDialogModalStatus ? <SuccessMessageModal /> : null}
          <div
            className={`${toggleActive ? 'main-wrapper z-aside-structure aside-active' : 'main-wrapper z-aside-structure'} ${
              context.darkMode ? 'dark-mode-wrapper' : ''
            }`}
          >
            <Sidebar toggleActive={toggleActive} setToggleActive={setToggleActive} />
            <div className="z-theme-wrapper">
              <Header
                toggleActive={toggleActive}
                setToggleActive={setToggleActive}
                name={name}
                darkMode={darkMode}
              />
              <div className="section-padding">
                <div className="container-fluid">
                  <div className="row mb-30">
                    <div className="col-lg-12">
                      <div className="page-bradcrumbs">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="/tickets">Tickets</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Ticket</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mb-50">
                    <div className="col-lg-6">
                      <div className="ds-card-title support-title">
                        <h3>Edit Ticket</h3>
                        <p>Get in touch with Market Making Partner support using Open Ticket</p>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-50 justify-content-center">
                    <div className="col-lg-12">
                      <div className="ds-main-card">
                        <form onSubmit={(e) => supportSubmitHandler(e)}>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input-holder mb-20">
                                <label htmlFor="">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter your email"
                                  value={email}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="input-holder mb-20">
                                <label htmlFor="">Subject</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Subject"
                                  value={subject}
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                                <p className="text-danger">
                                  {validator.current.message('subject', subject, 'required')}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="input-holder mb-20">
                                <label>Priority</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  value={priority}
                                  onChange={(e) => setPriority(e.target.value)}
                                >
                                  <option value="">Select Priority</option>
                                  <option value="Low">Low</option>
                                  <option value="Medium">Medium</option>
                                  <option value="High">High</option>
                                </select>
                                <p className="text-danger">
                                  {validator.current.message('priority', priority, 'required')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="input-holder mb-20 upload-v-img n-upload-img ">
                                <label htmlFor="">Upload Image</label>
                                <div className="upload-btn-wrapper">
                                  <button className="btn">
                                    <svg
                                      aria-hidden="true"
                                      focusable="false"
                                      data-prefix="fas"
                                      data-icon="upload"
                                      className="svg-inline--fa fa-upload "
                                      role="img"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
                                      ></path>
                                    </svg>
                                    Attach File
                                  </button>
                                  <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                                  {file ? (
                                    <div className="my-2 text-center">
                                      <p>{file.name}</p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className='connect-wallet-td mb-30'>
                                <ul>
                                  <li>
                                    <span>Uploaded File</span> <button className='connect-btn' onClick={e => handleKycDocView(e, oldFile)}>View</button>
                                  </li>
                                </ul>   
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <div className="input-holder mb-20">
                                <label>Message</label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows="5"
                                  placeholder="Add message"
                                  value={message}
                                  onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                                <p className="text-danger">
                                  {validator.current.message('message', message, 'required')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-lg-6">
                              <div className="input-holder mb-20">
                                <label>Status</label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  value={status}
                                  onChange={(e) => setStatus(e.target.value)}
                                >
                                  <option value="">Select status</option>
                                  <option value="Pending">Pending</option>
                                  <option value="In Progress">In Progress</option>
                                  <option value="Closed">Closed</option>
                                </select>
                                <p className="text-danger">
                                  {validator.current.message('status', status, 'required')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <button className="btn btn-theme" type="submit">
                            Update
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </AuthContext.Consumer>
  );
};

export default EditTicket;