import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import Loader from "../../../components/Frontend/loader/Loader";

function PrivacyPolicy() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />

      <Header />
      {/* Policy Banner */}
			<section className="policy-holder">
				<div className="container">
					<div className="row">
						<div className="main-policy-heading">
							<h3>MarketMaking – Privacy Policy</h3>
						</div>
					</div>
				</div>
			</section>
			{/* Policy Banner */}

			{/* Policy Content */}
			<section className="policy-content-main">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="policy-content-holder">
								<div className="policy-content-detail">
									<h4>1. Introduction</h4>
									<p>MarketMaking LTD. (“Company” “we”, “our” or “us”), is committed to protecting the privacy of anyone who accesses, uses, or registers to our Services (defined below). We have published this Privacy Policy (“Policy“) to outline our practices with respect to collecting, using, and disclosing your information when you use our Services. We encourage you to read this Policy carefully and use it to make informed decisions.</p>
									<p className="pt-20">Our Services may be connected by “hyperlinks” to other third-party’s sites. We are not responsible in any way for the privacy practices on other third-party’s sites and advise you to review the privacy policies on those linked sites before using them.</p>
								</div>
								<div className="policy-content-detail">
									<h4>2. Our Services</h4>
									<p>To get a better sense on how and from whom we collect data, it is useful to start with a brief overview of our Services. The Company is the owner of a proprietary software service that allows its end users to create and configure multifaceted trades for execution on third-party online cryptocurrency exchanges by creating their own trading models or following signals from various third party group leaders through channels which are integrated or created within Company’s software service provided on its Platform (collectively, “ <strong>Services</strong> ”).</p>
									<p className="pt-20">By using Company’s Services, you agree to the collection and use of your personal information as described in this policy as well as to Company’s Terms of Service. Any significant changes to this policy – will be posted on Company’s website <a href="https://mm.netrosol.com" target="_blank">https://mm.netrosol.com</a> (“Website”).</p>
									<p className="pt-20">This Policy applies to the information we collect from any person using or interacting with our Services, as well as any person visiting our Website or our web interface (collectively, “Users”).</p>
								</div>
								<div className="policy-content-detail">
									<h4>3. What Types of Data We Collect?</h4>
									<p>We collect two types of data from you: Personal Data (“ <strong>Personal Data</strong> “) and non-Personal Data. Personal Data means any information which may potentially allow your identification with reasonable means (for example, email address or name). Non-Personal Data, by contrast, can be defined as any information that does not relate to an identified or identifiable natural person. This may include, for example, your aggregated usage information and technical information transmitted by your device (for example, the device you use, the type of browser and operating system your device uses, language preference, access time, etc.). This section sets out how and when we collect those types of data from you.</p>
									<ul className="policy-list">
										<li>
											<p><span>Contact Information.</span> When you access our Services, we collect your username, first name, surname, country, Telegram ID and Discord account (including Discord ID and Discord username), as well as any contact information you make public using Telegram or Discord.</p>
										</li>
										<li>
											<p><span>Transaction Information. </span> Payments you make using our Services will typically be processed by the payment processor (e.g. PayPal, Coinpayments, etc.), and we will not collect or store your financial data (e.g. your credit card numbers or bank account details). We may still however receive your non-financial Personal Data related to your payment, such as your name, billing address, e-mail address and the items purchased, in order to fulfill your purchase and for our accounting purposes.</p>
										</li>
										<li>
											<p><span>Exchange Information.</span>When you continue with our onboarding process, you shall provide us with the details of your API keys and API secrets. Moreover, Company may collect from you additional details regarding your exchange and crypto investments, e.g. your portfolio size, cryptocurrencies allocation and availability.</p>
										</li>
										<li>
											<p><span>Usage Information.</span>When you use our Services, we collect certain data about your usage interaction with our Services. Such information may include geolocation data, clicks, pages viewed, channels membership, and other data which relates to your activity in the Services.</p>
										</li>
										<li>
											<p><span>Technical Information.</span> When you use our Services, we collect technical information about your device. Such information may include geolocation data, IP address, unique identifiers (e.g. MAC address and UUID), operating system, browsing history, language, mobile carrier, device information etc.</p>
										</li>
									</ul>
								</div>
								<div className="policy-content-detail">
									<h4>4. Tracking Technologies</h4>
									<p>When you visit or access our Services (for example when you visit our websites), we use (and authorize third parties to use) web beacons, cookies, pixels, scripts, tags and other technologies (“ <strong>Tracking Technologies</strong> “).</p>
									<p className="pt-20">The Tracking Technologies allow us to automatically collect information about you and your online behavior, as well as your device (for example your computer or mobile device), for different purposes, such as in order to enhance your navigation on our Services, improve our Services’ performance and customize your experience on our Services. We also use this information to collect statistics about the usage of our Services, perform analytics, deliver content which is tailored to your interests and administer services to our Users, advertisers, publishers, customers and partners. We also allow third parties to collect information about you through Tracking Technologies.</p>
								</div>
								<div className="policy-content-detail">
									<h4>5. How We Protect Your Data</h4>
									<p>We have implemented administrative, technical, and physical safeguards to help prevent unauthorized access, use, or disclosure of your Personal Data. While we seek to protect your information to ensure that it is kept confidential, we cannot guarantee the security of any information. You should be aware that there is always some risk involved in transmitting information over the internet and that there is also some risk that others could find a way to thwart our security systems. Therefore, we encourage you to exercise discretion regarding the Personal Data you choose to disclose.</p>
								</div>
								<div className="policy-content-detail">
									<h4>6. Retention</h4>
									<p>We will retain your personal information for as long as necessary to operate our Website or provide Services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our policies. Retention periods shall be determined taking into account the type of information that is collected and the purpose for which it is collected for, bearing in mind the requirements applicable to the situation and the need to destroy outdated, unused information at the earliest reasonable time.</p>
								</div>
								<div className="policy-content-detail">
									<h4>7. Minors</h4>
									<p>We do not knowingly collect or solicit Personal Data from anyone under the age of consent (as determined under the applicable laws where the individual resides; “ <strong>AgeofConsent</strong> ”). By accessing, using or interacting with our Services, you certify to us that you are not under the Age of Consent.</p>
									<p className="pt-20">
									In the event that we learn that we have collected Personal Data from an individual under the Age of Consent without verification of parental consent, we will delete that information upon discovery. If you believe that we might have any information from or about an individual under the Age of Consent, then please contact us through the contact details available below.</p>
								</div>
								<div className="policy-content-detail">
									<h4>8. Updates to this Policy</h4>
									<p>We reserve the right to change this Policy at any time. The most current version will always be posted on our Website (as reflected in the “Last Updated” heading). You are advised to check for updates regularly. By continuing to access or use our Services after any revisions become effective, you agree to be bound by the updated Policy.</p>
								</div>
								<div className="policy-content-detail">
									<h4>9. Further Information</h4>
									<p>If you have any further questions regarding the data collected, or how we use it, please contact us via <a href="mailto:support@mm.netrosol.com">support@mm.netrosol.com</a>.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
