import React from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";

class Register extends React.Component {
  constructor() {
    super();

    this.state = {
      username: "",
      email: "",
      apiUrl: process.env.REACT_APP_API_URL,
      password: "",
      reference: "",
      refFromUrl: false,
      usernameErr: "",
      emailErr: "",
      passwordErr: "",
      referenceErr: ""
    };
  }

  componentDidMount() {
    // Get the reference from the URL parameter
    const urlParams = new URLSearchParams(window.location.search);
    const refParam = urlParams.get("ref");

    if (refParam) {
      // If the ref parameter exists, set it in the state
      this.setState({ 
        reference: refParam, 
        refFromUrl: true
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { username, email, password, reference } = this.state;

    if (!username) {
      this.setState({
        usernameErr: "Username is required!",
      });
    } else if (!email) {
      this.setState({
        emailErr: "Email is required!",
      });
    } else if (!password) {
      this.setState({
        passwordErr: "Password is required!",
      });
    } else {
      const data = {
        username: username,
        email: email,
        password: password,
        reference: reference,
      };

      // console.log("data : ", data);

      axios
        .post(`${this.state.apiUrl}/register`, data)
        .then((res) => {
          if(res.data) {
            alert(res.data.message)
          }
          // console.log(res.data);
          // localStorage.setItem("userId", res.data.userId);
          // localStorage.setItem("address", res.data.address);
          // localStorage.setItem("email", res.data.email);
          // localStorage.setItem("username", res.data.username);
          // localStorage.setItem("auth", res.data.token);
          // localStorage.setItem("role", res.data.role);
          // localStorage.setItem("mode", false); // unselected dark mode

          setTimeout(() => {
            this.setState({
              username: "",
              email: "",
              password: "",
              refFromUrl: false
            });

            // if (res.data.role === "admin") {
            window.location.replace("/");
            // } else {
            //   window.location.replace("/tele-group");
            // }
          }, 500);
        })
        .catch((error) => {
          // console.log(error);
          console.log(error.response.data.message);
        });
    }
  };

  resetErrors = () => {
    this.setState({
      usernameErr: "",
      emailErr: "",
      passwordErr: "",
      referenceErr: "",
    });
  };

  render() {
    return (
      <div className="login-wrapper">
        <div className="login-left">
          <div className="login-logo">
            <a href="/">
              MarketMaking
            </a>
          </div>
          <div className="login-left-detail">
            <h2>Welcome to MarketMaking App</h2>
            <p>MarkingMaking is the platform for our members to manage their Trading through crypto bot.</p>
          </div>
        </div>
        <div className="login-right">
        <form action="" className="login-form">
                <h4>Register Your Account</h4>
                <div className="input-holder mb-20">
                  <label htmlFor="">Username:</label>
                  <input
                    type="text"
                    placeholder="Enter username"
                    className="from-control"
                    value={this.state.username}
                    onClick={() =>
                      this.setState({
                        usernameErr: "",
                      })
                    }
                    onChange={(e) =>
                      this.setState({
                        username: e.target.value,
                      })
                    }
                  />
                  {this.state.usernameErr ? (
                    <div className="text-danger">{this.state.usernameErr}</div>
                  ) : null}
                </div>
                <div className="input-holder mb-20">
                  <label htmlFor="">Email:</label>
                  <input
                    type="email"
                    placeholder="Enter email"
                    className="from-control"
                    value={this.state.email}
                    onClick={() =>
                      this.setState({
                        emailErr: "",
                      })
                    }
                    onChange={(e) =>
                      this.setState({
                        email: e.target.value,
                      })
                    }
                  />
                  {this.state.emailErr ? (
                    <div className="text-danger">{this.state.emailErr}</div>
                  ) : null}
                </div>
                <div className="input-holder mb-20">
                  <label htmlFor="">Password:</label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="from-control"
                    value={this.state.password}
                    onClick={() =>
                      this.setState({
                        passwordErr: "",
                      })
                    }
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                  />
                  {this.state.passwordErr ? (
                    <div className="text-danger">{this.state.passwordErr}</div>
                  ) : null}
                </div>
                {/* <div className="input-holder mb-40">
                  <label htmlFor="">Confirm Password:</label>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="from-control"
                  />
                </div> */}
                <div className="input-holder mb-20">
                  <label htmlFor="">Reference:</label>
                  <input
                    type="text"
                    placeholder="Enter reference"
                    className="from-control"
                    value={this.state.reference}
                    disabled={this.state.refFromUrl}
                    onClick={() =>
                      this.setState({
                        referenceErr: "",
                      })
                    }
                    onChange={(e) =>
                      this.setState({
                        reference: e.target.value,
                      })
                    }
                  />
                  {this.state.referenceErr ? (
                    <div className="text-danger">{this.state.referenceErr}</div>
                  ) : null}
                </div>
                <div className="input-holder check-privacy-policy-input">
                <div className="form-check">
                  <input className="" type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" for="flexCheckDefault">
                  By signing up I agree to the <Link to="/terms">terms</Link> and <Link to="/privacy-policy">privacy policy</Link>
                  </label>
                </div>
                </div>
                <div className="login-btn-form">
                  <button
                    className="btn btn-theme"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Register
                  </button>
                  <p>
                    Already registered! <NavLink to="/login">Login</NavLink>
                  </p>
                </div>
              </form>
        </div>
      </div>
    );
  }
}

export default Register;
