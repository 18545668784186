import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Header from "../../../../components/ClientArea/header/Header";
import "../../../../css/Main.css";
import "../../../../css/responsive.css";
import BinanceSVG from "../../../../assets/images/dashbaord-icons/binance.svg"
import Sidebar from "../../../../components/ClientArea/sidebar/Sidebar";
import { AuthContext } from "../../../../context/AuthContext";
import { Logos } from "../../../../helper/ExchangeLogos";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faEye, faEyeSlash, faEyedropper } from "@fortawesome/free-solid-svg-icons";

const EditApi = (props) => {
  const {
    toggleActive,
    setToggleActive,
    darkMode,
    name,
    locationState,
  } = props;
  const { id } = locationState;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [auth] = useState(localStorage.getItem('auth'));
  const [userId] = useState(localStorage.getItem('userId'));
  // const [role] = useState(localStorage.getItem('role'));
  const [apiHisotry, setApiHisotry] = useState([]);
  const [exchangeApi, setExchangeApi] = useState({});
  const [exchange, setExchange] = useState('');
  const [label, setLabel] = useState('');
  const [publicKey, setPublicKey] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [whiteIps, setWhiteIps] = useState('192.100.1.1');
  // const [exchangeErr, setExchangeErr] = useState('');
  const [labelErr, setLabelErr] = useState('');
  const [publicKeyErr, setPublicKeyErr] = useState('');
  const [privateKeyErr, setPrivateKeyErr] = useState('');
  // const [whiteIpsErr, setWhiteIpsErr] = useState('');
  const [uniqueLabelErr, setUniqueLabelErr] = useState('');
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword1) => !prevShowPassword1);
  };
  const [showPassword1, setShowPassword1] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    resetFields();

    if (label === "") {
      setLabelErr("Label is required")
    } else if (publicKey === "") {
      setPublicKeyErr("Public Key is required")
    } else if (privateKey === "") {
      setPrivateKeyErr("Private Key is required")
    } else {
      const data = {
        userId: userId,
        exchange: exchange,
        label: label,
        publicKey: publicKey,
        privateKey: privateKey,
        whiteIps: whiteIps,
      };

      // previous values 
      const { exchange: prevExchange, label: prevLabel, privateKey: prevPrivateKey, publicKey: prevPublicKey } = exchangeApi;

      const prevData = {
        prevExchange,
        prevLabel,
        prevPrivateKey,
        prevPublicKey
      }

      // if at least one api field is modified
      if(exchange != prevExchange || label != prevLabel || privateKey != prevPrivateKey || publicKey != prevPublicKey) {
        // add api update history
        await addApiHistory(prevData, exchange, label, privateKey, publicKey)

        // update api request
        updateApiRequest(data);
      } else {
        alert('No modifications have been made so far.')
      }
    }
  };

  const updateApiRequest = (dataObj) => {
    axios
      .patch(`${apiUrl}/apis/${id}/update`, dataObj, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          alert(res.data.message);
          getApiEditHistory();
          // window.location.replace('/trade');
        }
      })
      .catch((err) => {
        console.log(err.response);

        if (err.response) {
          if (err.response.data) {
            setUniqueLabelErr(err.response.data.labelErr);
          }
        }
      });
  }

  const addApiHistory = async (previousData, exchange, label, private_key, public_key) => {
    // console.log('prevData', previousData);

    const data = {
      previousData,
      updatedData: {
        exchange: exchange,
        label: label,
        privateKey: private_key,
        publicKey: public_key
      }
    }

    try {
      const response = await axios.post(`${apiUrl}/apis/${id}/create/history`, data, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })

      if(response.data) {
        return response.data;
      }
    } catch (error) {
      console.log(error);

      if (error.response) {
        if (error.response.data) {
          setUniqueLabelErr(error.response.data.labelErr);
          return null;
        }
      }
    }
  }

  const resetFields = () => {
    setLabelErr("");
    setPublicKeyErr("");
    setPrivateKeyErr("");
    setUniqueLabelErr("");
  }

  const copyText = (e) => {
    e.preventDefault();

    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("White IPs Copied: " + copyText.value);
  };
  
  const getExchangeApi = () => {
    axios
      .get(`${apiUrl}/apis/${id}/view`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          setExchangeApi(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getApiEditHistory = () => {
    axios
      .get(`${apiUrl}/apis/${id}/history`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        
        if (res.data) {
          // console.log('getApiEditHistory', res.data.data); 
          setApiHisotry(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(auth) {
      getExchangeApi();
      getApiEditHistory();
    }
  }, [auth])

  useEffect(() => {
    if(Object.keys(exchangeApi).length) {
      setExchange(exchangeApi.exchange);
      setLabel(exchangeApi.label);
      setPrivateKey(exchangeApi.privateKey);
      setPublicKey(exchangeApi.publicKey);
      setWhiteIps(exchangeApi.whiteIps);
    }
  }, [exchangeApi])
  const [isCardOpen, setIsCardOpen] = useState(true);

  // Function to toggle the card state
  const toggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };


  // console.log('exchangeApi:', exchangeApi);
  // console.log('apiHisotry', apiHisotry)
  return (
  <AuthContext.Consumer>
    {(context) => (
    <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
        <Sidebar 
          toggleActive={toggleActive}
          setToggleActive={setToggleActive}
        /> 
          <div className="z-theme-wrapper">
            <Header 
              toggleActive={toggleActive}
              setToggleActive={setToggleActive}
              name={name}
              darkMode={darkMode}
            />
          <div className="trade-page-holder">
            <section className="section-padding">
              <div className="container-fluid">
                <div className="row mb-30">
                  <div className="col-lg-12">
                    <div className="page-bradcrumbs">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                          <li className="breadcrumb-item"><Link to="/trade">Trading APIs</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Edit API</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="row mb-60">
                  {/* <div className="col-xxl-5 col-xl-6">
                      <div className="connect-exchange-left mb-40">
                        <h4>Quick connect an API's</h4>
                        <ul>
                          <li>
                            <p>1. Make sure you’ve accepted <a href="#/">Binance’s API Key Terms of Use</a></p>
                          </li>
                          <li>
                            <p>2. Click Connect to Binance</p>
                          </li>
                          <li>
                            <p>3. Login at Binance</p>
                          </li>
                          <li>
                            <p>4. Click ‘Continue’ when prompted</p>
                          </li>
                        </ul>
                      </div>
                      <div className="connect-exchange-left">
                        <h4>Why do we need your API keys?</h4>
                        <ul>
                          <li>
                            <p>API keys are a unique set of identifiers that allow Cornix to programmatically perform actions on your behalf in your crypto exchange account (Binance, ByBit, etc).</p>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  <div className="col-xxl-7 col-xl-6">
                    <div className={`ds-main-card ${isCardOpen ? 'open' : 'closed'}`}>
                      <button className="toggle-card" onClick={toggleCard}>
                        {/* Conditional rendering of icons based on the card state */}
                        {isCardOpen ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )}
                      </button>
                    <div className='page-inner-title'>
                        <h2>Update API</h2>
                    </div>
                    <form action="" className="trade-api-form">
                      <div className="input-holder mb-20">
                        <label htmlFor="">Exchange:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Exchange"
                          value={exchange}
                          disabled
                        />
                      </div>
                      {/* {exchangeErr ? (
                        <div>
                          <p className="text-danger">{exchangeErr}</p>
                        </div>
                      ) : null} */}
                      <div className="input-holder mb-20">
                        <label htmlFor="">Label:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Label"
                          value={label}
                          onChange={(e) => setLabel(e.target.value)}
                        />
                      </div>
                      {labelErr ? (
                        <div>
                          <p className="text-danger">{labelErr}</p>
                        </div>
                      ) : null}
                      {uniqueLabelErr ? (
                        <div>
                          <p className="text-danger">{uniqueLabelErr}</p>
                        </div>
                      ) : null}
                      <div className="input-holder mb-20 edit-api-show-btn-input">
                        <label htmlFor="">Public Key:</label>
                        <input
                           type={showPassword ? 'text' : 'password'}
                          placeholder="Add Public Key"
                          className="from-control"
                          value={publicKey}
                          onChange={(e) => setPublicKey(e.target.value)}
                        />
                         <span className="show-key-trade" onClick={togglePasswordVisibility}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                          </span>
                      </div>
                      {publicKeyErr ? (
                        <div>
                          <p className="text-danger">{publicKeyErr}</p>
                        </div>
                      ) : null}
                      <div className="input-holder mb-20 edit-api-show-btn-input">
                        <label htmlFor="">Private Key:</label>
                        <input
                          type={showPassword1 ? 'text' : 'password'}
                          className="form-control"
                          placeholder="Add Private Key"
                          value={privateKey}
                          onChange={(e) => setPrivateKey(e.target.value)}
                        />
                        <span className="show-key-trade" onClick={togglePasswordVisibility1}>
                            <FontAwesomeIcon icon={showPassword1 ? faEyeSlash : faEye} />
                          </span>
                      </div>
                      {privateKeyErr ? (
                        <div>
                          <p className="text-danger">{privateKeyErr}</p>
                        </div>
                      ) : null}
                      <div className="input-holder copy-ip-input mb-30">
                        <label htmlFor="">White IPs:</label>
                        <input
                          type="text"
                          disabled
                          value={whiteIps}
                          className="from-control"
                          id="myInput"
                          placeholder="White IPs"
                        />
                        <button
                          className="btn btn-theme btn-sm"
                          onClick={(e) => copyText(e)}
                        >
                          <svg width="16px" height="16px" className="svg-icon" viewBox="0 0 16 16" fill="none"><path fill="currentColor" d="M4 4V0.8C4 0.587827 4.08429 0.384344 4.23432 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H15.2C15.4122 0 15.6157 0.0842854 15.7657 0.234315C15.9157 0.384344 16 0.587827 16 0.8V11.2C16 11.4122 15.9157 11.6157 15.7657 11.7657C15.6157 11.9157 15.4122 12 15.2 12H12V15.1944C12 15.6392 11.6408 16 11.1944 16H0.8056C0.699778 16.0001 0.594974 15.9793 0.497186 15.9389C0.399399 15.8984 0.310548 15.8391 0.23572 15.7643C0.160893 15.6895 0.101557 15.6006 0.0611086 15.5028C0.0206607 15.405 -0.000104846 15.3002 3.98048e-07 15.1944L0.00240041 4.8056C0.00240041 4.3608 0.3616 4 0.808 4H4ZM5.6 4H11.1944C11.6392 4 12 4.3592 12 4.8056V10.4H14.4V1.6H5.6V4Z"></path></svg>
                        </button>
                      </div>
                      {/* {whiteIpsErr ? (
                        <div>
                          <p className="text-danger">{whiteIpsErr}</p>
                        </div>
                      ) : null} */}
                      <button
                        className="btn btn-theme"
                        onClick={(e) => submitHandler(e)}
                      >
                        Update
                      </button>
                    </form>
                    </div>
                  </div>
                </div>
                <div className="ds-card-title">
                  <h3>API Update History</h3>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                      {apiHisotry.length ? (
                        <table  className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Sr.#	</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Exchange</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Label</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Public Key	</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Private Key	</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="table-head-th">
                                  <span>Comments</span>
                                  <button>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                      <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                    </svg>
                                  </button>
                                </div>
                              </th>
                              {/* {
                                role === "admin" ?
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Action</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                :
                                null
                              } */}
                            </tr>
                          </thead>
                          <tbody>
                          {apiHisotry.map((api, index) => (
                            <tr className="tr-bg-dark" key={api._id}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="token-img">
                                  <div className="token-img-holder">
                                    <img src={Logos[api.previousValues.exchange.toLowerCase()]} alt={`${api.previousValues.exchange.toLowerCase()} logo`} width={25} />
                                  </div>
                                  <div className="token-img-detail">
                                    <span>{api.previousValues.exchange}</span>
                                  </div>
                                </div>
                              </td>
                              <td >
                                <div className="account-type-td">
                                  <ul>
                                    <li>
                                      <span>{api.previousValues.label}</span>
                                    </li>
                                  </ul>
                                </div>	
                              </td>
                              <td>
                                {api.previousValues.publicKey.substring(0, 15) + "*****"}
                              </td>
                              <td>
                                {api.previousValues.privateKey.substring(0, 15) + "*****"}
                              </td>
                              <td >
                                {moment(api.updatedAt).format("LLL")}
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                        ) : (
                        <div className="text-center">
                          <p className="text-danger">No data yet available!</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
    </div>
    )}
  </AuthContext.Consumer>
  );
}

export default EditApi;
