import React from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import Header from "../../../components/ClientArea/header/Header";
import "../../../css/Main.css";
import "../../../css/responsive.css";
import { Logos } from "../../../helper/ExchangeLogos";
import Sidebar from "../../../components/ClientArea/sidebar/Sidebar";
import { AuthContext } from "../../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import SearchResult from "../../../assets/images/search-result/result.png";
import Notfound from "../../../assets/images/search-result/not-found.png"
import moment from "moment";

class Dashboard extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      apiUrl: process.env.REACT_APP_API_URL,
      auth: localStorage.getItem("auth"),
      role: localStorage.getItem("role"),
      userId: localStorage.getItem("userId"),
      display: false,
      exchangeApis: [],
      exchanges: [],
      exchange: "",
      isCardOpen: true,
      isOpen: false,
      selectedExchange: null,
      label: "",
      publicKey: "",
      privateKey: "",
      whiteIps: "192.100.1.1",
      exchangeErr: "",
      labelErr: "",
      publicKeyErr: "",
      privateKeyErr: "",
      whiteIpsErr: "",
      uniqueLabelErr: "",
      search: '',
      apiId: '',
      apiBalance: ''
    };
  }

  toggleCard = () => {
    this.setState((prevState) => ({
      isCardOpen: !prevState.isCardOpen,
    }));
  };

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();

    this.setState({
      exchangeErr: "",
      labelErr: "",
      publicKeyErr: "",
      privateKeyErr: "",
      whiteIpsErr: "",
      uniqueLabelErr: "",
    });

    const {
      auth,
      userId,
      exchange,
      label,
      publicKey,
      privateKey,
      whiteIps,
    } = this.state;
    if (exchange === "") {
      this.setState({
        exchangeErr: "Exchange is required",
      });
    } else if (label === "") {
      this.setState({
        labelErr: "Label is required",
      });
    } else if (publicKey === "") {
      this.setState({
        publicKeyErr: "Public Key is required",
      });
    } else if (privateKey === "") {
      this.setState({
        privateKeyErr: "Private Key is required",
      });
    } else {
      const data = {
        userId: userId,
        exchange: exchange,
        label: label,
        publicKey: publicKey,
        privateKey: privateKey,
        whiteIps: whiteIps,
      };

      axios
        .post(`${this.state.apiUrl}/apis/create`, data, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        })
        .then((res) => {
          // console.log(res.data);

          if (res.data) {
            alert(res.data.message);
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err.response);

          if (err.response) {
            if (err.response.data) {
              this.setState({
                uniqueLabelErr: err.response.data.labelErr,
              });
            }
          }
        });
    }
  };

  getAllExchanges = () => {
    axios
      .get(`${this.state.apiUrl}/exchange/all`, {
        headers: {
          Authorization: `Bearer ${this.state.auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          this.setState({
            exchanges: res.data.exchanges,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  copyText = (e) => {
    e.preventDefault();

    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("White IPs Copied: " + copyText.value);
  };

  resetError = (e) => {
    e.preventDefault();

    this.setState({
      exchangeErr: "",
      labelErr: "",
      publicKeyErr: "",
      privateKeyErr: "",
      whiteIpsErr: "",
      uniqueLabelErr: "",
    });
  };

  getExchangeApis = () => {
    axios
      .get(`${this.state.apiUrl}/apis/${this.state.userId}`, {
        headers: {
          Authorization: `Bearer ${this.state.auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          this.setState({
            exchangeApis: res.data.apis,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleToggleDropdown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleSelectExchange = (exchange) => {
    this.setState({ 
      selectedExchange: exchange,
      exchange: exchange,
      isOpen: false 
    });
  };

  updateApiId = (e, api_id) => {
    e.preventDefault();

    this.setState({
      apiId: api_id,
    })
  }

  markAsDeleted = async (e) => {
    e.preventDefault();

    const { apiUrl, userId, role, auth, apiId } = this.state;

    // console.log('api id:', apiId);
    try {  
      // Make a DELETE request to the server
      const response = await axios.delete(`${apiUrl}/apis/${apiId}/del?userId=${userId}&role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
  
      if (response.data.success) {
        alert('API marked as deleted.');
        this.setState({
          apiId: '',
        })
        this.getExchangeApis();
      }
    } catch (error) {
      // Handling unexpected errors, e.g., to show a generic error message or log the error
      console.error('Unexpected error:', error);
    }
  }

  chargeSuccessFee = async (e) => {
    e.preventDefault();

    const {
      userId,
      apiId,
      apiBalance,
      apiUrl,
      auth,
    } = this.state;

    if(!apiBalance) {
      alert("Enter API profit balance");
    } else {
      // console.log('api balance', apiBalance);
      const data = {
        userId: userId,
        apiId: apiId,
        totalProfit: apiBalance
      }
  
      axios.post(`${apiUrl}/affiliates/success-fee/calculate`, data, {
        headers: {
          Authorization: `Bearer ${auth}`,
        }
      })
        .then(res => {
          // console.log("success fee charged", res.data);
  
          if(res.data) {
            alert(res.data.message);
            this.setState({
              apiBalance: ''
            })
          }
        })
        .catch(err => {
          console.log(err);
  
          if(err.response) {
            if(err.response.data) {
              alert(err.response.data.message);
              this.setState({
                apiBalance: ''
              })
            }
          }
        })
    }
  }

  componentDidMount() {
    this.getExchangeApis();
    this.getAllExchanges();
  }

  render() {
    const {
      toggleActive,
      setToggleActive,
      darkMode,
      name,
    } = this.props;

    const { exchanges } = this.state;
    const { isCardOpen } = this.state;
    
    // console.log('apiId', this.state.apiId)
    return (
      
      <AuthContext.Consumer>
      {(context) => (
      <div className={`${this.props.toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
          <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          /> 
            <div className="z-theme-wrapper">
              <Header 
                toggleActive={toggleActive}
                setToggleActive={setToggleActive}
                name={name}
                darkMode={darkMode}
              />
            <div className="trade-page-holder">
              <section className="section-padding">
                <div className="container-fluid">
                  <div className="row mb-30">
                    <div className="col-lg-12">
                      <div className="page-bradcrumbs">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Trading APIs</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-60">
                    <div className="col-xxl-5 col-xl-6">
                        <div className="connect-exchange-left mb-40">
                          <h4>Quick connect an API's</h4>
                          <ul>
                            <li>
                              <p>1. Make sure you’ve accepted <a href="#/">Binance’s API Key Terms of Use</a></p>
                            </li>
                            <li>
                              <p>2. Click Connect to Binance</p>
                            </li>
                            <li>
                              <p>3. Login at Binance</p>
                            </li>
                            <li>
                              <p>4. Click ‘Continue’ when prompted</p>
                            </li>
                          </ul>
                        </div>
                        <div className="connect-exchange-left">
                          <h4>Why do we need your API keys?</h4>
                          <ul>
                            <li>
                              <p>API keys are a unique set of identifiers that allow Cornix to programmatically perform actions on your behalf in your crypto exchange account (Binance, ByBit, etc).</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    <div className="col-xxl-7 col-xl-6">
                    <div className={`ds-main-card ${isCardOpen ? 'open' : 'closed'}`}>
                      <button className="toggle-card" onClick={this.toggleCard}>
                        {/* Conditional rendering of icons based on the card state */}
                        {isCardOpen ? (
                          <FontAwesomeIcon icon={faAngleUp} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleDown} />
                        )}
                      </button>
                      <div className='page-inner-title'>
                          <h2>Add New API</h2>
                      </div>
                      <form action="" className="trade-api-form">
                        <div className="input-holder mb-20">
                          <label htmlFor="">Exchanges:</label>
                          {/* <select
                            aria-label="Default select example"
                            name="exchange"
                            className="form-select"
                            id="exchange"
                            onChange={(e) =>
                              this.setState({
                                exchange: e.target.value,
                              })
                            }
                            onClick={(e) => this.resetError(e)}
                          >
                            <option value="">Select Exchange</option>
                            <option value="xt">XT</option>
                            {this.state.exchanges.length > 0 &&
                              this.state.exchanges.map((exchange) => (
                                <option value={exchange}>
                                  {console.log('Exchange: ', exchange, ' logo: ', Logos[exchange.toLowerCase()])}
                                  <img src={Logos[exchange.toLowerCase()]} alt={`${exchange} logo`} width={"10"} height={'10'} /> 
                                  {exchange.charAt(0).toUpperCase() +
                                    exchange.slice(1)}
                                </option>
                              ))}
                          </select> */}
                          <div className={`custom-dropdown ${this.state.isOpen ? 'custom-dropdown-open' : ''}`}>
                            <div className="dropdown-header" onClick={this.handleToggleDropdown}>
                              {this.state.selectedExchange ? (
                                <div className="selected-exchange">
                                  <img src={Logos[this.state.selectedExchange.toLowerCase()]} alt={`${this.state.selectedExchange} logo`} />
                                  <p>{this.state.selectedExchange.charAt(0).toUpperCase() + this.state.selectedExchange.slice(1)}</p>
                                </div>
                              ) : (
                                <p>Select an exchange</p>
                              )}
                              <FontAwesomeIcon icon={this.state.isOpen ? faAngleUp : faAngleDown} className="exchange-drop-icon" />
                            </div>
                            {this.state.isOpen && (
                              <ul className="dropdown-list">
                                <div className="search-exchange-holder">
                                  <div className="input-holder">
                                    <input type="text" placeholder="Search Exchange" value={this.state.search} onChange={this.handleSearchChange} />
                                    <FontAwesomeIcon icon={faSearch} />
                                  </div>
                                </div>
                                {this.state.search !== '' && (
                                  <div className="search-result-holder">
                                    <div className="search-result">
                                      <img src={SearchResult} alt="Search Result" />
                                      <p>Search Results: <span>{this.state.search}</span></p>
                                    </div>
                                  </div>
                                )}
                                {exchanges
                                  .filter(exchange => exchange.toLowerCase().includes(this.state.search.toLowerCase()))
                                  .map((exchange) => (
                                    <li key={exchange} onClick={() => this.handleSelectExchange(exchange)}>
                                      <img src={Logos[exchange.toLowerCase()]} alt={`${exchange} logo`} />
                                      <p>{exchange.charAt(0).toUpperCase() + exchange.slice(1)}</p>
                                    </li>
                                  ))}
                                {this.state.search !== '' && exchanges.filter(exchange => exchange.toLowerCase().includes(this.state.search.toLowerCase())).length === 0 && (
                                 <div className="not-found-holder">
                                  <img src={Notfound} alt="Not Found" />
                                  <p>Sorry, we couldn't find any exchange. Please try a different search.</p>
                                 </div>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                        {this.state.exchangeErr ? (
                          <div>
                            <p className="text-danger">{this.state.exchangeErr}</p>
                          </div>
                        ) : null}
                        <div className="input-holder mb-20">
                          <label htmlFor="">Label:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add Label"
                            value={this.state.label}
                            onChange={(e) =>
                              this.setState({
                                label: e.target.value,
                              })
                            }
                          />
                        </div>
                        {this.state.labelErr ? (
                          <div>
                            <p className="text-danger">{this.state.labelErr}</p>
                          </div>
                        ) : null}
                        {this.state.uniqueLabelErr ? (
                          <div>
                            <p className="text-danger">{this.state.uniqueLabelErr}</p>
                          </div>
                        ) : null}
                        <div className="input-holder mb-20">
                          <label htmlFor="">Public Key:</label>
                          <input
                            type="text"
                            placeholder="Add Public Key"
                            className="from-control"
                            value={this.state.publicKey}
                            onChange={(e) =>
                              this.setState({
                                publicKey: e.target.value,
                              })
                            }
                          />
                        </div>
                        {this.state.publicKeyErr ? (
                          <div>
                            <p className="text-danger">{this.state.publicKeyErr}</p>
                          </div>
                        ) : null}
                        <div className="input-holder mb-20">
                          <label htmlFor="">Private Key:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Add Private Key"
                            value={this.state.privateKey}
                            onChange={(e) =>
                              this.setState({
                                privateKey: e.target.value,
                              })
                            }
                          />
                        </div>
                        {this.state.privateKeyErr ? (
                          <div>
                            <p className="text-danger">{this.state.privateKeyErr}</p>
                          </div>
                        ) : null}
                        <div className="input-holder copy-ip-input mb-30">
                          <label htmlFor="">White IPs:</label>
                          <input
                            type="text"
                            disabled
                            value="192.100.1.1"
                            className="from-control"
                            id="myInput"
                            placeholder="White IPs"
                          />
                          <button
                            className="btn btn-theme btn-sm"
                            onClick={(e) => this.copyText(e)}
                          >
                            <svg width="16px" height="16px" className="svg-icon" viewBox="0 0 16 16" fill="none"><path fill="currentColor" d="M4 4V0.8C4 0.587827 4.08429 0.384344 4.23432 0.234315C4.38434 0.0842854 4.58783 0 4.8 0H15.2C15.4122 0 15.6157 0.0842854 15.7657 0.234315C15.9157 0.384344 16 0.587827 16 0.8V11.2C16 11.4122 15.9157 11.6157 15.7657 11.7657C15.6157 11.9157 15.4122 12 15.2 12H12V15.1944C12 15.6392 11.6408 16 11.1944 16H0.8056C0.699778 16.0001 0.594974 15.9793 0.497186 15.9389C0.399399 15.8984 0.310548 15.8391 0.23572 15.7643C0.160893 15.6895 0.101557 15.6006 0.0611086 15.5028C0.0206607 15.405 -0.000104846 15.3002 3.98048e-07 15.1944L0.00240041 4.8056C0.00240041 4.3608 0.3616 4 0.808 4H4ZM5.6 4H11.1944C11.6392 4 12 4.3592 12 4.8056V10.4H14.4V1.6H5.6V4Z"></path></svg>
                          </button>
                        </div>
                        {this.state.whiteIpsErr ? (
                          <div>
                            <p className="text-danger">{this.state.whiteIpsErr}</p>
                          </div>
                        ) : null}
                        <button
                          className="btn btn-theme"
                          onClick={(e) => this.submitHandler(e)}
                        >
                          Add New API
                        </button>
                      </form>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.exchangeApis.length ?
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <div className="subscription-inner-tabs trade-api-tabs-holder">
                          <ul className="nav nav-pills portfolio-mng-table" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="pills-CreatedAPI-tab" data-bs-toggle="pill" data-bs-target="#pills-CreatedAPI" type="button" role="tab" aria-controls="pills-CreatedAPI" aria-selected="false">Created</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-ActiveApis-tab" data-bs-toggle="pill" data-bs-target="#pills-ActiveApis" type="button" role="tab" aria-controls="pills-ActiveApis" aria-selected="true">Active</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-PauseAPI-tab" data-bs-toggle="pill" data-bs-target="#pills-PauseAPI" type="button" role="tab" aria-controls="pills-PauseAPI" aria-selected="false">Pause</button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="pills-DeletedAPI-tab" data-bs-toggle="pill" data-bs-target="#pills-DeletedAPI" type="button" role="tab" aria-controls="pills-DeletedAPI" aria-selected="false">Deleted</button>
                            </li>          
                          </ul>
                          <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-CreatedAPI" role="tabpanel" aria-labelledby="pills-CreatedAPI-tab" tabIndex="0">
                              <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                                {
                                this.state.exchangeApis
                                .filter(api => api.status === "Created")
                                .length ? (
                                <table  className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Sr.#	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Exchange	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Label</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Public Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Private Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Status</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Starting Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Updated Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Success Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Created At	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>                                    
                                      {/* <th scope="col">
                                        <div className="table-head-th">
                                          <span>Trade</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th> */}
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Action</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                  this.state.exchangeApis
                                  .filter(api => api.status === "Created")
                                  .map((api, index) => {
                                    const balanceDifference = api.updatedBalance - api.startingBalance;
                                    let successFee = Math.max(balanceDifference * 0.05, 0);  // Ensure successFee is not negative
                                    let successFeePercentage = 0;
                                    if(api.startingBalance > 0){
                                      successFeePercentage = (successFee / api.startingBalance) * 100
                                    }

                                    // console.log('balanceDifference', balanceDifference);
                                    // console.log('successFee', successFee);
                                    // console.log('successFeePercentage', successFeePercentage);

                                    return (
                                      <tr className="tr-bg-dark" key={api._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <div className="token-img">
                                            <div className="token-img-holder">
                                              <img src={Logos[api.exchange.toLowerCase()]} alt={`${api.exchange.toLowerCase()} logo`} width={25} />
                                            </div>
                                            <div className="token-img-detail">
                                              <span>{api.exchange}</span>
                                            </div>
                                          </div>
                                        </td>
                                        <td >
                                          <div className="account-type-td">
                                            <ul>
                                              <li>
                                                <span>{api.label}</span>
                                              </li>
                                            </ul>
                                          </div>	
                                        </td>
                                        <td>
                                        {api.publicKey.substring(0, 15) + "*****"}
                                        </td>
                                        <td>
                                        {api.privateKey.substring(0, 15) + "*****"}
                                        </td>
                                        <td >
                                          <div className="trading-status-holder">
                                            {
                                              api.status === "Created" ?
                                              <div className="badge bg-primary">{api.status}</div>
                                              :
                                              api.status === "Active" ?
                                              <div className="badge bg-success">{api.status}</div>
                                              :
                                              api.status === "Pause" ?
                                              <div className="badge bg-warning">{api.status}</div>
                                              :
                                              <div className="badge bg-danger">{api.status}</div>
                                            }
                                          </div>
                                        </td>
                                        <td>{api.startingBalance}</td>
                                        <td>{api.updatedBalance}</td>
                                        <td className='started-amount-td'>
                                          USDT {successFee.toFixed(2)} 
                                          {
                                            successFeePercentage > 0 ? 
                                            <span className='text-success' style={{ marginLeft: 5 }}>{successFeePercentage.toFixed(2)}%</span>
                                            :
                                            null
                                          }
                                        </td>
                                        <td >
                                        {moment(api.createdAt).format("LLL")}
                                        </td>
                                        {/* {api.exchange === "xt" ? (
                                              <td className="start-trade-main-holder">
                                                <NavLink
                                                  to={
                                                    "/trade/" +
                                                    api.exchange +
                                                    "/" +
                                                    api.label +
                                                    "/" +
                                                    "gldusdt"
                                                  }
                                                  state={{
                                                    pair: "gld_usdt",
                                                    exchange: api.exchange,
                                                    label: api.label,
                                                    publicKey: api.publicKey,
                                                    privateKey: api.privateKey,
                                                    status: api.status,
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-dark"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop"
                                                  >
                                                    Start Trading
                                                  </button>
                                                </NavLink>
                                              </td>
                                            ) : (
                                            <td className="start-trade-main-holder">
                                              <NavLink
                                                to={
                                                  "/trade/" +
                                                  api.exchange +
                                                  "/" +
                                                  api.label +
                                                  "/" +
                                                  "btcusdt"
                                                }
                                                state={{
                                                  pair: "BTC/USDT",
                                                  exchange: api.exchange,
                                                  label: api.label,
                                                  publicKey: api.publicKey,
                                                  privateKey: api.privateKey,
                                                  status: api.status,
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-dark"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                >
                                                  Start Trading
                                                </button>
                                              </NavLink>
                                            </td>
                                          )} */}
                                        <td>
                                          <div className="connect-wallet-td">
                                            <ul>
                                              <li>
                                                <NavLink to={process.env.PUBLIC_URL + `/trade/${api._id}/edit`} state={{ id: api._id }} className='connect-direct'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                  <path d="M13.9681 2.03174C13.0364 1.10006 11.5258 1.1001 10.5942 2.03184L2.62752 9.99947C2.35663 10.2704 2.16623 10.6111 2.0775 10.9838L1.3471 14.0515C1.30689 14.2204 1.35718 14.3981 1.47995 14.5208C1.60273 14.6436 1.78041 14.6939 1.94932 14.6537L5.01682 13.9233C5.38962 13.8345 5.73047 13.6441 6.00144 13.3731L13.9682 5.40541C14.8997 4.47375 14.8997 2.96334 13.9681 2.03174ZM11.3014 2.7389C11.8424 2.19773 12.7198 2.19771 13.261 2.73885C13.802 3.27994 13.8021 4.15722 13.261 4.69835L12.6668 5.29265L10.7072 3.33309L11.3014 2.7389ZM10.0002 4.04023L11.9598 5.99979L5.29429 12.666C5.15419 12.8061 4.97795 12.9046 4.7852 12.9505L2.5081 13.4927L3.0503 11.2155C3.09618 11.0227 3.19462 10.8466 3.33466 10.7065L10.0002 4.04023Z" fill="#415B73"/>
                                                </svg>
                                                </NavLink>
                                              </li>
                                              <li>
                                                <button className='connect-direct' data-bs-toggle="modal" data-bs-target="#DeleteModal" onClick={e => this.updateApiId(e, api._id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none">
                                                  <path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"/>
                                                </svg>
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                  </tbody>
                                </table>
                                ) : (
                                  <div className="text-center">
                                    <p className="text-danger">No data yet available!</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="pills-ActiveApis" role="tabpanel" aria-labelledby="pills-ActiveApis-tab" tabIndex="0">
                              <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                                {
                                this.state.exchangeApis
                                .filter(api => api.status === "Active")
                                .length ? (
                                <table  className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Sr.#	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Exchange	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Label</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Public Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Private Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Status</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Starting Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Updated Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Success Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Created At	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Success Fee</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Trade</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Action</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    this.state.exchangeApis
                                    .filter(api => api.status === "Active")
                                    .map((api, index) => {
                                      const balanceDifference = api.updatedBalance - api.startingBalance;
                                      let successFee = Math.max(balanceDifference * 0.05, 0);  // Ensure successFee is not negative
                                      let successFeePercentage = 0;
                                      if(api.startingBalance > 0){
                                        successFeePercentage = (successFee / api.startingBalance) * 100
                                      }
                                      
                                      return (
                                        <tr className="tr-bg-dark" key={api._id}>
                                          <td>{index + 1}</td>
                                          <td>
                                            <div className="token-img">
                                              <div className="token-img-holder">
                                                <img src={Logos[api.exchange.toLowerCase()]} alt={`${api.exchange.toLowerCase()} logo`} width={25} />
                                              </div>
                                              <div className="token-img-detail">
                                                <span>{api.exchange}</span>
                                              </div>
                                            </div>
                                          </td>
                                          <td >
                                            <div className="account-type-td">
                                              <ul>
                                                <li>
                                                  <span>{api.label}</span>
                                                </li>
                                              </ul>
                                            </div>	
                                          </td>
                                          <td>
                                          {api.publicKey.substring(0, 15) + "*****"}
                                          </td>
                                          <td>
                                          {api.privateKey.substring(0, 15) + "*****"}
                                          </td>
                                          <td >
                                            <div className="trading-status-holder">
                                              {
                                                api.status === "Created" ?
                                                <div className="badge bg-primary">{api.status}</div>
                                                :
                                                api.status === "Active" ?
                                                <div className="badge bg-success">{api.status}</div>
                                                :
                                                api.status === "Pause" ?
                                                <div className="badge bg-warning">{api.status}</div>
                                                :
                                                <div className="badge bg-danger">{api.status}</div>
                                              }
                                            </div>
                                          </td>
                                          <td>{api.startingBalance}</td>
                                          <td>{api.updatedBalance}</td>
                                          <td className='started-amount-td'>
                                            USDT {successFee.toFixed(2)} 
                                            {
                                              successFeePercentage > 0 ? 
                                              <span className='text-success' style={{ marginLeft: 5 }}>{successFeePercentage.toFixed(2)}%</span>
                                              :
                                              null
                                            }
                                          </td>
                                          <td >
                                          {moment(api.createdAt).format("LLL")}
                                          </td>
                                          <td>
                                            <button type="button" className="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#Chargefee" onClick={e => this.updateApiId(e, api._id)}>Charge</button>
                                          </td>
                                          {api.exchange === "xt" ? (
                                              <td className="start-trade-main-holder">
                                                <NavLink
                                                  to={
                                                    "/trade/" +
                                                    api.exchange +
                                                    "/" +
                                                    api.label +
                                                    "/" +
                                                    "gldusdt"
                                                  }
                                                  state={{
                                                    pair: "gld_usdt",
                                                    exchange: api.exchange,
                                                    label: api.label,
                                                    publicKey: api.publicKey,
                                                    privateKey: api.privateKey,
                                                    status: api.status,
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-dark"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop"
                                                  >
                                                    Start Trading
                                                  </button>
                                                </NavLink>
                                              </td>
                                            ) : (
                                              <td className="start-trade-main-holder">
                                                <NavLink
                                                  to={
                                                    "/trade/" +
                                                    api.exchange +
                                                    "/" +
                                                    api.label +
                                                    "/" +
                                                    "btcusdt"
                                                  }
                                                  state={{
                                                    pair: "BTC/USDT",
                                                    exchange: api.exchange,
                                                    label: api.label,
                                                    publicKey: api.publicKey,
                                                    privateKey: api.privateKey,
                                                    status: api.status,
                                                  }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn btn-sm btn-dark"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#staticBackdrop"
                                                  >
                                                    Start Trading
                                                  </button>
                                                </NavLink>
                                              </td>
                                            )}
                                          <td>
                                            <div className="connect-wallet-td">
                                              <ul>
                                                <li>
                                                  <NavLink to={process.env.PUBLIC_URL + `/trade/${api._id}/edit`} state={{ id: api._id }} className='connect-direct'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M13.9681 2.03174C13.0364 1.10006 11.5258 1.1001 10.5942 2.03184L2.62752 9.99947C2.35663 10.2704 2.16623 10.6111 2.0775 10.9838L1.3471 14.0515C1.30689 14.2204 1.35718 14.3981 1.47995 14.5208C1.60273 14.6436 1.78041 14.6939 1.94932 14.6537L5.01682 13.9233C5.38962 13.8345 5.73047 13.6441 6.00144 13.3731L13.9682 5.40541C14.8997 4.47375 14.8997 2.96334 13.9681 2.03174ZM11.3014 2.7389C11.8424 2.19773 12.7198 2.19771 13.261 2.73885C13.802 3.27994 13.8021 4.15722 13.261 4.69835L12.6668 5.29265L10.7072 3.33309L11.3014 2.7389ZM10.0002 4.04023L11.9598 5.99979L5.29429 12.666C5.15419 12.8061 4.97795 12.9046 4.7852 12.9505L2.5081 13.4927L3.0503 11.2155C3.09618 11.0227 3.19462 10.8466 3.33466 10.7065L10.0002 4.04023Z" fill="#415B73"/>
                                                  </svg>
                                                  </NavLink>
                                                </li>
                                                <li>
                                                  <button className='connect-direct' data-bs-toggle="modal" data-bs-target="#DeleteModal" onClick={e => this.updateApiId(e, api._id)}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none">
                                                    <path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"/>
                                                  </svg>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                                ) : (
                                  <div className="text-center">
                                    <p className="text-danger">No data yet available!</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="pills-PauseAPI" role="tabpanel" aria-labelledby="pills-PauseAPI-tab" tabIndex="0">
                              <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                                {
                                this.state.exchangeApis
                                .filter(api => api.status === "Pause")
                                .length ? (
                                <table  className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Sr.#	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Exchange	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Label</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Public Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Private Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Status</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Starting Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Updated Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Success Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Created At	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>                                    {/* <th scope="col">
                                        <div className="table-head-th">
                                          <span>Trade</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th> */}
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Action</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                  this.state.exchangeApis
                                  .filter(api => api.status === "Pause")
                                  .map((api, index) => {
                                    const balanceDifference = api.updatedBalance - api.startingBalance;
                                    let successFee = Math.max(balanceDifference * 0.05, 0);  // Ensure successFee is not negative
                                    let successFeePercentage = 0;
                                    if(api.startingBalance > 0){
                                      successFeePercentage = (successFee / api.startingBalance) * 100
                                    }

                                    return (
                                    <tr className="tr-bg-dark" key={api._id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="token-img">
                                          <div className="token-img-holder">
                                            <img src={Logos[api.exchange.toLowerCase()]} alt={`${api.exchange.toLowerCase()} logo`} width={25} />
                                          </div>
                                          <div className="token-img-detail">
                                            <span>{api.exchange}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td >
                                        <div className="account-type-td">
                                          <ul>
                                            <li>
                                              <span>{api.label}</span>
                                            </li>
                                          </ul>
                                        </div>	
                                      </td>
                                      <td>
                                      {api.publicKey.substring(0, 15) + "*****"}
                                      </td>
                                      <td>
                                      {api.privateKey.substring(0, 15) + "*****"}
                                      </td>
                                      <td >
                                        <div className="trading-status-holder">
                                          {
                                            api.status === "Created" ?
                                            <div className="badge bg-primary">{api.status}</div>
                                            :
                                            api.status === "Active" ?
                                            <div className="badge bg-success">{api.status}</div>
                                            :
                                            api.status === "Pause" ?
                                            <div className="badge bg-warning">{api.status}</div>
                                            :
                                            <div className="badge bg-danger">{api.status}</div>
                                          }
                                        </div>
                                      </td>
                                      <td>{api.startingBalance}</td>
                                      <td>{api.updatedBalance}</td>
                                      <td className='started-amount-td'>
                                        USDT {successFee.toFixed(2)} 
                                        {
                                          successFeePercentage > 0 ? 
                                          <span className='text-success' style={{ marginLeft: 5 }}>{successFeePercentage.toFixed(2)}%</span>
                                          :
                                          null
                                        }
                                      </td>
                                      <td >
                                      {moment(api.createdAt).format("LLL")}
                                      </td>
                                      {/* {api.exchange === "xt" ? (
                                            <td className="start-trade-main-holder">
                                              <NavLink
                                                to={
                                                  "/trade/" +
                                                  api.exchange +
                                                  "/" +
                                                  api.label +
                                                  "/" +
                                                  "gldusdt"
                                                }
                                                state={{
                                                  pair: "gld_usdt",
                                                  exchange: api.exchange,
                                                  label: api.label,
                                                  publicKey: api.publicKey,
                                                  privateKey: api.privateKey,
                                                  status: api.status,
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-dark"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                >
                                                  Start Trading
                                                </button>
                                              </NavLink>
                                            </td>
                                          ) : (
                                            <td className="start-trade-main-holder">
                                              <NavLink
                                                to={
                                                  "/trade/" +
                                                  api.exchange +
                                                  "/" +
                                                  api.label +
                                                  "/" +
                                                  "btcusdt"
                                                }
                                                state={{
                                                  pair: "BTC/USDT",
                                                  exchange: api.exchange,
                                                  label: api.label,
                                                  publicKey: api.publicKey,
                                                  privateKey: api.privateKey,
                                                  status: api.status,
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-dark"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                >
                                                  Start Trading
                                                </button>
                                              </NavLink>
                                            </td>
                                          )} */}
                                        <td>
                                          <div className="connect-wallet-td">
                                            <ul>
                                              <li>
                                                <NavLink to={process.env.PUBLIC_URL + `/trade/${api._id}/edit`} state={{ id: api._id }} className='connect-direct'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                  <path d="M13.9681 2.03174C13.0364 1.10006 11.5258 1.1001 10.5942 2.03184L2.62752 9.99947C2.35663 10.2704 2.16623 10.6111 2.0775 10.9838L1.3471 14.0515C1.30689 14.2204 1.35718 14.3981 1.47995 14.5208C1.60273 14.6436 1.78041 14.6939 1.94932 14.6537L5.01682 13.9233C5.38962 13.8345 5.73047 13.6441 6.00144 13.3731L13.9682 5.40541C14.8997 4.47375 14.8997 2.96334 13.9681 2.03174ZM11.3014 2.7389C11.8424 2.19773 12.7198 2.19771 13.261 2.73885C13.802 3.27994 13.8021 4.15722 13.261 4.69835L12.6668 5.29265L10.7072 3.33309L11.3014 2.7389ZM10.0002 4.04023L11.9598 5.99979L5.29429 12.666C5.15419 12.8061 4.97795 12.9046 4.7852 12.9505L2.5081 13.4927L3.0503 11.2155C3.09618 11.0227 3.19462 10.8466 3.33466 10.7065L10.0002 4.04023Z" fill="#415B73"/>
                                                </svg>
                                                </NavLink>
                                              </li>
                                              <li>
                                                <button className='connect-direct' data-bs-toggle="modal" data-bs-target="#DeleteModal" onClick={e => this.updateApiId(e, api._id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none">
                                                  <path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"/>
                                                </svg>
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                    </tr>
                                    )
                                  })}
                                  </tbody>
                                </table>
                                ) : (
                                  <div className="text-center">
                                    <p className="text-danger">No data yet available!</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="tab-pane fade" id="pills-DeletedAPI" role="tabpanel" aria-labelledby="pills-DeletedAPI-tab" tabIndex="0">
                              <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                                {
                                this.state.exchangeApis
                                .filter(api => api.status === "Deleted")
                                .length ? (
                                <table  className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Sr.#	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Exchange	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Label</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Public Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Private Key	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Status</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Starting Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Updated Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Success Amount</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="table-head-th">
                                          <span>Created At	</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th>
                                      {/* <th scope="col">
                                        <div className="table-head-th">
                                          <span>Action</span>
                                          <button>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                              <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                  this.state.exchangeApis
                                  .filter(api => api.status === "Deleted")
                                  .map((api, index) => {
                                    const balanceDifference = api.updatedBalance - api.startingBalance;
                                    let successFee = Math.max(balanceDifference * 0.05, 0);  // Ensure successFee is not negative
                                    let successFeePercentage = 0;
                                    if(api.startingBalance > 0){
                                      successFeePercentage = (successFee / api.startingBalance) * 100
                                    }

                                    return (
                                    <tr className="tr-bg-dark" key={api._id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="token-img">
                                          <div className="token-img-holder">
                                            <img src={Logos[api.exchange.toLowerCase()]} alt={`${api.exchange.toLowerCase()} logo`} width={25} />
                                          </div>
                                          <div className="token-img-detail">
                                            <span>{api.exchange}</span>
                                          </div>
                                        </div>
                                      </td>
                                      <td >
                                        <div className="account-type-td">
                                          <ul>
                                            <li>
                                              <span>{api.label}</span>
                                            </li>
                                          </ul>
                                        </div>	
                                      </td>
                                      <td>
                                      {api.publicKey.substring(0, 15) + "*****"}
                                      </td>
                                      <td>
                                      {api.privateKey.substring(0, 15) + "*****"}
                                      </td>
                                      <td >
                                        <div className="trading-status-holder">
                                          {
                                            api.status === "Created" ?
                                            <div className="badge bg-primary">{api.status}</div>
                                            :
                                            api.status === "Active" ?
                                            <div className="badge bg-success">{api.status}</div>
                                            :
                                            api.status === "Pause" ?
                                            <div className="badge bg-warning">{api.status}</div>
                                            :
                                            <div className="badge bg-danger">{api.status}</div>
                                          }
                                        </div>
                                      </td>
                                      <td>{api.startingBalance}</td>
                                      <td>{api.updatedBalance}</td>
                                      <td className='started-amount-td'>
                                        USDT {successFee.toFixed(2)}
                                        {
                                          successFeePercentage > 0 ? 
                                          <span className='text-success' style={{ marginLeft: 5 }}>{successFeePercentage.toFixed(2)}%</span>
                                          :
                                          null
                                        }
                                      </td>
                                      <td >
                                      {moment(api.createdAt).format("LLL")}
                                      </td>
                                      {/* <td className='started-amount-td'>$1255 <span className='text-success'>+1.33%</span> </td>
                                      {api.exchange === "xt" ? (
                                            <td className="start-trade-main-holder">
                                              <NavLink
                                                to={
                                                  "/trade/" +
                                                  api.exchange +
                                                  "/" +
                                                  api.label +
                                                  "/" +
                                                  "gldusdt"
                                                }
                                                state={{
                                                  pair: "gld_usdt",
                                                  exchange: api.exchange,
                                                  label: api.label,
                                                  publicKey: api.publicKey,
                                                  privateKey: api.privateKey,
                                                  status: api.status,
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-dark"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                >
                                                  Start Trading
                                                </button>
                                              </NavLink>
                                            </td>
                                          ) : (
                                            <td className="start-trade-main-holder">
                                              <NavLink
                                                to={
                                                  "/trade/" +
                                                  api.exchange +
                                                  "/" +
                                                  api.label +
                                                  "/" +
                                                  "btcusdt"
                                                }
                                                state={{
                                                  pair: "BTC/USDT",
                                                  exchange: api.exchange,
                                                  label: api.label,
                                                  publicKey: api.publicKey,
                                                  privateKey: api.privateKey,
                                                  status: api.status,
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-sm btn-dark"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#staticBackdrop"
                                                >
                                                  Start Trading
                                                </button>
                                              </NavLink>
                                            </td>
                                          )}
                                        <td>
                                          <div className="connect-wallet-td">
                                            <ul>
                                              <li>
                                                <NavLink to={process.env.PUBLIC_URL + `/trade/${api._id}/edit`} state={{ id: api._id }} className='connect-direct'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                  <path d="M13.9681 2.03174C13.0364 1.10006 11.5258 1.1001 10.5942 2.03184L2.62752 9.99947C2.35663 10.2704 2.16623 10.6111 2.0775 10.9838L1.3471 14.0515C1.30689 14.2204 1.35718 14.3981 1.47995 14.5208C1.60273 14.6436 1.78041 14.6939 1.94932 14.6537L5.01682 13.9233C5.38962 13.8345 5.73047 13.6441 6.00144 13.3731L13.9682 5.40541C14.8997 4.47375 14.8997 2.96334 13.9681 2.03174ZM11.3014 2.7389C11.8424 2.19773 12.7198 2.19771 13.261 2.73885C13.802 3.27994 13.8021 4.15722 13.261 4.69835L12.6668 5.29265L10.7072 3.33309L11.3014 2.7389ZM10.0002 4.04023L11.9598 5.99979L5.29429 12.666C5.15419 12.8061 4.97795 12.9046 4.7852 12.9505L2.5081 13.4927L3.0503 11.2155C3.09618 11.0227 3.19462 10.8466 3.33466 10.7065L10.0002 4.04023Z" fill="#415B73"/>
                                                </svg>
                                                </NavLink>
                                              </li>
                                              <li>
                                                <button className='connect-direct' data-bs-toggle="modal" data-bs-target="#DeleteModal" onClick={e => this.updateApiId(e, api._id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none">
                                                  <path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"/>
                                                </svg>
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </td> */}
                                    </tr>
                                    )
                                    })}
                                  </tbody>
                                </table>
                                ) : (
                                  <div className="text-center">
                                    <p className="text-danger">No data yet available!</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className="row justify-content-center">
                      <div className="col-lg-12">
                        <p>Connect your API to begin the automated trading journey with ABC's fully automated AI-based trading system, boasting a high success rate.</p>
                      </div>
                    </div>
                  }
                </div> 
                {/* <div className="container-fluid">
                  <div className="row mb-40">
                    <div className="col-lg-12">
                      <div className="page-title-trade">
                        <h2>Connect Your Exchange</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="connect-exchange-left mb-40">
                        <h4>Quick connect an API with Binance</h4>
                        <ul>
                          <li>
                            <p>1. Make sure you’ve accepted <a href="#/">Binance’s API Key Terms of Use</a></p>
                          </li>
                          <li>
                            <p>2. Click Connect to Binance</p>
                          </li>
                          <li>
                            <p>3. Login at Binance</p>
                          </li>
                          <li>
                            <p>4. Click ‘Continue’ when prompted</p>
                          </li>
                        </ul>
                      </div>
                      <div className="connect-exchange-left">
                        <h4>Don’t have a Binance account?</h4>
                        <ul>
                          <li>
                            <p><a href="#/">Sign up for free at Binance</a></p>
                          </li>
                        </ul>
                        <h4 className="pt-30">Why do we need your API keys?</h4>
                        <ul>
                          <li>
                            <p>API keys are a unique set of identifiers that allow Cornix to programmatically perform actions on your behalf in your crypto exchange account (Binance, ByBit, etc).</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="select-exchange-input">
                        <label htmlFor="">Exchange</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Binance Spot</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <div className="connect-binance-btn-holder">
                          <button className="btn btn-theme">Connect to Binance</button>
                          <a href="#/">Connect to Binance with API keys instead</a>
                        </div>
                        <div className="quick-connect-holder">
                          <span><svg viewBox="64 64 896 896" focusable="false" data-icon="link" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path></svg></span>
                          <p><span>Quick Connect</span> - Automatically create encrypted and IP-restricted API keys when connecting to Binance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                
              </section>
               {/* Delete Modal */}
                <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
                  <div className="modal-dialog delete-modal-holder">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="DeleteModalLabel">Delete Request</h1>
                      </div>
                      <div className="modal-body">
                        <p>Are you sure? You can't undo this action afterwards.</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={e => this.markAsDeleted(e)}>Delete</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Delete Modal */}
                 {/* Delete Modal */}
                 <div className="modal fade" id="Chargefee" tabIndex="-1" aria-labelledby="ChargefeeLabel" aria-hidden="true">
                  <form onSubmit={e => this.chargeSuccessFee(e)}>
                    <div className="modal-dialog delete-modal-holder">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="ChargefeeLabel">Charge Success Fee</h1>
                        </div>
                        <div className="modal-body">
                            <div className="input-holder">
                              <label htmlFor="">API Balance</label>
                              <input type="text" className="form-control" placeholder="Enter API balance" value={this.state.apiBalance} onChange={e => this.setState({
                                apiBalance: e.target.value
                              })} />
                            </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                          <button type="submit" className="btn btn-dark" data-bs-dismiss="modal">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* Delete Modal */}
            </div>
          </div>
      </div>
      )}
    </AuthContext.Consumer>

    );
  }
}

export default Dashboard;