import React from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import BinanceSVG from "../../../assets/images/dashbaord-icons/binance.svg"
import { AuthContext } from "../../../context/AuthContext";
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import moment from 'moment';

const PortfolioManagement = (
  {
    toggleActive,
    setToggleActive,
    name,
    darkMode,
  }
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [auth] = useState(localStorage.getItem('auth'));
  const [role] = useState(localStorage.getItem('role'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [transactions, setTransactions] = useState([]);

  const getTransactions = () => {
    axios
      .get(`${apiUrl}/transactions?userId=${userId}&role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if(res.data) {
          setTransactions(res.data.data)
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(auth) {
      getTransactions();
    }
  }, [auth])

  // console.log("transactions", transactions)
  return (
    <AuthContext.Consumer>
      {(context) => (
      <>
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
        <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          />
        <div className="z-theme-wrapper">
          <Header 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
            name={name}
            darkMode={darkMode}
          />
              <div className='section-padding'>
                <div className="container-fluid">
                  {/* <div className="row align-items-center mb-50">
                    <div className="col-lg-6 col-4">
                      <div className="page-title">
                        <h2>Portfolio</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-8">
                      <div className="portfolio-head-right text-end">
                        <button className='btn btn-theme btn-lg' type="button" data-bs-toggle="offcanvas" data-bs-target="#ConnectWalletCanvas" aria-controls="ConnectWalletCanvas">Connect a new account</button>
                      </div>
                    </div>
                  </div> */}
                  <div className="row mb-30">
                    <div className="col-lg-12">
                      <div className="page-bradcrumbs">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Portfolio Management</li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-50">
                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6">
                      <div className="ds-main-card prt-main-card">
                        <div className="prt-main-card-head">
                          <div className="prt-main-card-head-left">
                            <h3>Mubi Khan</h3>
                            <p>Pro Account</p>
                          </div>
                          <div className="prt-main-card-head-right">
                            <button>
                            <svg width="16px" height="16px" className="svg-icon XH5Vg" viewBox="0 0 17 17" fill="#000"><path fill="current" d="M3.81107 1.20125C5.06781 0.418551 6.52606 -0.00205142 8.02311 7.52329e-06C12.4287 9.2296e-05 15.9999 3.57134 15.9999 7.97695C15.9999 9.68054 15.4654 11.2603 14.556 12.5558L14.3496 12.8498L11.5165 7.75195H13.9953C13.9543 6.65903 13.6139 5.59671 13.0094 4.6818C12.3635 3.70423 11.4445 2.93807 10.3667 2.4786C9.2888 2.01912 8.09968 1.8866 6.94713 2.09751C6.16628 2.2404 5.42554 2.53667 4.76612 2.96522L4.59792 3.10406C4.11713 3.50091 3.39416 3.36421 3.09146 2.81922C2.84989 2.3843 2.96586 1.83787 3.36324 1.53856L3.81107 1.20125Z"></path><path fill="current" d="M12.1888 14.799C10.9321 15.5817 9.47389 16.0023 7.9768 16.0002C3.5712 16.0002 0 12.4289 0 8.02324C0 6.31966 0.534515 4.73989 1.44394 3.44435L1.65031 3.15039L4.48344 8.24824H2.00461C2.04569 9.34117 2.38601 10.4035 2.99051 11.3184C3.63642 12.296 4.55543 13.0622 5.63326 13.5217C6.71109 13.9811 7.90021 14.1136 9.05276 13.9027C9.83358 13.7598 10.5744 13.4636 11.2338 13.035L11.402 12.8962C11.8828 12.4993 12.6058 12.636 12.9085 13.181C13.15 13.6159 13.0341 14.1624 12.6367 14.4617L12.1888 14.799Z"></path></svg>
                            </button>
                          </div>
                        </div>
                        <div className="prt-card-bar">
                          <div className="progress-stacked">
                            <div className="progress prg-1" role="progressbar" aria-label="Segment one" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                              <div className="progress-bar bg-eth"></div>
                            </div>
                            <div className="progress prg-2" role="progressbar" aria-label="Segment two" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                              <div className="progress-bar bg-btc"></div>
                            </div>
                            <div className="progress prg-3" role="progressbar" aria-label="Segment three" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                              <div className="progress-bar bg-usdt"></div>
                            </div>
                            <div className="progress prg-4" role="progressbar" aria-label="Segment four" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              <div className="progress-bar bg-bnb"></div>
                            </div>
                          </div>
                        </div>
                        <div className="prt-bar-token mb-20">
                          <div className="prt-bar-token-holder">
                            <div className="token-prt">
                              <span className="bg-token-eth token-value"></span>
                              <span className="token-name">ETH</span>
                              <span className="token-percent">47%</span>
                            </div>
                            <div className="token-prt">
                              <span className="bg-token-btc token-value"></span>
                              <span className="token-name">BTC</span>
                              <span className="token-percent">20%</span>
                            </div>
                            <div className="token-prt">
                              <span className="bg-token-usdt token-value"></span>
                              <span className="token-name">USDT</span>
                              <span className="token-percent">33%</span>
                            </div>
                            <div className="token-prt">
                              <span className="bg-token-bnb token-value"></span>
                              <span className="token-name">BNB</span>
                              <span className="token-percent">20%</span>
                            </div>
                          </div>
                        </div>
                        <div className="prt-total-statistics">
                          <div className="prt-total-statistics-left">
                            <span>Total:</span>
                            <span>24hr changes:</span>
                          </div>
                          <div className="prt-total-statistics-right">
                            <div className="prt-total-statistics-blnc">
                              <p>$222,868.89</p>
                              <span className='plus-blnc'>
                                +1.7%
                                <svg width="8px" height="16px" className="svg-icon" viewBox="0 0 8 16" fill="none"><path fill="currentColor" d="M3.37879 14V4.79828C2.99495 5.1073 2.53535 5.3877 2 5.63948V4.3176C2.37374 3.9628 2.69697 3.59084 2.9697 3.20172C3.25253 2.81259 3.4899 2.41202 3.68182 2H4.33333C4.70707 2.84692 5.26263 3.61946 6 4.3176V5.63948C5.73737 5.52504 5.4899 5.39914 5.25757 5.2618C5.03535 5.12446 4.82828 4.97568 4.63637 4.81545V14H3.37879Z"></path></svg>  
                              </span>
                            </div>
                            <div className="prt-total-statistics-blnc">
                              <p>/ 5.76245821 BTC</p>
                              <span className='minus-blnc'>
                              -0.1%
                              <svg width="8px" height="16px" className="svg-icon" viewBox="0 0 8 16" fill="none"><path fill="currentColor" d="M3.37879 2V11.2017C2.99495 10.8927 2.53535 10.6123 2 10.3605V11.6824C2.37374 12.0372 2.69697 12.4092 2.9697 12.7983C3.25253 13.1874 3.4899 13.588 3.68182 14H4.33333C4.70707 13.1531 5.26263 12.3805 6 11.6824V10.3605C5.73737 10.475 5.4899 10.6009 5.25757 10.7382C5.03535 10.8755 4.82828 11.0243 4.63637 11.1845V2H3.37879Z"></path></svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="account-card-footer">
                          <div className="account-footer-left">
                            <button className='btn btn-theme'>Trade</button>
                            <button className='btn btn-outline-theme'>View</button>
                          </div>
                          <div className="account-footer-right">
                          <div className="dropdown">
                            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <svg width="16" height="16" className="ZEYul rfaeS" viewBox="0 0 16 16" fill="none"><path d="M8.92773 4.25C7.96124 4.25 7.17773 3.4665 7.17773 2.5C7.17773 1.5335 7.96124 0.75 8.92773 0.75C9.89423 0.75 10.6777 1.5335 10.6777 2.5C10.6777 3.4665 9.89423 4.25 8.92773 4.25Z" fill="currentColor"></path><path d="M8.92773 9.75C7.96124 9.75 7.17773 8.9665 7.17773 8C7.17773 7.0335 7.96124 6.25 8.92773 6.25C9.89423 6.25 10.6777 7.0335 10.6777 8C10.6777 8.9665 9.89423 9.75 8.92773 9.75Z" fill="currentColor"></path><path d="M8.92773 15.25C7.96124 15.25 7.17773 14.4665 7.17773 13.5C7.17773 12.5335 7.96124 11.75 8.92773 11.75C9.89423 11.75 10.6777 12.5335 10.6777 13.5C10.6777 14.4665 9.89423 15.25 8.92773 15.25Z" fill="currentColor"></path></svg>
                            </button>
                            <ul className="dropdown-menu">
                              <li><a className="dropdown-item" href="#/">Delete</a></li>
                            </ul>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-50">
                    <div className="col-lg-12">
                      <div className="ds-card-title">
                        <h3>Transactions History</h3>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-lg-12">
                          <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                            {
                              transactions.length ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    {/* <th scope="col">
                                      <div className="table-head-th">
                                        <span>Transaction ID</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th> */}
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>From</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>To</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Type</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Amount</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Remarks </span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    transactions
                                      .filter(transaction => transaction.userId === userId)
                                      .map((transaction, index) => (
                                        <tr className="tr-bg-dark">
                                          <td>{index + 1}</td>
                                          {/* <td>aawenjghfsdklj47</td> */}
                                          <td>
                                            {transaction.from}
                                          </td>
                                          <td>
                                            {transaction.to}
                                          </td>
                                          <td >
                                            <div className="account-type-td">
                                              <ul>
                                                <li>
                                                  <span>{transaction.type}</span>
                                                </li>
                                              </ul>
                                            </div>	
                                          </td>
                                          <td>
                                            USDT {transaction.amount}
                                          </td>
                                          <td>
                                            <div className="trading-status-holder">
                                              {
                                                transaction.status === "Completed" ?
                                                <div className="badge bg-success">{transaction.status}</div>
                                                :
                                                <div className="badge bg-info">{transaction.status}</div>
                                              }
                                            </div>
                                          </td>
                                          <td>
                                            {moment(transaction.createAt).format("LLL")}
                                          </td>
                                          <td></td>
                                        </tr>
                                      ))
                                  }
                                </tbody>
                              </table>
                              :
                              <div className='text-center'>
                                <p className='text-gray'>No data available yet</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
              {/* Edit Modal */}
              <div className="modal fade" id="EditAPIModal" tabIndex="-1" aria-labelledby="EditAPIModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg Edit-modal-holder">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="EditAPIModalLabel">Edit</h1>
                    </div>
                    <div className="modal-body">
                      <form action="">
                        <div className="input-holder mb-20">
                          <label htmlFor="">Label:</label>
                          <input type="text" className='form-control' placeholder='Edit Label' />
                        </div>
                        <div className="input-holder mb-20">
                          <label htmlFor="">Public Key:</label>
                          <input type="text" className='form-control' placeholder='Edit Public Key' />
                        </div>
                        <div className="input-holder">
                          <label htmlFor="">Private Key:</label>
                          <input type="text" className='form-control' placeholder='Edit Private Key' />
                        </div>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button type="button" className="btn btn-theme">Update</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Edit Modal */}
              {/* Connect Wallet Canvas */}
              <div className="offcanvas  connect-wallet-canvas offcanvas-end" tabIndex="-1" id="ConnectWalletCanvas" aria-labelledby="ConnectWalletCanvasLabel">
                <div className="offcanvas-header justify-content-end">
                  <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                  <h4>Select Account Type</h4>
                  <div className='select-acccount-alert'>
                    <div className="select-alert-detail">
                      <p>Safety first - No one has access to your funds and all account information is encrypted</p>
                    </div>
                  </div>
                  <ul className='select-account-holder'>
                    <li className='exchange-choose-list'>
                      <div className='account-choose-holder'>
                        <div className="account-choose-svg">
                          <svg width="28" height="29" className="ZEYul zmoSw" viewBox="0 0 28 29" fill="currentColor"><path d="M17.5984 28.4L7.39844 16.2H12.7984V12.2H14.3984V17.8H10.7984L17.5984 25.8L23.9984 17.8H20.5984V0H22.1984V16.2H27.3984L17.5984 28.4Z" className="U3H5v"></path><path d="M6.8 28.4H5.20001V12.2H0L9.8 0L20 12.2H14.4V16.2H12.8V10.6H16.4L9.8 2.6L3.40001 10.6H6.8V28.4Z" fill="#00A59A"></path></svg>
                        </div>
                        <div className="account-choose-detail">
                          <h4>Exchanges</h4>
                          <p>Connect an exchange and manage your assets</p>
                        </div>
                      </div>
                    </li>
                    <li className='wallet-choose-list'>
                      <div className='account-choose-holder'>
                        <div className="account-choose-svg">
                        <svg width="30" height="30" className="ZEYul zmoSw" viewBox="0 0 30 30" fill="currentColor"><path d="M5.40234 6.99963L15.4293 0.599609L17.4013 3.69867L19.7571 2.19956L22.8115 6.99963L20.9151 6.99963L19.2662 4.40841L15.1941 6.99963L12.2139 6.99963L16.0527 4.55684L16.0505 4.55611L14.9384 2.80846L8.35199 6.99963L5.40234 6.99963Z" fill="#00A59A"></path><path d="M24.6012 8.6H2.20154V27.8H24.6012V23H26.2012V29.4H0.601562V7H26.2012V13.4H24.6012V8.6Z" className="U3H5v"></path><path fillRule="evenodd" clipRule="evenodd" d="M27.8011 16.6H21.4012V19.8H27.8011V16.6ZM19.8013 15V21.4H29.4011V15H19.8013Z" className="U3H5v"></path></svg>
                        </div>
                        <div className="account-choose-detail">
                          <h4>Wallets</h4>
                          <p>Connect a wallet and track its price movement</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Connect Wallet Canvas */}
          </div>
      </div>
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default PortfolioManagement