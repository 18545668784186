import React, { useState } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Frontend/home/Home";
import Trade from "./pages/ClientArea/trade/Trade";
import Dashboard from "./pages/ClientArea/dashboard/Dashboard-Two";
import Login from "./pages/login/Login-Two";
import Register from "./pages/register/Register-Two";
import DashboardHome from "./pages/ClientArea/home/Home";
import Affiliate from "./pages/ClientArea/affiliate/Affiliate";
import HelpAndSupport from "./pages/ClientArea/tickets/Tickets";
import Settings from "./pages/ClientArea/settings/Settings";
import Subscription from "./pages/ClientArea/subscription/Subscription";
import ConnectExchange from "./pages/ClientArea/connect-exchange/ConnectExchange";
import PortfolioManagement from "./pages/ClientArea/portfolio-management/PortfolioManagement";
import CreateSupportTicket from "./pages/ClientArea/tickets/create/CreateTicket";
import EditTicket from "./pages/ClientArea/tickets/edit/EditTicket";
import KycRequests from "./pages/ClientArea/kyc-requests/KycRequests"
import PrivacyPolicy from "./pages/Frontend/privacy-policy/PrivacyPolicy"
import Terms from "./pages/Frontend/terms/Terms";
import CookiePolicy from "./pages/Frontend/cookie-policy/CookiePolicy";
import Arbitrage from "./pages/Frontend/arbitrage/Arbitrage"
import AutomatedTrading from "./pages/Frontend/automated-trading/AutomatedTrading"
import PortfolioTracking from "./pages/Frontend/portfolio-tracking/PortfolioTracking";
import MarketMaking from "./pages/Frontend/market-making/MarketMaking";
import EditApi from "./pages/ClientArea/dashboard/edit/EditApi"
import AboutUs from "./pages/Frontend/about-us/AboutUs"
import Users from "./pages/ClientArea/users/Users";
// import Callback from "./pages/callback/Callback"

function App() {
  const [auth] = useState(localStorage.getItem("auth"));
  const [role] = useState(localStorage.getItem("role"));
  const [toggleActive, setToggleActive] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <Routes>
        <>
          {auth ? (
            <>
              <Route
                path={process.env.PUBLIC_URL + "/login"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/register"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/trade/:id/:id/:id"}
                element={
                <Trade
                  toggleActive={toggleActive} 
                  setToggleActive={setToggleActive} 
                  name={"Exchange Connectivity"}
                />
              }
              />
              <Route
                path={process.env.PUBLIC_URL + "/trade"}
                element={
                  <Dashboard
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Trade"}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/kyc-requests"}
                element={
                  <KycRequests
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Kyc Requests"}
                  />
                }
              />
                <Route
                path={process.env.PUBLIC_URL + "/tickets/create"}
                element={
                  <CreateSupportTicket
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"CreateSupportTicket"}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/tickets/:id"}
                element={
                  <EditTicket
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Edit Ticket"}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/trade/:id/edit"}
                element={
                  <EditApi
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Edit Api"}
                    navigate={navigate} 
                    locationState={state}
                  />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/dashboard"}
                element={<DashboardHome
                  toggleActive={toggleActive} 
                  setToggleActive={setToggleActive} 
                  name={"Main"}
                  />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/affiliate"}
                element={<Affiliate
                  toggleActive={toggleActive} 
                  setToggleActive={setToggleActive} 
                  name={"Affiliate"}
                  />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/tickets"}
                element={<HelpAndSupport
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Help & Support"}
                  />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/connect-exchange"}
                element={<ConnectExchange
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Connect Exchange"}
                  />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/settings"}
                element={
                  <Settings 
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Settings"}
                  />
                }
              />
              {
                role === "admin" ?
                <Route
                  path={process.env.PUBLIC_URL + "/users"}
                  element={<Users
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Users"}
                    />}
                />
                :
                <Route
                  path={process.env.PUBLIC_URL + "/users"}
                  element={
                    <Navigate replace to={process.env.PUBLIC_URL + "/dashboard"} />
                  }
                />
              }
              <Route
                path={process.env.PUBLIC_URL + "/subscription"}
                element={<Subscription
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Subscription"}
                  />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/portfolio-management"}
                element={<PortfolioManagement
                    toggleActive={toggleActive} 
                    setToggleActive={setToggleActive} 
                    name={"Portfolio Management"}
                  />}
              />
            </>
          ) : (
            <>
              <Route
                path={process.env.PUBLIC_URL + "/trade"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/trade/:id/edit"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/trade/:id/:id/:id"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/affiliate"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />  
              <Route
                path={process.env.PUBLIC_URL + "/subscription"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/portfolio-management"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/settings"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/users"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/tickets"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              />
              <Route
                path={process.env.PUBLIC_URL + "/tickets/create"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/tickets/:id"}
                element={
                  <Navigate replace to={process.env.PUBLIC_URL + "/login"} />
                }
              /> 
              <Route
                path={process.env.PUBLIC_URL + "/register"}
                element={<Register />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login"}
                element={<Login />}
              />
            </>  
          )}
          <Route path={process.env.PUBLIC_URL + "/"} element={<Home />}  />
          {/* <Route path={process.env.PUBLIC_URL + "/callback"} element={<Callback />}  /> */}
          <Route path={process.env.PUBLIC_URL + "/arbitrage"} element={<Arbitrage />}  />
          <Route path={process.env.PUBLIC_URL + "/automated-trading"} element={<AutomatedTrading />}  />
          <Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs />}  />
          <Route path={process.env.PUBLIC_URL + "/portfolio-tracking"} element={<PortfolioTracking />}  />
          <Route path={process.env.PUBLIC_URL + "/market-making-trading"} element={<MarketMaking />}  />
          <Route path={process.env.PUBLIC_URL + "/privacy-policy"} element={<PrivacyPolicy />}  />
          <Route path={process.env.PUBLIC_URL + "/cookie-policy"} element={<CookiePolicy />}  />
          <Route path={process.env.PUBLIC_URL + "/terms"} element={<Terms />}  />
        </>
      </Routes>
    </>
  );
}

export default App;
