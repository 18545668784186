import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import AutoTradeBanner from "../../../assets/images/auto-trading/auto-trading.webp"
import AutoTrade from "../../../assets/images/auto-trading/auto-trade.svg"
import Analyze from "../../../assets/images/auto-trading/analyze.svg"
import ArbitrageProfit from "../../../assets/images/arbitrage/arb-profit.svg"
import SupportAuto from "../../../assets/images/auto-trading/247.svg"
import ProfitExchangeArb from "../../../assets/images/arbitrage/profit-arbitrage.webp"
import BannerMainImage from "../../../assets/images/banner-coins/banner-laptop.png"
import AvailableAutoImg from "../../../assets/images/auto-trading/Screenshot_1.png"
import Loader from "../../../components/Frontend/loader/Loader";
import { Link } from "react-router-dom";


function AutomatedTrading() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />
      <Header />
      {/* Main Banner */}
      <section className="main-banner section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-caption-holder">
                <h2>Bots don't need sleep.</h2>
                <p>Cryptotrading is 24/7. So is your bot. Give yourself an edge, and while everyone else sleeps, you’ll never miss a beat.</p>
                <div className="banner-btn">
                  <Link to="/login" className="btn btn-fr-theme btn-lg">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-main-img">
                <img src={AutoTradeBanner} alt="Banner-Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main Banner */}

      {/* Exchange Arbitrage */}
			<section className="total-vol-holder section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={AutoTrade} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Copy</h4>
									<p>A pro</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={Analyze} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Analyze</h4>
									<p>Automatically</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={SupportAuto} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>24/7</h4>
									<p>In the cloud</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Exchange Arbitrage */}

      {/* Cross Exchange */}
			<section className="section-padding cross-exchange-holder">
				<div className="container">
					<div className="row mb-80 align-items-center justify-content-between">
						<div className="col-lg-6">
							<img src={BannerMainImage} alt="Cross Exchange" />
						</div>
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Top traders meet world-class execution</h2>
								<p>Arbitrage between exchanges without sending funds from one exchange to another. Connect exchanges where you have funds, enable Exchange Arbitrage, and let your Hopper search for Arbitrage opportunities.</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Profit from inefficiencies on your exchange</h2>
								<p>Market Arbitrage, also called triangular arbitrage, enables you to profit from price differences between pairs on the exchange itself.</p>
							</div>
						</div>
						<div className="col-lg-6">
							<img src={ProfitExchangeArb} alt="Cross Exchange" />
						</div>
					</div>
				</div>
			</section>
      {/* Cross Exchange */}

      {/* Avaiable Anytime */}
			<section className="section-padding available-auto-holder">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="main-heading text-center">
								<h2>Available anytime. Anywhere.</h2>
								<p>Hosted in the cloud, MarketMaking is available 24/7. Protect and monitor your assets, even while you’re logged out. Access your MarketMaking from any device, including web, phone, tablet and even your smartwatch.</p>
								<img src={AvailableAutoImg} alt="Available Auto Image" />
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Avaiable Anytime */}

      <Footer />
    </div>
  );
}

export default AutomatedTrading;
