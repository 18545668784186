import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../components/ClientArea/header/Header";
import "../../../css/Main.css";
import "../../../css/responsive.css";
import BinanceSVG from "../../../assets/images/dashbaord-icons/binance.svg"

import Sidebar from "../../../components/ClientArea/sidebar/Sidebar";
import { AuthContext } from "../../../context/AuthContext";


class Dashboard extends React.Component {
  render() {
    const {
      toggleActive,
      setToggleActive,
      darkMode,
      name,
    } = this.props;
    return (
      <AuthContext.Consumer>
      {(context) => (
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
          <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          />
            <div className="z-theme-wrapper">
              <Header 
                toggleActive={toggleActive}
                setToggleActive={setToggleActive}
                name={name}
                darkMode={darkMode}
              />
            <div className="trade-page-holder">
              <section className="section-padding">
                <div className="container-fluid">
                  <div className="row mb-40">
                    <div className="col-lg-12">
                      <div className="page-title-trade">
                        <h2>Connect Your Exchange</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-50">
                    <div className="col-xxl-5 col-xl-6">
                      <div className="connect-exchange-left mb-40">
                        <h4>Quick connect an API with Binance</h4>
                        <ul>
                          <li>
                            <p>1. Make sure you’ve accepted <a href="#/">Binance’s API Key Terms of Use</a></p>
                          </li>
                          <li>
                            <p>2. Click Connect to Binance</p>
                          </li>
                          <li>
                            <p>3. Login at Binance</p>
                          </li>
                          <li>
                            <p>4. Click ‘Continue’ when prompted</p>
                          </li>
                        </ul>
                      </div>
                      <div className="connect-exchange-left">
                        <h4>Don’t have a Binance account?</h4>
                        <ul>
                          <li>
                            <p><a href="#/">Sign up for free at Binance</a></p>
                          </li>
                        </ul>
                        <h4 className="pt-30">Why do we need your API keys?</h4>
                        <ul>
                          <li>
                            <p>API keys are a unique set of identifiers that allow Cornix to programmatically perform actions on your behalf in your crypto exchange account (Binance, ByBit, etc).</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-xl-5">
                      <div className="select-exchange-input">
                        <label htmlFor="">Exchange</label>
                        <select className="form-select" aria-label="Default select example">
                          <option selected>Binance Spot</option>
                          <option value="1">Binance Limit</option>
                          <option value="2">Bitmart Spot</option>
                          <option value="3">Bitmart Limit</option>
                        </select>
                        <div className="connect-binance-btn-holder">
                          <NavLink to="/trade" className="btn btn-theme">Connect to Exchange</NavLink>
                          <NavLink to="/">Connect to Exchange with API keys instead</NavLink>
                        </div>
                        <div className="quick-connect-holder">
                          <span><svg viewBox="64 64 896 896" focusable="false" data-icon="link" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path></svg></span>
                          <p><span>Quick Connect</span> - Automatically create encrypted and IP-restricted API keys when connecting to Binance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">               
                          <table  className="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Sr.#	</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Exchange	</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Label</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Public Key	</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Private Key	</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Status</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Created At	</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                                <th scope="col">
                                  <div className="table-head-th">
                                    <span>Trade</span>
                                    <button>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                      </svg>
                                    </button>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="tr-bg-dark" >
                                <td>1</td>
                                <td>
                                  <div className="token-img">
                                    <div className="token-img-holder">
                                      <img src={BinanceSVG} alt="Binance" width={25} />
                                    </div>
                                    <div className="token-img-detail">
                                      <span>Binance</span>
                                    </div>
                                  </div>
                                </td>
                                <td >
                                  <div className="account-type-td">
                                    <ul>
                                      <li>
                                        <span>Hayat</span>
                                      </li>
                                    </ul>
                                  </div>	
                                </td>
                                <td>P1aloMZKPVDswNU...</td>
                                <td>pv2Jj33qT55PYlk...</td>
                                <td >
                                  <div className="trading-status-holder">
                                    <div className="badge bg-success">Created</div>
                                  </div>
                                </td>
                                <td >2022-04-11T21:35:25.462Z	</td>
                                <td>
                                  <NavLink to='/trade'>
                                    <button type="button" className="btn btn-sm btn-dark">Start Trading</button>
                                  </NavLink>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
      </div>
      )}
    </AuthContext.Consumer>

    );
  }
}

export default Dashboard;
