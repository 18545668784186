import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from 'moment';
import ApexCharts from "react-apexcharts";
import { Link, useLocation } from "react-router-dom";
import AllPairImage from "../../../images/all.png";
import SellPairImage from "../../../images/sell.png";
import BuyPairImage from "../../../images/buy.png";
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import Pagination from '../../../components/ClientArea/pagination/Pagination';
import { AuthContext } from "../../../context/AuthContext";
import { paginate } from '../../../helper/paginate';
// import { w3cwebsocket as W3CWebSocket } from "websocket";
const client = new WebSocket("ws://localhost:8080");
// const client = new WebSocket("wss://websocket-ccxt.vercel.app");
// const client1 = new WebSocket("wss://xtsocket.xt.com/websocket");

function MarketMaking(
{
  toggleActive,
  darkMode,
  setToggleActive,
  name,
}
) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  // console.log("location: ", location);
  const [auth] = useState(localStorage.getItem("auth"));
  const [role] = useState(localStorage.getItem("role"));
  const [
    exchangePair,
    // setExchangePair
  ] = useState(location.state.pair);
  const [
    // exchangePairErr,
    setExchangePairErr,
  ] = useState("");
  const [
    limitTradeAvailableAmountBuy,
    setLimitTradeAvailableAmountBuy,
  ] = useState(0);
  const [limitTradeBuyPrice, setLimitTradeBuyPrice] = useState(0);
  const [limitTradeBuyPriceErr, setLimitTradeBuyPriceErr] = useState(0);
  const [limitTradeBuyAmount, setLimitTradeBuyAmount] = useState(0);
  const [limitTradeBuyAmountErr, setLimitTradeBuyAmountErr] = useState(0);
  const [
    limitTradeEstimatedValueBuy,
    // setLimitTradeEstimatedValueBuy,
  ] = useState(0);
  const [
    limitTradeBuyAvailableAmountErr,
    setLimitTradeBuyAvailableAmountErr,
  ] = useState("");
  const [
    limitTradeAvailableAmountSell,
    setLimitTradeAvailableAmountSell,
  ] = useState(0);
  const [limitTradeSellPrice, setLimitTradeSellPrice] = useState(0);
  const [limitTradeSellPriceErr, setLimitTradeSellPriceErr] = useState(0);
  const [limitTradeSellAmount, setLimitTradeSellAmount] = useState(0);
  const [limitTradeSellAmountErr, setLimitTradeSellAmountErr] = useState(0);
  const [
    limitTradeEstimatedValueSell,
    // setLimitTradeEstimatedValueSell,
  ] = useState(0);
  const [
    limitTradeSellAvailableAmountErr,
    setLimitTradeSellAvailableAmountErr,
  ] = useState("");
  const [
    marketTradeAvailableAmountBuy,
    // setMarketTradeAvailableAmountBuy,
  ] = useState(0);
  const [
    marketTradeBuyPrice,
    // setMarketTradeBuyPrice
  ] = useState(0);
  const [
    marketTradeBuyAmount,
    // setMarketTradeBuyAmount
  ] = useState(0);
  const [
    marketTradeEstimatedValueBuy,
    // setMarketTradeEstimatedValueBuy,
  ] = useState(0);
  const [
    marketTradeAvailableAmountSell,
    // setMarketTradeAvailableAmountSell,
  ] = useState(0);
  const [
    marketTradeSellPrice,
    // setMarketTradeSellPrice
  ] = useState(0);
  const [
    marketTradeSellAmount,
    // setMarketTradeSellAmount
  ] = useState(0);
  const [
    marketTradeEstimatedValueSell,
    // setMarketTradeEstimatedValueSell,
  ] = useState(0);
  const [openOrders, setOpenOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [loadMarkets, setLoadMarkets] = useState([]);
  const [orderBook, setOrderBook] = useState([]);
  const [ticker, setTicker] = useState({});
  const [reverseLoadMarkets, setReverseLoadMarkets] = useState(false);
  const [searchMarket, setSearchMarket] = useState("");
  const [altMarket, setAltMarket] = useState("");
  const [otherMarket, setOtherMarket] = useState("");
  // const [marketTradeHistory, setMarketTradeHistory] = useState([]);
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);
  const [options] = useState({
    chart: {
      type: "candlestick",
      height: 350,
    },
    zoom: {
      enabled: true,
    },
    // title: {
    //   text: "CandleStick Chart",
    //   align: "left",
    // },
    xaxis: {
      type: "datetime",
    },
    selection: {
      enabled: true,
      xaxis: {
        min: new Date().getTime(),
        max: new Date().getTime(),
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      tickAmount: 4,
    },
  });
  const [
    candleTime,
    // setCandleTime
  ] = useState("15m");
  const [candleTimeChange, setCandleTimeChange] = useState(false);
  // const [timeStamp, setTimeStamp] = useState("");
  let sumAsks = 0;
  let sumBids = 0;
  let sumAllAsks = 0;
  let sumAllBids = 0;

  const pageSize = 10;
  const [openOrdersCurrentPage, setOpenOrdersCurrentPage] = useState(1);
  const [allOrdersPage, setAllOrdersPage] = useState(1);

  const handleOpenOrdersPageChange = (page) => {
      setOpenOrdersCurrentPage(page);
  };
  
  const handleAllOrdersPageChange = (page) => {
    setAllOrdersPage(page);
  }

  // Sort allOrders array by datetime
  const sortedAllOrders = allOrders.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
  
  const paginateOpenOrders = paginate(openOrders, openOrdersCurrentPage, pageSize);
  const paginateAllOrders = paginate(sortedAllOrders, allOrdersPage, pageSize);

  const limitTradeBuy = (e) => {
    e.preventDefault();

    setLimitTradeBuyPriceErr("");
    setLimitTradeBuyAmountErr("");
    setLimitTradeBuyAvailableAmountErr("");
    setExchangePairErr("");

    if (exchangePair === null || exchangePair === "") {
      setExchangePairErr("Exchange pair is required");
    } else if (limitTradeBuyPrice < 0 || limitTradeBuyPrice === null) {
      setLimitTradeBuyPriceErr("Buy Price is required");
    } else if (limitTradeBuyAmount < 0 || limitTradeBuyAmount === null) {
      setLimitTradeBuyAmountErr("Buy Amount is required");
    } else if (
      limitTradeBuyPrice * limitTradeBuyAmount >
      limitTradeAvailableAmountBuy
    ) {
      setLimitTradeBuyAvailableAmountErr("Insufficient balance");
    } else {
      if (location.state.exchange !== "xt") {
        const data = {
          exchangeName: location.state.exchange,
          label: location.state.label,
          privateKey: location.state.privateKey,
          publicKey: location.state.publicKey,
          pair: exchangePair,
          price: limitTradeBuyPrice,
          amount: limitTradeBuyAmount,
          side: "buy",
        };

        // console.log(data);

        axios
          .post(`${apiUrl}/exchange/create-limit-buy-order`, data, {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data) {
              if (res.data.success) {
                alert("Your order has been placed successfully");
                window.location.reload();
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        console.log("limitTradeBuy xt api");
      }
    }
  };

  const limitTradeSell = (e) => {
    e.preventDefault();

    setLimitTradeSellPriceErr("");
    setLimitTradeSellAmountErr("");
    setLimitTradeSellAvailableAmountErr("");
    setExchangePairErr("");

    if (exchangePair === null || exchangePair === "") {
      setExchangePairErr("Exchange pair is required");
    } else if (limitTradeSellPrice < 0 || limitTradeSellPrice === null) {
      setLimitTradeSellPriceErr("Market Price is required");
    } else if (limitTradeSellAmount < 0 || limitTradeSellAmount === null) {
      setLimitTradeSellAmountErr("Sell Amount is required");
    }
    // else if (
    //   limitTradeSellPrice * limitTradeSellAmount >
    //   limitTradeAvailableAmountSell
    // ) {
    //     setLimitTradeSellAvailableAmountErr("Insufficient balance");
    // }
    else {
      if (location.state.exchange !== "xt") {
        const data = {
          exchangeName: location.state.exchange,
          label: location.state.label,
          privateKey: location.state.privateKey,
          publicKey: location.state.publicKey,
          pair: exchangePair,
          price: limitTradeSellPrice,
          amount: limitTradeSellAmount,
          side: "sell",
        };

        axios
          .post(
            `${apiUrl}/exchange/create-limit-sell-order`,
            data,
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data) {
              if (res.data.success) {
                alert("Your order has been placed successfully");
                window.location.reload();
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        console.log("limitTradeSell xt api");
      }
    }
  };

  const cancalLimitTradeOrder = (e, id) => {
    e.preventDefault();

    const res = window.confirm(
      `Are your sure you want to cancel order: ${id}?`
    );

    if (res) {
      console.log("cancelling current order...");
      if (id) {
        if (location.state.exchange !== "xt") {
          const data = {
            exchangeName: location.state.exchange,
            label: location.state.label,
            privateKey: location.state.privateKey,
            publicKey: location.state.publicKey,
            pair: exchangePair,
          };

          axios
            .post(`${apiUrl}/exchange/order/cancel/${id}`, data, {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            })
            .then((res) => {
              console.log(res.data);

              if (res.data) {
                alert(res.data.message);
                window.location.reload();
              }
            })
            .catch((err) => console.log(err));
        } else {
          console.log("cancalLimitTradeOrder xt api");
        }
      }
    }
  };

  useEffect(() => {
    // const getXTWebsocketData = () => {
    //   client1.onmessage = (message) => {
    //     // console.log("message: ", JSON.parse(message.data));
    //     const data = JSON.parse(message.data);
    //     // if (data.data !== undefined && Object.keys(data.data).length > 0) {
    //     //   if (data.data.channel === "ex_chart_update") {
    //     //     if (
    //     //       data.data.records !== undefined &&
    //     //       data.data.records.length > 10
    //     //     ) {
    //     //       console.log("ex_chart_update first: ", data.data.records[0][4]);
    //     //       console.log(
    //     //         "ex_chart_update last: ",
    //     //         data.data.records[data.data.records.length - 1][4]
    //     //       );
    //     //       console.log(
    //     //         ("ex_chart_update last length: ", data.data.records.length)
    //     //       );
    //     //     }
    //     //   }
    //     // }
    //     const { ping } = JSON.parse(message.data);

    //     if (Object.keys(data).length > 0) {
    //       if (data.data) {
    //         const dataObj = data.data;
    //         // console.log("data.data: ", data.data);
    //         if (dataObj.asks) {
    //           if (dataObj.asks.length > 6 && dataObj.bids.length > 6) {
    //             setOrderBook(dataObj);
    //           }
    //         }
    //         if (dataObj.records !== undefined) {
    //           if (
    //             dataObj.records.length > 0 &&
    //             dataObj.records[0][0] === "gld_usdt"
    //           ) {
    //             // console.log("ticker object: ", dataObj.records[0]);
    //             setTicker(dataObj.records[0]);
    //           }
    //         }

    //         if (
    //           dataObj.records !== undefined &&
    //           dataObj.channel === "ex_chart_update"
    //         ) {
    //           // console.log("dataObj.records.length: ", dataObj.records.length);
    //           if (dataObj.records.length > 10) {
    //             updateGraph(dataObj.records);
    //           }
    //         }
    //       }
    //     }

    //     if (ping) {
    //       // setTimeStamp(ping);

    //       // console.log("timestamp: ", JSON.stringify({ pong: ping }));

    //       // client1.onopen = () => {
    //       client1.send(JSON.stringify({ pong: ping }));
    //       client1.send(JSON.stringify({ ping: Date.now() }));

    //       client1.send(
    //         JSON.stringify({
    //           channel: "ex_single_market",
    //           market: "gld_usdt",
    //           event: "addChannel",
    //         })
    //       );

    //       client1.send(
    //         JSON.stringify({
    //           channel: "ex_group_market",
    //           event: "addChannel",
    //         })
    //       );

    //       client1.send(
    //         JSON.stringify({
    //           channel: "ex_depth_data",
    //           market: "gld_usdt",
    //           event: "addChannel",
    //         })
    //       );

    //       client1.send(
    //         JSON.stringify({
    //           channel: "ex_last_trade",
    //           market: "gld_usdt",
    //           event: "removeChannel",
    //         })
    //       );

    //       let currentDateObj = new Date();
    //       let numberOfMlSeconds = currentDateObj.getTime();
    //       let subtractMlSeconds = 60 * 60 * 1000 * 6;
    //       let dateSixHoursBefore = new Date(
    //         numberOfMlSeconds - subtractMlSeconds
    //       ).getTime();

    //       client1.send(
    //         JSON.stringify({
    //           channel: "ex_chart_update",
    //           market: "gld_usdt",
    //           since: dateSixHoursBefore,
    //           interval: "15min",
    //           event: "addChannel",
    //         })
    //       );
    //       // };
    //     }
    //   };
    // };

    const getWebSocketData = () => {
      // client.onopen = () => {
      //   client.send("Client is connected");
      // };

      const data = {
        exchangeName: location.state.exchange,
        label: location.state.label,
        privateKey: location.state.privateKey,
        publicKey: location.state.publicKey,
        pair: location.state.pair,
        candle: candleTime,
        candleTimeChange: candleTimeChange,
      };
      // console.log(
      //   "exchange: ", 
      // data.exchangeName);
      // if(client.readyState === WebSocket.OPEN) {
      if (client !== undefined && (data.exchangeName !== undefined && data.exchangeName !== "")) {
        // console.log("sending...");
        client.send(JSON.stringify(data));
      }
      // } else {
      //   console.log('ws already open');
      console.log('client.readyState: ', client.readyState);
      // }

      client.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };
      // client.send(JSON.stringify(data));

      client.onmessage = (message) => {
        // console.log("message: ", message.data);
        // console.log("message: ", JSON.parse(message.data));
        if (Object.keys(JSON.parse(message.data)).length > 0) {
          const data = JSON.parse(message.data);

          if (data.asks) {
            setOrderBook(data);
          }

          if (data.last) {
            setTicker(data);
          }

          if (data.length > 10) {
            // console.log("data: ", data);
            updateGraph(data);
          }

          if (data.channel === "tickers") {
            let markets = {};
            markets = data.tickersData;
            let marketsArr = [];
            for (let market in markets) {
              marketsArr.push(markets[market]);
            }

            // console.log("marketsArr: ", marketsArr);
            if (marketsArr.length > 0) {
              setLoadMarkets(marketsArr);
            }
          }
        }
      };
    };

    const updateGraph = (data) => {
      // console.log("update graph data: ", data);
      let arrayData = [];
      // if (series[0].data.length > 0 && data.length > 0) {
      //   // console.log("data[0][0]: ", new Date(data[0][0]));
      //   console.log(series[0].data[0].y[0]);
      //   console.log(data[0][1]);
      // }

      setCandleTimeChange(false);
      // console.log("data latest value: ", data[data.length - 1][4]);
      // data
      //   .slice(450, 451)
      //   .map((val) => console.log("data first value: ", val[4]));
      // data
      //   .slice(499, 500)
      //   .map((val) => console.log("data last value: ", val[4]));

      if (location) {
        if (location.state) {
          if (location.state.exchange !== "xt") {
            // console.log("data first: ", data[0][4]);
            // console.log("data 450: ", data[450][4]);
            // console.log("data last: ", data[data.length - 1][4]);
            data.slice(420, 500).map((socketData) => {
              arrayData.push({
                x: new Date(socketData[0]),
                y: [socketData[1], socketData[2], socketData[3], socketData[4]],
              });
            });
          } else {
            if (data.length > 300) {
              // console.log("first element: ", data[0][4]);
              // console.log("last element: ", data[50][4]);

              data
                .slice(0, 80)
                .reverse()
                .map((socketData) => {
                  arrayData.push({
                    x: new Date(socketData[0] * 1000),
                    y: [
                      socketData[1],
                      socketData[2],
                      socketData[3],
                      socketData[4],
                    ],
                  });
                });
            } else {
              // console.log("first element: ", data[0][4]);
              // console.log("last element: ", data[50][4]);

              data
                .slice(0, 80)
                .reverse()
                .map((socketData) => {
                  arrayData.push({
                    x: new Date(socketData[0] * 1000),
                    y: [
                      socketData[1],
                      socketData[2],
                      socketData[3],
                      socketData[4],
                    ],
                  });
                });
            }
          }
        }
      }
      if (arrayData.length > 0) {
        // console.log("array data: ", arrayData);

        setSeries([
          {
            data: arrayData,
          },
        ]);
        arrayData = [];
      }
    };

    // const getOrderBook = () => {
    //   // console.log("Order book...");
    //   axios
    //     .get(
    //       `${apiUrl}/exchange/order-book?exchangeName=${location.state.exchange}&label=${location.state.label}&privateKey=${location.state.privateKey}&publicKey=${location.state.publicKey}&pair=${exchangePair}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${auth}`,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       // console.log(res.data);
    //       if (res.data) {
    //         setOrderBook(res.data.orderBook);
    //         setTicker(res.data.ticker);
    //       }
    //     })
    //     .catch((err) => console.log(err));
    // };

    // const getMarkets = () => {
    //   // console.log("Makets...");
    //   if (location.state.exchange !== "xt") {
    //     axios
    //       .get(
    //         `${apiUrl}/exchange/load-markets?exchangeName=${location.state.exchange}&label=${location.state.label}&privateKey=${location.state.privateKey}&publicKey=${location.state.publicKey}&pair=${exchangePair}`,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${auth}`,
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         // console.log(res.data);
    //         let markets = {};
    //         markets = res.data.loadMarkets;
    //         let marketsArr = [];
    //         for (let market in markets) {
    //           // console.log("market: ", markets[market]);
    //           marketsArr.push(markets[market]);
    //         }

    //         // console.log("marketsArr: ", marketsArr);
    //         if (res.data) {
    //           if (marketsArr.length > 0) {
    //             setLoadMarkets(marketsArr);
    //           }
    //           // res.data.OHLCV.map((data) => {
    //           //   // let date =
    //           //   //   new Date(data[0]).getUTCFullYear() +
    //           //   //   ", " +
    //           //   //   new Date(data[0]).getUTCMonth() +
    //           //   //   ", " +
    //           //   //   new Date(data[0]).getUTCDate();
    //           //   // let date = new Date(data[0]);
    //           //   series[0].data.push({
    //           //     x: new Date(data[0]),
    //           //     y: [data[1], data[2], data[3], data[4]],
    //           //   });
    //           // });
    //         }
    //         // console.log("series: ", series);
    //       })
    //       .catch((err) => console.log(err));
    //   } else {
    //     console.log("loadMarkets xt api");
    //   }
    // };

    const getAllOrders = () => {
      if (location.state.exchange !== "xt") {
        axios
          .get(
            `${apiUrl}/exchange/orders?exchangeName=${location.state.exchange}&label=${location.state.label}&privateKey=${location.state.privateKey}&publicKey=${location.state.publicKey}&pair=ADA/USDT`,
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data) {
              setAllOrders(res.data.allOrders);
              setOpenOrders(res.data.openOrders);
              setLimitTradeAvailableAmountBuy(
                JSON.parse(res.data.balance.USDT.free).toFixed(8)
              );
              setLimitTradeAvailableAmountSell(
                JSON.parse(res.data.balance.BTC.free).toFixed(8)
              );
            }
          })
          .catch((err) => console.log(err));
      } else {
        console.log("getAllOrders xt api");
      }
    };

    // getOrderBook();
    if (location) {
      if (location.state) {
        if (location.state.exchange !== "xt") {
          // console.log("getting ccxt websocket...");
          getWebSocketData();
        } else {
          // console.log("getting xt websocket...");
          // getXTWebsocketData();
        }
      }
    }

    // getMarkets();
    getAllOrders();

    // return () => {
    //   // Update the ref when the component is unmounted
    //   client.onmessage = (message) => {
    //     if (Object.keys(JSON.parse(message.data)).length > 0) {
    //       console.log("cleanup call")
    //       client.close();
    //     }
    //   }
    // };
  }, [
    location.state,
    auth,
    exchangePair,
    // orderBook
  ]);

  const resetErrorLimitTradeBuy = (e) => {
    e.preventDefault();

    setLimitTradeBuyPriceErr("");
    setLimitTradeBuyAmountErr("");
    setLimitTradeBuyAvailableAmountErr("");
    setExchangePairErr("");
  };

  const resetErrorLimitTradeSell = (e) => {
    e.preventDefault();

    setLimitTradeSellPriceErr("");
    setLimitTradeSellAmountErr("");
    setLimitTradeSellAvailableAmountErr("");
    setExchangePairErr("");
  };

  const handleMarketPairsFilter = () => {
    if (
      // loadMarkets !== undefined &&
      loadMarkets.length > 0 &&
      reverseLoadMarkets === false
    ) {
      loadMarkets.reverse();
      setReverseLoadMarkets(true);
    } else {
      loadMarkets.sort();
      setReverseLoadMarkets(false);
    }
  };

  const filteredMarkets = loadMarkets.filter((market) =>
    market.symbol.toLowerCase().includes(searchMarket.toLowerCase())
  );

  const handleSumAsks = (val) => {
    sumAsks =
      Number(parseFloat(sumAsks).toFixed(4)) +
      Number(parseFloat(val).toFixed(4));

    return parseFloat(sumAsks).toFixed(4);
  };

  const handleSumBids = (val) => {
    // console.log("val: ", val);
    // console.log("sumBids: ", sumBids);
    sumBids =
      Number(parseFloat(sumBids).toFixed(4)) +
      Number(parseFloat(val).toFixed(4));
    return parseFloat(sumBids).toFixed(4);
  };

  const handleSumAllAsks = (val) => {
    sumAllAsks =
      Number(parseFloat(sumAllAsks).toFixed(4)) +
      Number(parseFloat(val).toFixed(4));

    return parseFloat(sumAllAsks).toFixed(4);
  };

  const handleSumAllBids = (val) => {
    sumAllBids =
      Number(parseFloat(sumAllBids).toFixed(4)) +
      Number(parseFloat(val).toFixed(4));

    return parseFloat(sumAllBids).toFixed(4);
  };

  // const sendCandleTimeToSocket = (e, candleLatestTime) => {
  //   e.preventDefault();
  //   // console.log("sending candle time to socket...");
  //   setCandleTimeChange(true);
  //   if (candleTimeChange === true) {
  //     setSeries([
  //       {
  //         data: [],
  //       },
  //     ]);
  //   }
  //   const data = {
  //     exchangeName: location.state.exchange,
  //     label: location.state.label,
  //     privateKey: location.state.privateKey,
  //     publicKey: location.state.publicKey,
  //     pair: location.state.pair,
  //     candle: candleLatestTime,
  //     candleTimeChange: true,
  //   };

  //   client.send(JSON.stringify(data));
  // };

  // console.log("LoadMarkets Array: ", loadMarkets);
  // console.log("Order Book: ", orderBook);
  // console.log("Ticker: ", ticker);
  // console.log("Market pair: ", exchangePair);
  // console.log("chartdata: ", chartData);
  // console.log("alt market: ", altMarket);
  // console.log("other markte: ", otherMarket);
  // console.log("series: ", series);
  // console.log("candle time: ", candleTime);
  // console.log("location.state: ", location.state);
  
  // console.log({openOrders});
  // console.log({allOrders});
  return (
     <AuthContext.Consumer>
      {(context) => (
        <>
        <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
          <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          />
            <div className="z-theme-wrapper">
              <Header 
                toggleActive={toggleActive}
                setToggleActive={setToggleActive}
                name={name}
                darkMode={darkMode}
              />
            <section className="spot-tabs">
              <div className="container">
                <div className="row mb-30">
                  <div className="col-lg-12">
                    <div className="page-bradcrumbs">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                          <li className="breadcrumb-item"><Link to="/trade">Trade</Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Market Making</li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center mb-70">
                  <div className="col-lg-3">
                    <div className="trade-card trade-left-holder">
                      <div className="search-trade-left">
                        <input
                          type="text"
                          value={searchMarket}
                          onChange={(e) => setSearchMarket(e.target.value)}
                          // readOnly
                          placeholder="Search"
                          className="form-control"
                        />
                      </div>
                      <div className="trading-pair-holder">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-USD-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-USD"
                              type="button"
                              role="tab"
                              aria-controls="pills-USD"
                              aria-selected="true"
                            >
                              USD
                            </button>
                          </li>
                          {location.state.exchange !== "xt" ? (
                            <>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-ETF-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-ETF"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-ETF"
                                  aria-selected="false"
                                >
                                  ETF
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-BTC-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-BTC"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-BTC"
                                  aria-selected="false"
                                >
                                  BTC
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-ETH-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-ETH"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-ETH"
                                  aria-selected="false"
                                >
                                  ETH
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link nav-item-last"
                                  id="pills-ALTS-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-ALTS"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-ALTS"
                                  aria-selected="false"
                                >
                                  <select
                                    className="form-select"
                                    name="alts"
                                    aria-label="Default select example"
                                    onChange={(e) => setAltMarket(e.target.value)}
                                  >
                                    <option value="ALTS">ALTS</option>
                                    <option value="XT">XT</option>
                                    <option value="BNB">BNB</option>
                                    <option value="BITCI">BITCI</option>
                                  </select>
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link nav-item-last"
                                  id="pills-Zone-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-Zone"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-Zone"
                                  aria-selected="false"
                                >
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="other"
                                    onChange={(e) => setOtherMarket(e.target.value)}
                                  >
                                    <option value="Other">Other</option>
                                    <option value="Layer2">Layer2</option>
                                    <option value="DeFi">DeFi</option>
                                    <option value="Metaverse">Metaverse</option>
                                    <option value="Web 3.0">Web 3.0</option>
                                    <option value="Gray Scale">Gray Scale</option>
                                    <option value="Fan Token">Fan Token</option>
                                    <option value="DOGE">DOGE</option>
                                    <option value="MEMEs">MEMEs</option>
                                    <option value="DAI">DAI</option>
                                  </select>
                                </button>
                              </li>
                            </>
                          ) : null}
                        </ul>
                        <div className="trading-pair-filter d-flex justify-content-between">
                          <div className="filter-left">
                            <button
                              className="tading-filter"
                              onClick={() => handleMarketPairsFilter()}
                            >
                              Trading Pairs{" "}
                            </button>
                          </div>
                          <div className="filter-right">
                            <button className="tading-filter last-price-filter">
                              Last Price{" "}
                            </button>
                            <button className="tading-filter">Change </button>
                          </div>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-USD"
                            role="tabpanel"
                            aria-labelledby="pills-USD-tab"
                          >
                            <div className="trading-pair-accordian">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingOne">
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Main Zone
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    {location.state.exchange !== "xt" ? (
                                      <div className="accordion-body">
                                        <ul className="trading-pair-list">
                                          {loadMarkets !== undefined &&
                                            loadMarkets.length > 0 &&
                                            filteredMarkets.map((market, index) => (
                                              <li key={index}>
                                                <div className="trading-pair-list-left">
                                                  <h5>
                                                    {/* XT <span>/</span> <span>USDT</span> */}
                                                    {market.symbol}
                                                  </h5>
                                                </div>
                                                <div className="trading-pair-list-right">
                                                  <span className="pair-last-price">
                                                    {parseFloat(market.last).toFixed(
                                                      4
                                                    )}
                                                  </span>
                                                  <>
                                                    {String(
                                                      market.percentage
                                                    ).includes("-") === true ? (
                                                      <span className="pair-change text-danger">
                                                        {market.percentage + "%"}
                                                      </span>
                                                    ) : (
                                                      <span className="pair-change text-green">
                                                        {market.percentage + "%"}
                                                      </span>
                                                    )}
                                                  </>
                                                </div>
                                              </li>
                                            ))}
                                        </ul>
                                      </div>
                                    ) : (
                                      <div className="accordion-body">
                                        <ul className="trading-pair-list overflow-hidden">
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                GLD <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                {ticker[2]}
                                              </span>
                                              {String(ticker[3]).includes("-") ===
                                                true ? (
                                                <span className="pair-change text-danger">
                                                  {ticker[3]}
                                                </span>
                                              ) : (
                                                <span className="pair-change text-green">
                                                  {ticker[3]}
                                                </span>
                                              )}
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {location.state.exchange !== "xt" ? (
                                  <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Innovation Zone
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <ul className="trading-pair-list">
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-green">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-red">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-green">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-red">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-green">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-red">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-green">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                          <li>
                                            <div className="trading-pair-list-left">
                                              <h5>
                                                XT <span>/</span> <span>USDT</span>
                                              </h5>
                                            </div>
                                            <div className="trading-pair-list-right">
                                              <span className="pair-last-price">
                                                2.356
                                              </span>
                                              <span className="pair-change text-red">
                                                +30
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-ETF"
                            role="tabpanel"
                            aria-labelledby="pills-ETF-tab"
                          >
                            <div className="trading-pair-accordian">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="ETF">
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#ETF1"
                                      aria-expanded="true"
                                      aria-controls="ETF1"
                                    >
                                      Main Zone
                                    </button>
                                  </h2>
                                  <div
                                    id="ETF1"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="ETF"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <ul className="trading-pair-list">
                                        <li>
                                          <div className="trading-pair-list-left">
                                            <h5>
                                              XT <span>/</span> <span>USDT</span>
                                            </h5>
                                          </div>
                                          <div className="trading-pair-list-right">
                                            <span className="pair-last-price">
                                              2.356
                                            </span>
                                            <span className="pair-change text-green">
                                              +30
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="etf-2">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#etf2"
                                      aria-expanded="false"
                                      aria-controls="etf2"
                                    >
                                      Innovation Zone
                                    </button>
                                  </h2>
                                  <div
                                    id="etf2"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="etf-2"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="accordion-body">
                                      <ul className="trading-pair-list">
                                        <li>
                                          <div className="trading-pair-list-left">
                                            <h5>
                                              XT <span>/</span> <span>USDT</span>
                                            </h5>
                                          </div>
                                          <div className="trading-pair-list-right">
                                            <span className="pair-last-price">
                                              2.356
                                            </span>
                                            <span className="pair-change text-red">
                                              +30
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-BTC"
                            role="tabpanel"
                            aria-labelledby="pills-BTC-tab"
                          >
                            <div className="accordion-body">
                              <ul className="trading-pair-list">
                                {loadMarkets !== undefined &&
                                  loadMarkets.length > 0 &&
                                  filteredMarkets
                                    .filter((market) => market.quote === "BTC")
                                    .map((market, index) => (
                                      <li key={index}>
                                        <div className="trading-pair-list-left">
                                          <h5>
                                            {/* XT <span>/</span> <span>USDT</span> */}
                                            {market.symbol}
                                          </h5>
                                        </div>
                                        <div className="trading-pair-list-right">
                                          <span className="pair-last-price">
                                            {parseFloat(market.last).toFixed(4)}
                                          </span>
                                          <>
                                            {String(market.percentage).includes(
                                              "-"
                                            ) === true ? (
                                              <span className="pair-change text-danger">
                                                {market.percentage + "%"}
                                              </span>
                                            ) : (
                                              <span className="pair-change text-green">
                                                {market.percentage + "%"}
                                              </span>
                                            )}
                                          </>
                                        </div>
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-ETH"
                            role="tabpanel"
                            aria-labelledby="pills-ETH-tab"
                          >
                            <div className="accordion-body">
                              <ul className="trading-pair-list">
                                {loadMarkets !== undefined &&
                                  loadMarkets.length > 0 &&
                                  filteredMarkets
                                    .filter((market) => market.quote === "ETH")
                                    .map((market, index) => (
                                      <li key={index}>
                                        <div className="trading-pair-list-left">
                                          <h5>
                                            {/* XT <span>/</span> <span>USDT</span> */}
                                            {market.symbol}
                                          </h5>
                                        </div>
                                        <div className="trading-pair-list-right">
                                          <span className="pair-last-price">
                                            {parseFloat(market.last).toFixed(4)}
                                          </span>
                                          <>
                                            {String(market.percentage).includes(
                                              "-"
                                            ) === true ? (
                                              <span className="pair-change text-danger">
                                                {market.percentage + "%"}
                                              </span>
                                            ) : (
                                              <span className="pair-change text-green">
                                                {market.percentage + "%"}
                                              </span>
                                            )}
                                          </>
                                        </div>
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-ALTS"
                            role="tabpanel"
                            aria-labelledby="pills-ALTS-tab"
                          >
                            <div className="accordion-body">
                              <ul className="trading-pair-list">
                                {loadMarkets !== undefined &&
                                  loadMarkets.length > 0 &&
                                  filteredMarkets
                                    .filter(
                                      (market) =>
                                        market.quote === altMarket ||
                                        market.base === altMarket
                                    )
                                    .map((market, index) => (
                                      <li key={index}>
                                        <div className="trading-pair-list-left">
                                          <h5>
                                            {/* XT <span>/</span> <span>USDT</span> */}
                                            {market.symbol}
                                          </h5>
                                        </div>
                                        <div className="trading-pair-list-right">
                                          <span className="pair-last-price">
                                            {parseFloat(market.last).toFixed(4)}
                                          </span>
                                          <>
                                            {String(market.percentage).includes(
                                              "-"
                                            ) === true ? (
                                              <span className="pair-change text-danger">
                                                {market.percentage + "%"}
                                              </span>
                                            ) : (
                                              <span className="pair-change text-green">
                                                {market.percentage + "%"}
                                              </span>
                                            )}
                                          </>
                                        </div>
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-Zone"
                            role="tabpanel"
                            aria-labelledby="pills-Zone-tab"
                          >
                            <div className="accordion-body">
                              {otherMarket === "ALTS" ? (
                                <ul className="trading-pair-list">Select ALTS</ul>
                              ) : (
                                <ul className="trading-pair-list">
                                  {loadMarkets !== undefined &&
                                    loadMarkets.length > 0 &&
                                    filteredMarkets
                                      .filter(
                                        (market) =>
                                          market.quote === otherMarket ||
                                          market.base === otherMarket
                                      )
                                      .map((market, index) => (
                                        <li key={index}>
                                          <div className="trading-pair-list-left">
                                            <h5>
                                              {/* XT <span>/</span> <span>USDT</span> */}
                                              {market.symbol}
                                            </h5>
                                          </div>
                                          <div className="trading-pair-list-right">
                                            <span className="pair-last-price">
                                              {parseFloat(market.last).toFixed(4)}
                                            </span>
                                            <>
                                              {String(market.percentage).includes(
                                                "-"
                                              ) === true ? (
                                                <span className="pair-change text-danger">
                                                  {market.percentage + "%"}
                                                </span>
                                              ) : (
                                                <span className="pair-change text-green">
                                                  {market.percentage + "%"}
                                                </span>
                                              )}
                                            </>
                                          </div>
                                        </li>
                                      ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 trade-card">
                    <div className="spot-trade-img mb-40">
                      {/* <img src={TradeImage} alt="trade-img" /> */}
                      {series[0].data.length > 0 && candleTimeChange === false ? (
                        <ApexCharts
                          options={options}
                          series={series}
                          type="candlestick"
                          height={350}
                        />
                      ) : (
                        <div className="mt-5 text-center">
                          <p className="text-danger">Loading...</p>
                        </div>
                      )}
                    </div>
                    <div className="spot-tabs-holder">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-Limit-Trade-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Limit-Trade"
                            type="button"
                            role="tab"
                            aria-controls="pills-Limit-Trade"
                            aria-selected="true"
                          >
                            Limit Trade
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-Market-Trade-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Market-Trade"
                            type="button"
                            role="tab"
                            aria-controls="pills-Market-Trade"
                            aria-selected="false"
                          >
                            Market Trade
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-Limit-Trade"
                          role="tabpanel"
                          aria-labelledby="pills-Limit-Trade-tab"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="limit-form">
                                <form>
                                  <h4>
                                    Available:{" "}
                                    <span className="text-green">
                                      {limitTradeAvailableAmountBuy}
                                    </span>{" "}
                                    USDT
                                  </h4>
                                  <div className="l-inp-holder mb-20">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Buy Price"
                                      value={limitTradeBuyPrice}
                                      onClick={(e) => resetErrorLimitTradeBuy(e)}
                                      onChange={(e) =>
                                        setLimitTradeBuyPrice(e.target.value)
                                      }
                                    />
                                    <span>USDT</span>
                                  </div>
                                  {limitTradeBuyPriceErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeBuyPriceErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="l-inp-holder mb-30">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={limitTradeBuyAmount}
                                      placeholder="Buy Amount"
                                      onClick={(e) => resetErrorLimitTradeBuy(e)}
                                      onChange={(e) =>
                                        setLimitTradeBuyAmount(e.target.value)
                                      }
                                    />
                                    <span>BTC</span>
                                  </div>
                                  {limitTradeBuyAmountErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeBuyAmountErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="est-value">
                                    <h4>
                                      Estimated Value:{" "}
                                      <span className="text-green">
                                        {limitTradeBuyPrice > 0 &&
                                          limitTradeBuyAmount > 0
                                          ? limitTradeBuyPrice * limitTradeBuyAmount
                                          : limitTradeEstimatedValueBuy}
                                      </span>{" "}
                                      USDT
                                    </h4>
                                  </div>
                                  {limitTradeBuyAvailableAmountErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeBuyAvailableAmountErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  {
                                    role && role === "admin" ?
                                    <button
                                      className="btn-spot btn-buy"
                                      onClick={(e) => limitTradeBuy(e)}
                                    >
                                      Buy
                                    </button>
                                    :
                                    <button
                                      className="btn-spot btn-buy"
                                      disabled
                                    >
                                      Buy
                                    </button>
                                  }
                                </form>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="limit-form">
                                <form>
                                  <h4>
                                    Available:{" "}
                                    <span className="text-red">
                                      {limitTradeAvailableAmountSell}
                                    </span>{" "}
                                    BTC
                                  </h4>
                                  <div className="l-inp-holder mb-20">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={limitTradeSellPrice}
                                      onClick={(e) => resetErrorLimitTradeSell(e)}
                                      onChange={(e) =>
                                        setLimitTradeSellPrice(e.target.value)
                                      }
                                      placeholder="Best Market Price"
                                    />
                                    <span>USDT</span>
                                  </div>
                                  {limitTradeSellPriceErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeSellPriceErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="l-inp-holder mb-30">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={limitTradeSellAmount}
                                      onClick={(e) => resetErrorLimitTradeSell(e)}
                                      onChange={(e) =>
                                        setLimitTradeSellAmount(e.target.value)
                                      }
                                      placeholder="Sell Amount"
                                    />
                                    <span>BTC</span>
                                  </div>
                                  {limitTradeSellAmountErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeSellAmountErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  <div className="est-value">
                                    <h4>
                                      Estimated Value:{" "}
                                      <span className="text-red">
                                        {limitTradeSellPrice > 0 &&
                                          limitTradeSellAmount > 0
                                          ? limitTradeSellPrice * limitTradeSellAmount
                                          : limitTradeEstimatedValueSell}
                                      </span>{" "}
                                      USDT
                                    </h4>
                                  </div>
                                  {limitTradeSellAvailableAmountErr ? (
                                    <div className="text-left">
                                      <p className="text-danger">
                                        {limitTradeSellAvailableAmountErr}
                                      </p>
                                    </div>
                                  ) : null}
                                  {
                                    role && role === "admin" ?
                                    <button
                                      className="btn-spot btn-sell"
                                      onClick={(e) => limitTradeSell(e)}
                                    >
                                      Sell
                                    </button>
                                    :
                                    <button
                                      className="btn-spot btn-sell"
                                      disabled
                                    >
                                      Sell
                                    </button>
                                  }
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-Market-Trade"
                          role="tabpanel"
                          aria-labelledby="pills-Market-Trade-tab"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="limit-form">
                                <form>
                                  <h4>
                                    Available:{" "}
                                    <span className="text-green">
                                      {marketTradeAvailableAmountBuy}
                                    </span>{" "}
                                    USDT
                                  </h4>
                                  <div className="l-inp-holder mb-20">
                                    <input
                                      type="number"
                                      className="form-control text-green"
                                      value={marketTradeBuyPrice}
                                      placeholder="Best Market Price"
                                      onChange={(e) => console.log(e.target.value)}
                                    />
                                    <span>USDT</span>
                                  </div>
                                  <div className="l-inp-holder mb-30">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={marketTradeBuyAmount}
                                      placeholder="Order Price"
                                      onChange={(e) => console.log(e.target.value)}
                                    />
                                    <span>BTC</span>
                                  </div>
                                  <div className="est-value">
                                    <h4>
                                      Estimated Value:{" "}
                                      <span className="text-green">
                                        {marketTradeEstimatedValueBuy}
                                      </span>{" "}
                                      USDT
                                    </h4>
                                  </div>
                                  {
                                    role && role === "admin" ?
                                    <button
                                      className="btn-spot btn-buy"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        alert("Buy Now");
                                      }}
                                    >
                                      Buy
                                    </button>
                                    :
                                    <button
                                      className="btn-spot btn-buy"
                                      disabled
                                    >
                                      Buy
                                    </button>
                                  }
                                </form>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="limit-form">
                                <form>
                                  <h4>
                                    Available:{" "}
                                    <span className="text-red">
                                      {marketTradeAvailableAmountSell}
                                    </span>{" "}
                                    BTC
                                  </h4>
                                  <div className="l-inp-holder mb-20">
                                    <input
                                      type="number"
                                      className="form-control text-red"
                                      value={marketTradeSellPrice}
                                      placeholder="Best Market Price"
                                      onChange={(e) => console.log(e.target.value)}
                                    />
                                    <span>USDT</span>
                                  </div>
                                  <div className="l-inp-holder mb-30">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={marketTradeSellAmount}
                                      placeholder="Sell Amount"
                                      onChange={(e) => console.log(e.target.value)}
                                    />
                                    <span>BTC</span>
                                  </div>
                                  <div className="est-value">
                                    <h4>
                                      Estimated Value:{" "}
                                      <span className="text-red">
                                        {marketTradeEstimatedValueSell}
                                      </span>{" "}
                                      USDT
                                    </h4>
                                  </div>
                                  {
                                    role && role === "admin" ?
                                    <button
                                      className="btn-spot btn-sell"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        alert("Sell Now");
                                      }}
                                    >
                                      Sell
                                    </button>
                                    :
                                    <button
                                      className="btn-spot btn-sell"
                                      disabled
                                    >
                                      Sell
                                    </button>
                                  }
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="trade-card">
                      <div className="order-card-holder">
                        <div className="order-card-header">
                          <div className="order-card-heading">
                            <h4>Orders</h4>
                          </div>
                          <div className="order-card-tabs">
                            <ul
                              className="nav nav-pills"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="pills-All-Trading-Pair-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-All-Trading-Pair"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-All-Trading-Pair"
                                  aria-selected="true"
                                >
                                  <img
                                    src={AllPairImage}
                                    alt="all pair"
                                    width="20px"
                                  />
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-Sell-Trading-Pair-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-Sell-Trading-Pair"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-Sell-Trading-Pair"
                                  aria-selected="false"
                                >
                                  <img
                                    src={SellPairImage}
                                    alt="all pair"
                                    width="20px"
                                  />
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-Buy-Trading-pair-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-Buy-Trading-pair"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-Buy-Trading-pair"
                                  aria-selected="false"
                                >
                                  <img
                                    src={BuyPairImage}
                                    alt="all pair"
                                    width="20px"
                                  />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="order-card-center-body">
                          <span>Price [USDT]</span>
                          <span>Amount [BTC]</span>
                          <span>Total [USDT]</span>
                        </div>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="pills-All-Trading-Pair"
                            role="tabpanel"
                            aria-labelledby="pills-All-Trading-Pair-tab"
                          >
                            <ul className="order-card-pair-price">
                              {orderBook !== undefined &&
                                orderBook.asks !== undefined &&
                                orderBook.asks
                                  .slice(0, 7)
                                  .map((order, index) => (
                                    <li key={index}>
                                      <span className="text-red">
                                        {parseFloat(order[0]).toFixed(2)}
                                      </span>
                                      <span className="text-right">
                                        {parseFloat(order[1]).toFixed(6)}
                                      </span>
                                      <span
                                      // className="bg-rgba-red"
                                      >
                                        {handleSumAsks(order[1])}
                                      </span>
                                    </li>
                                  ))
                                  .reverse()}
                            </ul>
                            <ul className="cuurent-pair-price">
                              <li>
                                {location.state.exchange !== "xt" ? (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker.last).toFixed(4)
                                      : 0}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker.last).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                ) : (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker[2]).toFixed(4)
                                      : parseFloat(0).toFixed(4)}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker[2]).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                )}
                              </li>
                              {/* <li>
                                <span>More</span>
                              </li> */}
                            </ul>
                            <ul className="order-card-pair-price order-pair-buying">
                              {orderBook !== undefined &&
                                orderBook.bids !== undefined &&
                                orderBook.bids.slice(0, 7).map((order, index) => (
                                  <li key={index}>
                                    <span className="text-green">
                                      {parseFloat(order[0]).toFixed(2)}
                                    </span>
                                    <span className="text-right">
                                      {parseFloat(order[1]).toFixed(6)}
                                    </span>
                                    <span
                                    // className="bg-rgba-green"
                                    >
                                      {handleSumBids(order[1])}
                                    </span>
                                  </li>
                                ))
                                // .reverse()
                              }
                            </ul>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-Sell-Trading-Pair"
                            role="tabpanel"
                            aria-labelledby="pills-Sell-Trading-Pair-tab"
                          >
                            <ul className="order-card-pair-price">
                              {orderBook !== undefined &&
                                orderBook.asks !== undefined &&
                                orderBook.asks
                                  .slice(0, 14)
                                  .map((order, index) => (
                                    <li key={index}>
                                      <span className="text-red">
                                        {parseFloat(order[0]).toFixed(2)}
                                      </span>
                                      <span className="text-right">
                                        {parseFloat(order[1]).toFixed(6)}
                                      </span>
                                      <span
                                      // className="bg-rgba-red"
                                      >
                                        {handleSumAllAsks(order[1])}
                                      </span>
                                    </li>
                                  ))
                                  .reverse()}
                            </ul>
                            <ul className="cuurent-pair-price">
                              <li>
                                {location.state.exchange !== "xt" ? (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker.last).toFixed(4)
                                      : 0}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker.last).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                ) : (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker[2]).toFixed(4)
                                      : parseFloat(0).toFixed(4)}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker[2]).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                )}
                              </li>
                              {/* <li>
                                <span>More</span>
                              </li> */}
                            </ul>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-Buy-Trading-pair"
                            role="tabpanel"
                            aria-labelledby="pills-Buy-Trading-pair-tab"
                          >
                            <ul className="cuurent-pair-price">
                              <li>
                                {location.state.exchange !== "xt" ? (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker.last).toFixed(4)
                                      : 0}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker.last).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                ) : (
                                  <h4>
                                    {Object.keys(ticker).length > 0
                                      ? parseFloat(ticker[2]).toFixed(4)
                                      : parseFloat(0).toFixed(4)}
                                    <span className="ml-5">
                                      ≈{" "}
                                      {Object.keys(ticker).length > 0
                                        ? parseFloat(ticker[2]).toFixed(2)
                                        : parseFloat(0).toFixed(2)}{" "}
                                      USD
                                    </span>
                                  </h4>
                                )}
                              </li>
                              {/* <li>
                                <span>More</span>
                              </li> */}
                            </ul>
                            <ul className="order-card-pair-price order-pair-buying">
                              {orderBook !== undefined &&
                                orderBook.bids !== undefined &&
                                orderBook.bids
                                  .slice(0, 14)
                                  .map((order, index) => (
                                    <li key={index}>
                                      <span className="text-green">
                                        {parseFloat(order[0]).toFixed(2)}
                                      </span>
                                      <span className="text-right">
                                        {parseFloat(order[1]).toFixed(6)}
                                      </span>
                                      <span
                                      // className="bg-rgba-green"
                                      >
                                        {handleSumAllBids(order[1])}
                                      </span>
                                    </li>
                                  ))
                                  .reverse()}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="spot-tabs-holder trade-card ">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-Open-Order-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Open-Order"
                            type="button"
                            role="tab"
                            aria-controls="pills-Open-Order"
                            aria-selected="false"
                          >
                            Open Orders
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-Order-History-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Order-History"
                            type="button"
                            role="tab"
                            aria-controls="pills-Order-History"
                            aria-selected="true"
                          >
                            Order History
                          </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-Trade-History-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Trade-History"
                            type="button"
                            role="tab"
                            aria-controls="pills-Trade-History"
                            aria-selected="false"
                          >
                            Market Trade
                          </button>
                        </li> */}
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-Open-Order"
                          role="tabpanel"
                          aria-labelledby="pills-Open-Order-tab"
                        >
                          <div className="table-responsive spot-table">
                            {paginateOpenOrders.length > 0 ? (
                              <>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Date & Time</th>
                                      <th scope="col">Market/Symbol</th>
                                      <th scope="col">Order Type/Side</th>
                                      <th scope="col">Volume/Order Qty</th>
                                      <th scope="col">Order Price</th>
                                      <th scope="col">Order Est.Value</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {paginateOpenOrders.map((order) => (
                                      <tr
                                        key={order.clientOrderId}
                                        className="text-center"
                                      >
                                        <td>{moment(order.datetime).format("LLL")}</td>
                                        <td>{order.symbol}</td>
                                        <td>{order.side}</td>
                                        <td>{order.info.origQty}</td>
                                        <td>{order.price}</td>
                                        <td>
                                          {JSON.parse(
                                            order.price * order.amount
                                          ).toFixed(4)}
                                        </td>
                                        <td>
                                        {
                                          role && role === "admin" ?
                                          <button
                                            className="btn btn-sm btn-dark"
                                            onClick={(e) =>
                                              cancalLimitTradeOrder(e, order.id)
                                            }
                                          >
                                            Cancel
                                          </button>
                                          :
                                          <button
                                          className="btn btn-sm btn-dark"
                                          disabled
                                        >
                                          Cancel
                                        </button>
                                        }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <Pagination
                                  items={openOrders.length}
                                  pageSize={pageSize}
                                  currentPage={openOrdersCurrentPage}
                                  onPageChange={handleOpenOrdersPageChange}
                                />
                              </>
                            ) : (
                              <div className="mt-5 text-center">
                                <p className="text-danger">
                                  No open orders are available
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-Order-History"
                          role="tabpanel"
                          aria-labelledby="pills-Order-History-tab"
                        >
                          <div className="table-responsive spot-table">
                            {paginateAllOrders.length > 0 ? (
                              <>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Time</th>
                                      <th scope="col">Market/Type</th>
                                      <th scope="col">Traded Price/Order Price</th>
                                      <th scope="col">Volume/Order Qty</th>
                                      <th scope="col">Turnover/Order Price</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">BUY/SELL</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      paginateAllOrders
                                        .reverse()
                                        .map((order) => (
                                        <tr
                                          key={order.clientOrderId}
                                          className="text-center"
                                        >
                                          <td>{moment(order.datetime).format("LLL")}</td>
                                          <td>{order.symbol}</td>
                                          <td>{order.price}</td>
                                          <td>{order.info.executedQty}</td>
                                          <td>{order.info.price}</td>
                                          <td>{order.info.status}</td>
                                          <td>{order.side}</td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                                <Pagination
                                  items={allOrders.length}
                                  pageSize={pageSize}
                                  currentPage={allOrdersPage}
                                  onPageChange={handleAllOrdersPageChange}
                                />
                              </>
                            ) : (
                              <div className="mt-5 text-center">
                                <p className="text-danger">Order history is empty</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div
                          className="tab-pane fade"
                          id="pills-Trade-History"
                          role="tabpanel"
                          aria-labelledby="pills-Trade-History-tab"
                        >
                          <div className="table-responsive spot-table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Time</th>
                                  <th scope="col">Market/Type</th>
                                  <th scope="col">Traded Price/Order Price</th>
                                  <th scope="col">Volume/Order Qty</th>
                                  <th scope="col">Turnover/Order Price</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Cancel Order</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th scope="row"></th>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>
      </>
      )}
    </AuthContext.Consumer>
  );
}

export default MarketMaking;
