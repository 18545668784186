import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import { Link } from "react-router-dom";
import Loader from "../../../components/Frontend/loader/Loader";

function Terms() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />

      <Header />
      {/* Policy Banner */}
			<section className="policy-holder">
				<div className="container">
					<div className="row">
						<div className="main-policy-heading">
							<h3>MarketMaking – Terms of Use</h3>
						</div>
					</div>
				</div>
			</section>
			{/* Policy Banner */}

			{/* Policy Content */}
			<section className="policy-content-main">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="policy-content-holder">
								<div className="policy-content-detail">
									<h4>Welcome to Market Making!</h4>
									<p>The Services (as defined below) are provided to you by MarketMaking. (“ <strong>Company</strong> “) through its applications and on its website located at mm.netrosol.com (collectively, the “ <strong>Platform</strong> “). These Terms of Use govern your use of the Services.</p>
									<p className="pt-20">Please carefully read these terms of use (“Terms“) as well as our privacy policy which we may update from time to time, a current version of which is available <Link to="/privacy-policy">here</Link> (“ <strong>Privacy Policy</strong> “)</p>
                                    <p className="pt-20">By accessing the Services, you agree to the Terms and Privacy Policy. “User” means a user of the Services. If you do not agree to the Terms and Privacy Policy, you should stop using the Services immediately.</p>
                                    <p className="pt-20">We may revise the Terms at any time without notice by updating the Terms on our Platform. You should periodically visit these Terms to review the current terms that apply to your use of the Services. Any use of the Services by you after our publication of any such changes shall constitute your acceptance of these Terms as modified. We may, at our sole discretion and at any time, discontinue providing the Services, or any part thereof without notice.</p>
								</div>
								<div className="policy-content-detail">
									<h4>Use of the Services</h4>
									<p>The Company is the owner of a proprietary software service that allows its end users to create and configure multifaceted trades for execution on third-party online cryptocurrency exchanges by creating their own trading models or following signals (“ <strong>Signals</strong> “) from various third party group leaders through channels which are integrated or created within Company’s software service provided on its Platform (the “ <strong>Services</strong> “)</p>
									<p className="pt-20">The Services are not, nor should they be considered as, the provision of investment advice, portfolio management, financial advice, cryptocurrency exchange or custody or any other financial service, or investment services or activities, or ancillary services under any applicable law, or endorsement of any third party providing one of the aforementioned activities. The Services are not regulated by financial or investor protection laws and you will not benefit from protections and remedies which are available to clients of regulated services against any damages arising from your use of the Services. The Services pose a high risk of financial loss and you should consider seeking independent and professional advice before using them.</p>
									<p className="pt-20">You expressly acknowledge and agree that Company does not endorse or recommend any signal providers or any third party marketplaces that are integrated with the Services. Company is not responsible for activities of any signal providers or cryptocurrency marketplaces (including, among others, with respect to contents generated by signal providers or the execution of trading orders) and shall not be held liable for losses incurred by interacting with such third parties using the Services.</p>
                                    <p className="pt-20">In order to use the Services you may be required, at Company’s sole discretion, to undergo and successfully pass know-your-customer (KYC) and customer due diligence procedures or screening, or execute and deliver to the Company, or to any other entity on its behalf, transaction documents related to the Services under any applicable laws, including any anti-money laundering and counterterrorism financing laws in any jurisdiction.</p>
								</div>
								<div className="policy-content-detail">
									<h4>Consideration</h4>
									<p>In order to use the Services you will have to register and may be required to pay the fees indicated on the Platform for the applicable Services (“ <strong>Subscription Fee</strong> ”).</p>
                                    <p className="pt-20">The Subscription Fee and Plans structure may be reasonably changed from time to time, in our sole discretion, with fourteen (14) days’ notice on our Platform. If the Subscription Fee is raised more than twenty percent (20%) in any given month we will request User’s consent thereof.</p>
								</div>
								<div className="policy-content-detail">
									<h4>No Warranty</h4>
									<p>YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK AND THE COMPANY SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED (WHETHER BY LAW, STATUTE OR OTHERWISE). INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT (WHETHER UNDER APPLICABLE LAW OR OTHERWISE).</p>
                                    <p className="pt-20">THE COMPANY DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, THAT YOUR ACCESS TO THE SERVICES WILL BE ACCURATE, FREE OF ERROR, COMPLETE, UNINTERRUPTED, CONTINUOUS, OR THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM IS FREE OF VIRUSES AND BUGS. THE COMPANY MAKES NO REPRESENTATION CONCERNING THE FULL OR PARTIAL FUNCTIONALITY, ACCURACY, OR RELIABILITY OF ANY CONTENT, INFORMATION OR MATERIALS OBTAINED BY YOU THROUGH THE SERVICES.</p>
								</div>
								<div className="policy-content-detail">
									<h4>Refund Policy</h4>
									<p>We do not offer refunds for initial payments for any of the subscription plans. If you order the Services for the first time please make sure that your order is correct before the payment is made. There are no refunds for monthly subscription plans, even if the monthly subscription plan is canceled on the same day as the auto renewal payment of the monthly Subscription Fee has been made.</p>
                                    <p className="pt-20">We do not offer refunds for upgrades to a more expensive subscription plan.</p>
                                    <p>Refunds for recurring annual subscription plans for any of the subscription plans: If you have been billed for automatic annual renewal you have fourteen (14) calendar days (after the payment was made) to contact our support team. If you don’t do this, the payment will not be refunded.</p>
                                    <p>Users who file a chargeback/dispute request or a claim directly with their payment processor of choice are not eligible for a refund through the Company.</p>
                                    <p>The Services are billed in advance on a monthly or annual basis. There will be no refunds or credits to any active User account for: (i) partial use of the annual subscription plan for the Services; or (ii) any unused time periods during the subscription plan.</p>
								</div>
								<div className="policy-content-detail">
									<h4>Miscellaneous</h4>
									<p>These Terms shall be governed by, and interpreted in accordance with the law of Israel. Any action to be brought in connection with these Terms shall be brought exclusively in the courts of Tel-Aviv-Jaffa, Israel and you irrevocably consent to their jurisdiction. In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision that most closely achieves the effect of the original provision, and the remaining provisions of these Terms shall remain in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Company or authorizes you to act on behalf of Company. We may assign our rights and obligations hereunder to any third party without prior notice. You shall not assign any of your rights or obligations hereunder, and any assignment shall be null and void. Any provisions hereof which expressly or by their nature are required to survive termination or expiration of these Terms in order to achieve their purpose shall so survive until it shall no longer be necessary for them to survive in order to achieve that purpose. Any translation of these Terms of Use is provided solely for your convenience. The meanings of terms, conditions and representations herein are subject to definitions and interpretations in the English language and in the event of a dispute, the English language version shall prevail. Any translation provided may not accurately represent the information in the original English language version of these Terms.</p>
								</div>
								<div className="policy-content-detail">
									<h4>9. Further Information</h4>
									<p>If you have any further questions regarding the data collected, or how we use it, please contact us via <a href="mailto:support@mm.netrosol.com">support@mm.netrosol.com</a>.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      <Footer />
    </div>
  );
}

export default Terms;
