import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import AllTicket from "../../../assets/images/ticket-images/total-ticket.png"
import ClosedTicket from "../../../assets/images/ticket-images/closed-ticket.png"
import ProgressTicket from "../../../assets/images/ticket-images/progress-ticket.png"
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment/moment';
import { AuthContext } from "../../../context/AuthContext";
import SuccessMessageModal from '../../../components/ClientArea/Modals/SuccessModal'

const Tickets = (
  {
    toggleActive,
    setToggleActive,
    name,
    darkMode,

  }
) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const context = useContext(AuthContext);
  const {
    alertDialogModalStatus,
    setAlertDialogModalStatus,
    setAlertDialogModalMessage
  } = context;
  const [auth] = useState(localStorage.getItem('auth'));
  const [role] = useState(localStorage.getItem('role'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [ticketId, setTicketId] = useState('');
  const [tickets, setTickets] = useState([]);
  const [ticketMessage, setTicketMessage] = useState('');

  useEffect(() => {
    getSupportTickets();
  }, [])

  const getSupportTickets = async () => {
    // console.log("getSupportTickets...");
    axios
      .get(`${apiUrl}/support/all?userId=${userId}&role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          setTickets(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  }

  const handlePopupModal = (e, id) => {
    e.preventDefault();

    // setting the id of the ticket to be deleted from custom popup modal
    setTicketId(id);
  }

  const deleteTicket = async (e) => {
    e.preventDefault();
    
    try {  
      // Make a DELETE request to the server
      const response = await axios.delete(`${apiUrl}/support/${ticketId}/del?userId=${userId}&role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
  
      if (response.data.success) {
        setAlertDialogModalStatus(true);
        setAlertDialogModalMessage('Ticket deleted successfully');
        setTicketId('');
        getSupportTickets();
      }
    } catch (error) {
      // Handling unexpected errors, e.g., to show a generic error message or log the error
      console.error('Unexpected error:', error);
    }
  };

  const handleKycDocView = (e, docUrl) => {
    e.preventDefault();

    // Open the document in a new tab while uploading on the backend server
    // window.open(apiUrl + "/" + docUrl, '_blank');

    window.open(docUrl, '_blank');
  };  
 
  // console.log("tickets: ", tickets);
  return (
    <AuthContext.Consumer>
      {(context) => (
      <>
      {
        alertDialogModalStatus ? <SuccessMessageModal /> : null
      }
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
        <Sidebar 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
          />
        <div className="z-theme-wrapper">
          <Header 
            toggleActive={toggleActive}
            setToggleActive={setToggleActive}
            name={name}
            darkMode={darkMode}
          />
          <div className='section-padding'>
            <div className="container-fluid">
              <div className="row align-items-center mb-50">
                <div className="col-lg-6 col-5">
                  <div className="page-bradcrumbs">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Help & Support</li>
                      </ol>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-6 col-7 text-end btn-support-holder">
                  <NavLink to="/tickets/create" className='btn btn-theme btn-lg'>Create New Ticket</NavLink>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-lg-3">
                  <div className="ds-main-card support-card">
                    <div className="help-card-body">
                      <img src={AllTicket} alt="All Tickets" />
                      <h3>{tickets.length}</h3>
                      <p>Total Tickets</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="ds-main-card support-card">
                    <div className="help-card-body">
                      <img src={AllTicket} alt="All Tickets" />
                      <h3>{tickets.filter(ticket => ticket.status === "Pending").length}</h3>
                      <p>Pending Tickets</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="ds-main-card support-card">
                    <div className="help-card-body">
                      <img src={ProgressTicket} alt="All Tickets" />
                      <h3>{tickets.filter(ticket => ticket.status === "In Progress").length}</h3>
                      <p>In Progress Tickets</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="ds-main-card support-card">
                    <div className="help-card-body">
                      <img src={ClosedTicket} alt="All Tickets" />
                      <h3>{tickets.filter(ticket => ticket.status === "Closed").length}</h3>
                      <p>Closed Tickets</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-50">
                <div className="col-lg-12">
                  <div className="table-responsive theme-table support-exchange-table" id="tabel-res-scroll-bar">
                  {
                   tickets.length > 0 ?
                    <table  className="table">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Sr.#	</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Subject</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Email</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Priority</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Status</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Created At	</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Updated At	</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>Message</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          <th scope="col">
                            <div className="table-head-th">
                              <span>File</span>
                              <button>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                  <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                </svg>
                              </button>
                            </div>
                          </th>
                          {
                            role === "admin" &&
                            <th scope="col">
                              <div className="table-head-th">
                                <span>Action</span>
                                <button>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                  </svg>
                                </button>
                              </div>
                            </th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                      {
                        tickets.map((ticket, index) => (
                        <tr className="tr-bg-dark" key={ticket._id}>
                        <td>{index + 1}</td>
                        <td>{ticket.subject}</td>
                        <td>{ticket.email}</td>
                        <td >
                          <div className="account-type-td">
                            <ul>
                              {
                                ticket.priority === "Low" ?
                                <li>
                                  <span className='account-typ-low'>{ticket.priority}</span>
                                </li>
                                :
                                ticket.priority === "Medium" ?
                                <li>
                                  <span className='account-typ-med'>{ticket.priority}</span>
                                </li>
                                :
                                ticket.priority === "High" ?
                                <li><span className='account-typ-high'>{ticket.priority}</span></li>
                                :
                                <li><span className='account-typ-high'>{ticket.priority}</span></li>
                              }
                            </ul>
                          </div>	
                        </td>
                        <td>
                          {
                            ticket.status === "Pending" ?
                            <span className='badge bg-secondary'>{ticket.status}</span>
                            :
                            ticket.status === "In Progress" ?
                            <span className='badge bg-warning text-dark'>{ticket.status}</span>
                            :
                            ticket.status === "Closed" ?
                            <span className='badge bg-success'>{ticket.status}</span>
                            :
                            <span className='badge bg-info'>{ticket.status}</span>
                          }
                        </td>
                        <td>{moment(ticket.createdAt).format("LLL")}</td>
                        <td>{moment(ticket.updatedAt).format("LLL")}</td>
                        <td> 
                          <div className='connect-wallet-td'>
                            <ul>
                              <li>
                                <button className='connect-btn' onClick={e => {
                                  e.preventDefault();

                                  setTicketMessage(ticket.message)
                                }}
                                data-bs-toggle="modal" 
                                data-bs-target="#MessageShow"
                                >
                                <svg width="16px" height="16px" className="svg-icon" viewBox="0 0 16 16" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M2 0.25C1.03379 0.25 0.25 1.03291 0.25 1.9997V4.5C0.25 4.91421 0.585786 5.25 1 5.25C1.41421 5.25 1.75 4.91421 1.75 4.5V1.9997C1.75 1.86192 1.86164 1.75 2 1.75H14C14.1381 1.75 14.25 1.86193 14.25 2V14C14.25 14.1381 14.1381 14.25 14 14.25H2C1.86164 14.25 1.75 14.1381 1.75 14.0003V11.5C1.75 11.0858 1.41421 10.75 1 10.75C0.585786 10.75 0.25 11.0858 0.25 11.5V14.0003C0.25 14.9671 1.03379 15.75 2 15.75H14C14.9665 15.75 15.75 14.9665 15.75 14V2C15.75 1.0335 14.9665 0.25 14 0.25H2ZM6.53033 4.46967C6.23744 4.17678 5.76256 4.17678 5.46967 4.46967C5.17678 4.76256 5.17678 5.23744 5.46967 5.53033L7.18934 7.25H1C0.585786 7.25 0.25 7.58579 0.25 8C0.25 8.41421 0.585786 8.75 1 8.75H7.18934L5.46967 10.4697C5.17678 10.7626 5.17678 11.2374 5.46967 11.5303C5.76256 11.8232 6.23744 11.8232 6.53033 11.5303L9.53033 8.53033L10.0607 8L9.53033 7.46967L6.53033 4.46967Z" fill="currentColor"></path></svg>
                                </button>
                              </li>
                            </ul>   
                          </div>
                        </td>
                        <td> 
                          <div className='connect-wallet-td'>
                            <ul>
                              <li>
                                <button className='connect-btn' onClick={e => handleKycDocView(e, ticket.docUrl)}>View</button>
                              </li>
                            </ul>   
                          </div>
                        </td>
                        {
                          role === "admin" &&
                          <td>
                            <div className="connect-wallet-td">
                              <ul>
                                <li>
                                <NavLink to={`/tickets/${ticket._id}`}>
                                  <button className='connect-direct'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                                      <path d="M12.9681 1.03174C12.0364 0.10006 10.5258 0.1001 9.59423 1.03184L1.62752 8.99947C1.35663 9.27041 1.16623 9.61114 1.0775 9.98381L0.347102 13.0515C0.306889 13.2204 0.357175 13.3981 0.479949 13.5208C0.602729 13.6436 0.780409 13.6939 0.949315 13.6537L4.01682 12.9233C4.38962 12.8345 4.73047 12.6441 5.00144 12.3731L12.9682 4.40541C13.8997 3.47375 13.8997 1.96334 12.9681 1.03174ZM10.3014 1.7389C10.8424 1.19773 11.7198 1.19771 12.261 1.73885C12.802 2.27994 12.8021 3.15722 12.261 3.69835L11.6668 4.29265L9.70723 2.33309L10.3014 1.7389ZM9.00016 3.04023L10.9598 4.99979L4.29429 11.666C4.15419 11.8061 3.97795 11.9046 3.7852 11.9505L1.5081 12.4927L2.0503 10.2155C2.09618 10.0227 2.19462 9.84661 2.33466 9.70654L9.00016 3.04023Z" fill="#415B73"/>
                                    </svg>
                                    </button>
                                </NavLink>
                                </li>
                                <li>
                                  <button className='connect-direct' onClick={e => handlePopupModal(e, ticket._id)} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 21" fill="none"><path d="M8 4H12C12 2.89543 11.1046 2 10 2C8.8954 2 8 2.89543 8 4ZM6.5 4C6.5 2.067 8.067 0.5 10 0.5C11.933 0.5 13.5 2.067 13.5 4H19.25C19.6642 4 20 4.33579 20 4.75C20 5.16421 19.6642 5.5 19.25 5.5H17.9309L16.7589 17.6112C16.5729 19.5334 14.9575 21 13.0263 21H6.97369C5.04254 21 3.42715 19.5334 3.24113 17.6112L2.06908 5.5H0.75C0.33579 5.5 0 5.16421 0 4.75C0 4.33579 0.33579 4 0.75 4H6.5ZM8.5 8.75C8.5 8.33579 8.1642 8 7.75 8C7.33579 8 7 8.33579 7 8.75V16.25C7 16.6642 7.33579 17 7.75 17C8.1642 17 8.5 16.6642 8.5 16.25V8.75ZM12.25 8C12.6642 8 13 8.33579 13 8.75V16.25C13 16.6642 12.6642 17 12.25 17C11.8358 17 11.5 16.6642 11.5 16.25V8.75C11.5 8.33579 11.8358 8 12.25 8ZM4.73416 17.4667C4.84577 18.62 5.815 19.5 6.97369 19.5H13.0263C14.185 19.5 15.1542 18.62 15.2658 17.4667L16.4239 5.5H3.57608L4.73416 17.4667Z" fill="#DC3545"></path></svg>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        }
                      </tr>
                      ))
                          }
                        </tbody>
                      </table>
                      :
                      <div className='text-center my-5'>
                        <p className='text-gray'>No data found.</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="DeleteModalLabel">Delete Ticket</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure? You can't undo this action afterwards.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" className="btn btn-danger"  data-bs-dismiss="modal" onClick={e => deleteTicket(e)}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}

            {/* Message Show Modal */}
            <div className="modal fade" id="MessageShow" tabIndex="-1" aria-labelledby="MessageShowLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="MessageShowLabel">Ticket Message</h1>
                  </div>
                  <div className="modal-body">
                    <p>{ticketMessage}</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setTicketMessage('')}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Message Show Modal */}
          </div>
        </div>
      </div>
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default Tickets