import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import AutoTradeBanner from "../../../assets/images/auto-trading/auto-trading.webp"
import KnowledgeShared from "../../../assets/images/about-us/shared.webp"
import AnyoneTrade from "../../../assets/images/about-us/anyone-trade.webp"
import Safety from "../../../assets/images/about-us/safety.webp"
import Loader from "../../../components/Frontend/loader/Loader";
import PortfolioTrack3 from "../../../assets/images/portfolio-tracking/portfolio-tracking-3.webp"
import SupportAuto from "../../../assets/images/auto-trading/247.svg"
import BannerMainImage from "../../../assets/images/banner-coins/banner-laptop.png"
import { Link } from "react-router-dom";


function AutomatedTrading() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />
      <Header />
      {/* Main Banner */}
      <section className="main-banner section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-caption-holder">
                <h2>Your universal crypto trading solution</h2>
                <p>Our platform enables experts to share signals with novices, and offers world - class bots and configurations to make crypto trading simple and profitable.</p>
                <div className="banner-btn">
                  <Link to="/login" className="btn btn-fr-theme btn-lg">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-main-img">
                <img src={AutoTradeBanner} alt="Banner-Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main Banner */}

      {/* Exchange Arbitrage */}
			<section className="total-vol-holder section-padding about-us-main-section">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="arb-exchnage-card about-us-main-card">
								<div className="arb-exc-left">
									<img src={AnyoneTrade} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Anyone can trade crypto</h4>
									<p>We want all people to have access to a user-friendly crypto trading platform.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card about-us-main-card">
								<div className="arb-exc-left">
									<img src={Safety} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Safety is our #1 priority</h4>
									<p>Every trader deserves to be confident that their hard-earned money is protected.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card about-us-main-card">
								<div className="arb-exc-left">
									<img src={KnowledgeShared} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Knowledge should be shared</h4>
									<p>At MarketMaking, we empower novices to copy expert traders in just a few clicks.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Exchange Arbitrage */}

			{/* Cross Exchange */}
			<section className="section-padding cross-exchange-holder">
				<div className="container">
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<img src={BannerMainImage} alt="Cross Exchange" />
						</div>
						<div className="col-lg-6">
							<div className="main-heading">
								<h2>Empowering people everywhere to trade crypto successfully</h2>
								<p>Being a successful crypto trader is not easy. Crypto strategies can get very complex, tracking signals and copying trades manually is exhausting.</p>
								<p>As traders ourselves, we learned the hard way that trading can quickly turn into a full-time job.</p>
							</div>
						</div>
					</div>
					{/* <div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Our vision</h2>
								<p>CryptoBot will continue to grow as the go-to crypto trading platform for novices and pros everywhere.</p>
								<ul className="main-heading-points">
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>Access historical performance data.</p>
									</li>
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>See trade progress and results in real-time.</p>
									</li>
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>Track portfolio performance across your exchanges.</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="portfolio-track-img text-center">
								<img src={PortfolioTrack3} alt="Portfolio Tracking" />
							</div>
						</div>
					</div> */}
				</div>
			</section>
      {/* Cross Exchange */}

      {/* How to start */}
			<section className="how-to-start-holder section-padding">
				<div className="container">
					<div className="row justify-content-center mb-60">
						<div className="col-lg-6">
							<div className="main-heading text-center">
								<h2>How to start automated cryptocurrency trading</h2>
							</div>
						</div>
					</div>
					<div className="row mb-60">
						<div className="col-lg-12">
							<ul className="start-how-list-holder">
								<li className="start__item">
									<span className="start__number">01</span>
									<div className="start__info">Login to the platform</div>
								</li>
								<li className="start__item">
									<span className="start__number">02</span>
									<div className="start__info">Find and select your exchange</div>
								</li>
								<li className="start__item">
									<span className="start__number">03</span>
									<div className="start__info">Supply your API keys and enter the amount you want to trade</div>
								</li>
								<li className="start__item">
									<span className="start__number">04</span>
									<div className="start__info">Make profit</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 text-center">
							<Link to="/login" className="btn btn-theme">Get Started</Link>
						</div>
					</div>
				</div>
			</section>
			{/* How to start */}

			 {/* Arbitrage Feature */}
			 <section className="arb-feature-section section-padding">
				<div className="container">
					<div className="row mb-50">
						<div className="col-lg-12">
							<div className="main-heading text-center">
								<h2>Other Features</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="AutomaticTradingIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M35.168 5.59v11.87a2.628 2.628 0 01-2.637 2.638h-8.574l-3.785 6.593a.198.198 0 01-.344 0l-3.785-6.593H7.469a2.628 2.628 0 01-2.637-2.637V5.59a2.628 2.628 0 012.637-2.637H32.53a2.628 2.628 0 012.637 2.637zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M27.016 10.363a.967.967 0 010 1.664c-1.45.883-4.047 2.137-7.016 2.137-2.969 0-5.566-1.254-7.016-2.137a.967.967 0 010-1.664c1.45-.883 4.047-2.136 7.016-2.136 2.969 0 5.566 1.253 7.016 2.136zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M20 14.164a2.97 2.97 0 10-.002-5.94 2.97 2.97 0 00.002 5.94zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M20 32.777a1.666 1.666 0 100-3.332 1.667 1.667 0 100 3.332zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M28.73 37.063a1.666 1.666 0 100-3.333 1.667 1.667 0 100 3.333zm0 0M11.27 28.492a1.667 1.667 0 10.001-3.334 1.667 1.667 0 00-.001 3.334zm0 0M1.668 33.887l1.11-1.11a4.71 4.71 0 016.667 0l1.11 1.11a4.704 4.704 0 006.668 0M23.332 28.332l.559-.555a4.706 4.706 0 016.664 0l1.113 1.11a4.699 4.699 0 006.664 0" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path></g></svg>
								<h4>Automatic Trading</h4>
								<p>Bots outperform humans</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="SocialTradingIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M3.965 28.008l7.773-7.778 4.375 4.375 7.774-7.777 4.375 4.375 7.773-7.773" stroke="#00b2c8" stroke-width="1.333332"></path><path d="M24.191 5.129L20.074 3.03a.129.129 0 00-.148 0L15.809 5.13 12.44 3.21a.12.12 0 00-.078-.027.124.124 0 00-.078.03c-.023.016-.035.044-.043.071a.146.146 0 00.012.082L13.71 7.75h12.578l1.457-4.383a.146.146 0 00.012-.082c-.008-.027-.02-.055-.043-.07a.124.124 0 00-.078-.031.12.12 0 00-.078.027zm0 0" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M36.035 13.875v16.523H23.887l-3.703 5.559a.237.237 0 01-.079.074.245.245 0 01-.21 0 .237.237 0 01-.079-.074l-3.703-5.52H6.891a2.919 2.919 0 01-2.918-2.914V11h29.156c.766 0 1.5.305 2.043.844.543.535.855 1.265.863 2.031zm0 0" stroke="currentColor" stroke-width="1.333332"></path></g></svg>
								<h4>Social Trading</h4>
								<p>Trade like a pro, without being one</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="TrailingOrdersIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M20 20.996v-8.512" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M22.535 20.996h-5.07a.951.951 0 00-.95.953V35.48c0 .528.426.954.95.954h5.07c.524 0 .95-.426.95-.954V21.95a.951.951 0 00-.95-.954zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M8.047 36.434V20" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M20 4.605V3.566" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M20 10.21a1.666 1.666 0 100-3.331 1.667 1.667 0 100 3.332zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M8.047 3.566v2.989" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M10.582 6.555H5.516a.951.951 0 00-.953.949V18.55c0 .523.425.949.953.949h5.066a.954.954 0 00.953-.95V7.505c0-.524-.43-.95-.953-.95zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M31.953 36.434V20M31.953 3.566v5.477" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M34.484 9.543h-5.066a.954.954 0 00-.953.95v8.058c0 .523.43.949.953.949h5.066a.951.951 0 00.954-.95v-8.058a.951.951 0 00-.954-.949zm0 0" stroke="currentColor" stroke-width="1.333332"></path></g></svg>
								<h4>Trailing Orders</h4>
								<p>Better buys & sells, the easy way</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="DollarCostAveragingIcon"><path d="M10.375 13.379H2.75a.954.954 0 00-.953.953v21.29c0 .526.426.952.953.952h7.625a.952.952 0 00.953-.953V14.332a.954.954 0 00-.953-.953zm0 0" fill="none" stroke="#00b2c8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M23.813 23.973h-7.625a.951.951 0 00-.954.949v10.7c0 .526.426.952.954.952h7.625a.952.952 0 00.953-.953v-10.7a.951.951 0 00-.953-.948zm0 0" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M37.238 31.563h-7.625a.952.952 0 00-.953.953v3.113c0 .527.426.953.953.953h7.625a.952.952 0 00.953-.953v-3.113a.952.952 0 00-.953-.953zm0 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M7.648 2.848L29.141 20.43l6.347-6.348" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M36.883 12.64l-1.938 6.419a6.408 6.408 0 00-1.66-2.606 6.352 6.352 0 00-2.695-1.512zm0 0" fill="currentColor"></path><path d="M27.492 10.68a1.669 1.669 0 000-3.336 1.667 1.667 0 100 3.336zm0 0" fill="none" stroke="#00b2c8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path></svg>
								<h4>DCA</h4>
								<p>Don’t worry buying at the wrong moment</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Arbitrage Feature */}


      <Footer />
    </div>
  );
}

export default AutomatedTrading;
