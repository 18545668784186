import React from 'react'
import { Link } from 'react-router-dom'
import FooterLogo from "../../../assets/images/logo/logo.png"

const Footer = () => {
  return (
    <>
      <footer className='main-footer'>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3">
              <div className="footer-logo-holder">
                <Link to="/">
                  <img src={FooterLogo} alt="FooterLogo" />
                </Link>
                <h4>Automated crypto trading for everyone</h4>
                <p>Cutting-edge trading automation for novices and pros. Thousands of strategies. User-friendly bots. 24/7 Support.</p>
                <ul className='footer-social-icon'>
                  <li>
                    <a href="#/">
                    <svg aria-hidden="true" className="sc-koXPp haPqdn" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="FacebookIcon"><path d="M30 20c0-5.523-4.477-10-10-10s-10 4.477-10 10c0 4.992 3.656 9.129 8.438 9.879V22.89h-2.54V20h2.54v-2.203c0-2.508 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.262c-1.242 0-1.628.77-1.628 1.563V20h2.773l-.441 2.89h-2.332v6.989C26.343 29.129 30 24.992 30 20zm0 0" ></path></svg>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <svg aria-hidden="true" className="sc-koXPp haPqdn" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="TwitterIcon"><path d="M16.293 28.125c7.543 0 11.672-6.254 11.672-11.672 0-.176-.004-.355-.012-.531A8.336 8.336 0 0030 13.797a8.083 8.083 0 01-2.355.644 4.147 4.147 0 001.804-2.27 8.316 8.316 0 01-2.605.997 4.08 4.08 0 00-2.328-1.238 4.092 4.092 0 00-4.403 2.355 4.1 4.1 0 00-.265 2.625 11.672 11.672 0 01-8.453-4.285 4.116 4.116 0 00-.45 2.988 4.134 4.134 0 001.72 2.489 4.182 4.182 0 01-1.86-.512v.05c0 .946.328 1.864.925 2.598a4.11 4.11 0 002.368 1.426 4.12 4.12 0 01-1.852.07 4.138 4.138 0 001.457 2.04c.688.507 1.52.792 2.375.812a8.257 8.257 0 01-5.098 1.758 9.113 9.113 0 01-.98-.063 11.67 11.67 0 006.293 1.844zm0 0" ></path></svg>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <svg aria-hidden="true" className="sc-koXPp haPqdn" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="TelegramIcon"><g ><path d="M16.793 27.727c-.668 0-.555-.254-.785-.891l-1.973-6.488 15.176-9"></path><path d="M16.797 27.727c.515 0 .746-.235 1.035-.516l2.758-2.684-3.442-2.074"></path><path d="M17.148 22.453l8.336 6.16c.953.528 1.641.254 1.88-.883l3.39-15.992c.348-1.394-.531-2.027-1.442-1.613l-19.93 7.684c-1.359.546-1.35 1.304-.245 1.644l5.113 1.598 11.84-7.473c.558-.34 1.074-.156.652.219"></path></g></svg>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <svg aria-hidden="true" className="sc-koXPp haPqdn" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="InstagramIcon"><path d="M15.418 10h9.164A5.418 5.418 0 0130 15.418v9.164A5.418 5.418 0 0124.582 30h-9.164A5.418 5.418 0 0110 24.582v-9.164A5.418 5.418 0 0115.418 10zm0 0" ></path><path d="M24.598 20a4.598 4.598 0 11-9.196 0 4.598 4.598 0 019.196 0zm0 0" fill="none" stroke="#fff" stroke-width="1.66666"></path><path d="M27.5 13.594c0 .691-.559 1.25-1.25 1.25S25 14.285 25 13.594a1.251 1.251 0 012.5 0zm0 0" fill="#fff"></path></svg>
                    </a>
                  </li>
                  <li>
                    <a href="#/">
                      <svg aria-hidden="true" className="sc-koXPp haPqdn" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="YoutubeIcon"><path d="M29.8 16s-.195-1.379-.796-1.984c-.762-.797-1.613-.801-2.004-.848-2.797-.203-6.996-.203-6.996-.203h-.008s-4.2 0-6.996.203c-.39.047-1.242.05-2.004.848-.601.605-.793 1.984-.793 1.984S10 17.621 10 19.238v1.516c0 1.617.2 3.238.2 3.238s.195 1.38.792 1.985c.762.796 1.762.77 2.207.855 1.602.152 6.801.2 6.801.2s4.203-.009 7-.208c.39-.047 1.242-.05 2.004-.847.601-.606.797-1.985.797-1.985S30 22.375 30 20.754v-1.516c0-1.617-.2-3.238-.2-3.238zm-11.866 6.594v-5.621l5.402 2.82zm0 0" ></path></svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4">
                  <div className="footer-menu-holder">
                    <h4>Features</h4>
                    <ul className='footer-list'>
                      <li>
                        <Link to="/arbitrage">Arbitrage</Link>
                      </li>
                      <li>
                        <Link to="/automated-trading">Automated Trading (APIs)</Link>
                      </li>
                      <li>
                        <Link to="/portfolio-tracking">Portfolio Tracking</Link>
                      </li>
                      <li>
                        <Link to="/market-making-trading">Market Making Trading</Link>
                      </li>
                      <li>
                        <Link to="/">Multi-Exchange Connectivity</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-menu-holder">
                    <h4>Features</h4>
                    <ul className='footer-list'>
                      <li>
                        <Link to="/terms">Terms</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      {/* <li>
                        <Link to="/">Careers</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="footer-menu-holder">
                    <h4>Exchanges</h4>
                    <ul className='footer-list'>
                      <li>
                        <a href="#/">Bybit</a>
                      </li>
                      <li>
                        <a href="#/">Binance</a>
                      </li>
                      <li>
                        <a href="#/">Binance Futures</a>
                      </li>
                      <li>
                        <a href="#/">Kucoin</a>
                      </li>
                      <li>
                        <a href="#/">Kucoin Futures</a>
                      </li>
                      <li>
                        <a href="#/">OKX</a>
                      </li>
                      <li>
                        <a href="#/">Huobi</a>
                      </li>
                      <li>
                        <a href="#/">Bitmex</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p className='disclimar-text'><strong>Trade Responsibly</strong> – All trading carries risk. To help you understand the risks involved we have put together a series of documents highlighting the risks and rewards related to trading. Read these documents available on our website.</p>
          </div>
        </div>
      </div>
      <section className='copyright-holder'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>© 2024 Copyright by MarketMaking CryptoBot - All rights reserved.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer