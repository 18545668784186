import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import CryptoBotIcon from "../../../assets/images/banner-coins/crypto-loader.gif"
const Loader = () => {
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 3000);
  
      return () => clearTimeout(timeout);
    }, []);

  return (
    <div className={`loader-container ${loading ? 'loading' : ''}`}>
      <div className="loader">
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#3498db"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <line x1="8" y1="12" x2="16" y2="12" />
      <line x1="12" y1="8" x2="12" y2="16" />
    </svg>
      </div>
    </div>
  );
};

export default Loader;
