import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import axios from 'axios'
import { AuthContext } from "../../../context/AuthContext";
import SuccessMessageModal from '../../../components/ClientArea/Modals/SuccessModal';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Users = ({
  toggleActive,
  setToggleActive,
  name,
  darkMode,

}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const context = useContext(AuthContext);
  const {
    alertDialogModalStatus,
    setAlertDialogModalStatus,
    setAlertDialogModalMessage
  } = context;
  const [auth] = useState(localStorage.getItem('auth'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [role] = useState(localStorage.getItem('role'));
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [userStatus, setUserStatus] = useState('');

  const getUsers = async () => {
    axios.get(`${apiUrl}/users/all?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((response) => {
        if(response.data) {
          setUsers(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }

  useEffect(() => {
    getUsers();
  }, [])

  const handlePopupModal = (e, id, newStatus) => {
    e.preventDefault();

    // setting the id, title, new status for modals to use it
    setSelectedUserId(id);
    setUserStatus(newStatus)
  }

  const handleStatusChange = async (e) => {
    e.preventDefault();

    if(selectedUserId && userStatus) {
      try {
        // Make an API request to change the user status
        const response = await axios.put(
          `${apiUrl}/users/${selectedUserId}/update/status`,
          {
            status: userStatus
          },
          {
            headers: {
              Authorization: `Bearer ${auth}`
            }
          }
        );
  
        if (response.data.success) {
          setAlertDialogModalStatus(true);
          setAlertDialogModalMessage(response.data.message);
          resetUserStatusAndId();
          getUsers();
        } else {
          // Handle failure
          console.error('Failed to update user status');
        }
      } catch (error) {
        console.error('Error changing user status:', error);
      }
    }
  };

  const permaDelUser = async (e) => {
    e.preventDefault();
    
    try {
      if(selectedUserId) {
        // Make a DELETE request to the server
        const response = await axios.delete(`${apiUrl}/users/${selectedUserId}/del?role=${role}`, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
    
        if (response.data.success) {
          setAlertDialogModalStatus(true);
          setAlertDialogModalMessage('User removed permanently from the system.');
          resetUserStatusAndId();
          getUsers();
        }
      } else {
        setAlertDialogModalStatus(true);
        setAlertDialogModalMessage('User selection not found');
      }
    } catch (error) {
      // Handling unexpected errors, e.g., to show a generic error message or log the error
      console.error('Unexpected error:', error);
    }
  };

  const resetUserStatusAndId = () => {
    setSelectedUserId('');
    setUserStatus('');
  }

  // console.log({users});
  return (
    <AuthContext.Consumer>
    {(context) => (
      <>
      {
        alertDialogModalStatus ? 
        <SuccessMessageModal />
        : null
      }
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
      <Sidebar 
        toggleActive={toggleActive}
        setToggleActive={setToggleActive}
      />
      <div className="z-theme-wrapper">
        <Header 
          toggleActive={toggleActive}
          setToggleActive={setToggleActive}
          name={name}
          darkMode={darkMode}
        />
          <div className='section-padding'>
            <div className="container-fluid">
              <div className="row mb-30">
                <div className="col-lg-12">
                  <div className="page-bradcrumbs">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">All Users</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-inner-title">
                    <h2>All Users</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="subscription-main-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-registered-tab" data-bs-toggle="pill" data-bs-target="#pills-registered" type="button" role="tab" aria-controls="pills-registered" aria-selected="true">
                        Registered
                        <span className="count-badge">{users.filter(user => user.status === "Registered").length}</span>  
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-active-tab" data-bs-toggle="pill" data-bs-target="#pills-active" type="button" role="tab" aria-controls="pills-active" aria-selected="true">
                        Active
                        <span className="count-badge">{users.filter(user => user.status === "Active").length}</span>  
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-inactive-tab" data-bs-toggle="pill" data-bs-target="#pills-inactive" type="button" role="tab" aria-controls="pills-inactive" aria-selected="false">
                        Inactive
                        <span className="count-badge">{users.filter(user => user.status === "Inactive").length}</span>  
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-block-tab" data-bs-toggle="pill" data-bs-target="#pills-block" type="button" role="tab" aria-controls="pills-block" aria-selected="false">
                        Block
                        <span className="count-badge">{users.filter(user => user.status === "Block").length}</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-registered" role="tabpanel" aria-labelledby="pills-registered-tab" tabIndex="0">
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Registered Users</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              users
                              .filter(user => user.status === "Registered").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Username</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Email</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Role</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Reference </span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users
                                    .filter(user => user.status === "Registered")
                                    .map((user, index) => (
                                    <tr key={index} className="tr-bg-dark">
                                      <td>{index + 1}</td>
                                      <td>{user.username}</td>
                                      <td>{user.email}</td>
                                      <td>
                                        <div className="account-type-td">
                                          <span className={`badge badge-${user.status === 'Active' ? 'success' : user.status === 'Inactive' ? 'secondary' : user.status === 'Block' ? 'danger' : user.status === 'Registered' ? 'info' : 'warning'}`}>
                                            {user.status}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{user.role}</td>
                                      <td>{user.referenceId}</td>
                                      <td>{moment(user.createdAt).format("LLL")}</td>
                                      <td>{moment(user.updatedAt).format("LLL")}</td>
                                      <td>
                                        <div className="connect-wallet-td">
                                          <ul>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Active", user.status, 'Active')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Inactive", user.status,  'Inactive')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Block", user.status,  'Block')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-active" role="tabpanel" aria-labelledby="pills-active-tab" tabIndex="0">
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Active Users</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              users
                              .filter(user => user.status === "Active").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Username</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Email</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Role</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Reference </span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users
                                    .filter(user => user.status === "Active")
                                    .map((user, index) => (
                                    <tr key={index} className="tr-bg-dark">
                                      <td>{index + 1}</td>
                                      <td>{user.username}</td>
                                      <td>{user.email}</td>
                                      <td>
                                        <div className="account-type-td">
                                          <span className={`badge badge-${user.status === 'Active' ? 'success' : user.status === 'Inactive' ? 'secondary' : user.status === 'Block' ? 'danger' : user.status === 'Registered' ? 'info' : 'warning'}`}>
                                            {user.status}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{user.role}</td>
                                      <td>{user.referenceId}</td>
                                      <td>{moment(user.createdAt).format("LLL")}</td>
                                      <td>{moment(user.updatedAt).format("LLL")}</td>
                                      <td>
                                        <div className="connect-wallet-td">
                                          <ul>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Inactive", user.status,  'Inactive')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Block", user.status,  'Block')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-inactive" role="tabpanel" aria-labelledby="pills-inactive-tab" tabIndex="0">
                     <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Inactive Users</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              users
                              .filter(user => user.status=== "Inactive").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Username</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Email</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Role</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Reference </span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {users
                                  .filter(user => user.status=== "Inactive")
                                  .map((user, index) => (
                                  <tr key={index} className="tr-bg-dark">
                                    <td>{index + 1}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                      <div className="account-type-td">
                                        <span className={`badge badge-${user.status === 'Active' ? 'success' : user.status === 'Inactive' ? 'secondary' : user.status === 'Block' ? 'danger' : user.status === 'Registered' ? 'info' : 'warning'}`}>
                                          {user.status}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{user.role}</td>
                                    <td>{user.referenceId}</td>
                                    <td>{moment(user.createdAt).format("LLL")}</td>
                                    <td>{moment(user.updatedAt).format("LLL")}</td>
                                    <td>
                                      <div className="connect-wallet-td">
                                        <ul>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Active", user.status, 'Active')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                            </button>
                                          </li>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Block", user.status,  'Block')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-block" role="tabpanel" aria-labelledby="pills-block-tab" tabIndex="0">
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Blocked Users</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              users
                              .filter(user => user.status === "Block").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Username</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Email</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Role</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Reference </span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {users
                                  .filter(user => user.status === "Block")
                                  .map((user, index) => (
                                  <tr key={index} className="tr-bg-dark">
                                    <td>{index + 1}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                      <div className="account-type-td">
                                        <span className={`badge badge-${user.status === 'Active' ? 'success' : user.status === 'Inactive' ? 'secondary' : user.status === 'Block' ? 'danger' : user.status === 'Registered' ? 'info' : 'warning'}`}>
                                          {user.status}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{user.role}</td>
                                    <td>{user.referenceId}</td>
                                    <td>{moment(user.createdAt).format("LLL")}</td>
                                    <td>{moment(user.updatedAt).format("LLL")}</td>
                                    <td>
                                      <div className="connect-wallet-td">
                                        <ul>
                                        <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Active", user.status, 'Active')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                            </button>
                                          </li>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Inactive", user.status,  'Inactive')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                            </button>
                                          </li>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, user.userId, "Block", user.status,  'Block')} data-bs-toggle="modal" data-bs-target="#PermaDeleteModal">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="DeleteModalLabel">Block User Temporarily</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetUserStatusAndId()}>Cancel</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>Block</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            {/* Delete Perma Modal */}
            <div className="modal fade" id="PermaDeleteModal" tabIndex="-1" aria-labelledby="PermaDeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="PermaDeleteModalLabel">Remove User Permanently</h1>
                  </div>
                  <div className="modal-body">
                    <p>This user is already blocked. Do you want to remove it permanently from the system?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetUserStatusAndId()}>Cancel</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={e => permaDelUser(e)}>Remove</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Perma Modal */}
            {/* Reject Modal */}
            <div className="modal fade" id="RejectKyc" tabIndex="-1" aria-labelledby="RejectKycLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="RejectKycLabel">Update User Status to Inactive</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetUserStatusAndId()}>Cancel</button>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Reject Modal */}
          </div>
        </div>
      </div>
      {/* Approved Modal */}
      <div className="modal fade" id="ApprovedKyc" tabIndex="-1" aria-labelledby="ApprovedKycLabel" aria-hidden="true">
        <div className="modal-dialog delete-modal-holder">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="ApprovedKycLabel">Update User Status to Active</h1>
            </div>
            <div className="modal-body">
              <p>Are you sure?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetUserStatusAndId()}>
                Cancel
              </button>
              <button type="button" className="btn btn-theme" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal */}
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default Users