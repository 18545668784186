import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import ProfileImage from "../../../assets/images/profile/profile-image.png"
import axios from 'axios'
import { AuthContext } from "../../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faKey, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Settings = ({
  toggleActive,
  setToggleActive,
  name,
  darkMode,

}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userId] = useState(localStorage.getItem("userId"));
  const [auth] = useState(localStorage.getItem("auth"));
  const [fullname, setFullname] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameErr, setUsernameErr] = useState('');
  const [currentPasswordErr, setCurrentPasswordErr] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('');

  useEffect(() => {
    getUserInfo();
  }, [])

  const getUserInfo = async () => {
    // console.log("getUserInfo...");
    axios
      .get(`${apiUrl}/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      })
      .then((res) => {
        // console.log(res.data);

        if (res.data) {
          if(Object.keys(res.data.data).length > 0) {
            setFullname(res.data.data.fullname);
            setUsername(res.data.data.username);
            setUserEmail(res.data.data.email);
            setPhone(res.data.data.phone);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const updateHandler = async (e) => {
    e.preventDefault();

    setUsernameErr('');

    if(!username) {
      setUsernameErr("Username field is required")
    } else {
      const updateUserObj = {
        // email: userEmail,
        fullname: fullname,
        username: username,
        phone: phone,
      };
  
      try {
        const response = await axios.put(`${apiUrl}/users/${userId}/update`, updateUserObj, {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        });
  
        if(response.data) {
          if(Object.keys(response.data.data).length > 0) {
            setFullname(response.data.data.fullname);
            setUsername(response.data.data.username);
            setPhone(response.data.data.phone);
            // setUserEmail(response.data.data.email);
          }
  
          alert("Info updated successfully");
        }
      } catch (error) {
        console.error("Update failed:", error);
      }
    }
  };

  const changePasswordHandler = async (e) => {
    e.preventDefault();

    resetChangePasswordErrs();
  
    if(!currentPassword) {
      setCurrentPasswordErr("Current password field is required");
      return;
    } else if (!newPassword) {
      setNewPasswordErr("New password field is required");
      return;
    } else if (!confirmPassword) {
      setConfirmPasswordErr("Confirm password field is required");
      return;
    } else if (newPassword !== confirmPassword) {
      setNewPasswordErr("New password and confirm password do not match.");
      return;
    } else {
      try {
        const changePasswordObj = {
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        };
    
        const response = await axios.put(
          `${apiUrl}/users/${userId}/change-password`,
          changePasswordObj,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
            },
          }
        );
    
        if (response.data) {
          alert("Password changed successfully");
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        }
      } catch (error) {
        console.error("Password change failed:", error);
        // log and set the wrong current password
        if(error.response) {
          if(error.response.data) {
            setNewPasswordErr(error.response.data.message); 
          }
        }
      }
    }
  };

  const resetChangePasswordErrs = () => {
    setCurrentPasswordErr("");
    setNewPasswordErr("");
    setConfirmPasswordErr("");
  }
  
  return (
    <AuthContext.Consumer>
    {(context) => (
      <>
    <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
      <Sidebar 
        toggleActive={toggleActive}
        setToggleActive={setToggleActive}
      />
      <div className="z-theme-wrapper">
        <Header 
          toggleActive={toggleActive}
          setToggleActive={setToggleActive}
          name={name}
          darkMode={darkMode}
        />
          <div className='section-padding'>
            <div className="container-fluid">
              <div className="row mb-30">
                <div className="col-lg-12">
                  <div className="page-bradcrumbs">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"> Settings</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="row mb-60">
                <div className="col-lg-3">
                  <div className="setting-tabs-left">
                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <button className="nav-link active" id="v-pills-Profile-tabs-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Profile-tabs" type="button" role="tab" aria-controls="v-pills-Profile-tabs" aria-selected="true">
                        <div className='tab-pill-detail'>
                          <div className='tab-pill-left-detail'>
                            <span>
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            <h4>Profile</h4>
                          </div>
                          <div className="tab-pill-right-detail">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </button>
                      <button className="nav-link" id="v-pills-Privacy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Privacy" type="button" role="tab"        aria-controls="v-pills-Privacy" aria-selected="false">
                        <div className='tab-pill-detail'>
                          <div className='tab-pill-left-detail'>
                            <span>
                              <FontAwesomeIcon icon={faKey} />
                            </span>
                            <h4>Change Password</h4>
                          </div>
                          <div className="tab-pill-right-detail">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </button>
                      {/* <button className="nav-link" id="v-pills-Security-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Security" type="button" role="tab" aria-controls="v-pills-Security" aria-selected="false">
                        <div className='tab-pill-detail'>
                          <div className='tab-pill-left-detail'>
                            <span>
                              <FontAwesomeIcon icon={faLock} />
                            </span>
                            <h4>KYC Verification</h4>
                          </div>
                          <div className="tab-pill-right-detail">
                            <FontAwesomeIcon icon={faArrowRight} />
                          </div>
                        </div>
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9"> 
                  <div className="setting-tabs-right">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div className="tab-pane fade show active" id="v-pills-Profile-tabs" role="tabpanel" aria-labelledby="v-pills-Profile-tabs-tab" tabIndex="0">
                        <div className='setting-right-main-card'>
                          <div className="setting-right-main-card-header">
                            <h3>Profile</h3>
                            <p>Your profile settings will be available for people to see.</p>
                          </div>
                          <div className="setting-right-main-card-body">
                            <form onSubmit={e => updateHandler(e)}>
                              <img src={ProfileImage} alt="Profile" />
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Name</label>
                                    <input type="text" value={fullname} onChange={e => setFullname(e.target.value)} className='form-control' placeholder='Enter name' />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Username</label>
                                    <input type="text" value={username} onChange={e => setUsername(e.target.value)} onClick={e => {
                                      e.preventDefault();

                                      setUsernameErr('');
                                    }} className='form-control' placeholder='Enter username' />
                                  </div>
                                  {usernameErr ? (
                                    <div>
                                      <p className="text-danger">{usernameErr}</p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Phone</label>
                                    <input type="text" value={phone} onChange={e => setPhone(e.target.value)} className='form-control' placeholder='Enter phone number' />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Email</label>
                                    <input type="text" value={userEmail} onChange={e => setUserEmail(e.target.value)} className='form-control' placeholder='Enter email' disabled />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <button className='btn btn-theme btn-lg'>Save Info</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="v-pills-Privacy" role="tabpanel" aria-labelledby="v-pills-Privacy-tab" tabIndex="0">
                        <div className='setting-right-main-card'>
                          <div className="setting-right-main-card-header">
                            <h3>Change Password</h3>
                            {/* <p>Your profile settings will be available for people to see.</p> */}
                          </div>
                          <div className="setting-right-main-card-body">
                            <form onSubmit={e => changePasswordHandler(e)}>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Current Password</label>
                                    <input type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} onClick={e => {
                                      e.preventDefault();

                                      setCurrentPasswordErr('');
                                    }} className='form-control' placeholder='Enter current password' />
                                  </div>
                                  {currentPasswordErr ? (
                                    <div>
                                      <p className="text-danger">{currentPasswordErr}</p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">New Password</label>
                                    <input type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} onClick={e => {
                                      e.preventDefault();

                                      setNewPasswordErr('');
                                    }} className='form-control' placeholder='Enter new password' />
                                  </div>
                                  {newPasswordErr ? (
                                    <div>
                                      <p className="text-danger">{newPasswordErr}</p>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-lg-6">
                                  <div className="input-holder mb-20">
                                    <label htmlFor="">Confirm Password</label>
                                    <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} onClick={e => {
                                      e.preventDefault();

                                      setConfirmPasswordErr('');
                                    }} className='form-control' placeholder='Enter confirm password' />
                                  </div>
                                  {confirmPasswordErr ? (
                                    <div>
                                      <p className="text-danger">{confirmPasswordErr}</p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <button className='btn btn-theme btn-lg' type='submit'>Change Password</button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <div className="tab-pane fade" id="v-pills-Security" role="tabpanel" aria-labelledby="v-pills-Security-tab" tabIndex="0">
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default Settings