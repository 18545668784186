import React, { useState, createContext } from "react";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem('mode')));
  const [alertDialogModalStatus, setAlertDialogModalStatus] = useState(false);
  const [alertDialogModalMessage, setAlertDialogModalMessage] = useState('');

  return (
    <Provider
      value={{
        darkMode,
        setDarkMode,
        alertDialogModalStatus,
        setAlertDialogModalStatus,
        alertDialogModalMessage,
        setAlertDialogModalMessage
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };