import Ace from '../assets/images/exchanges/ace.png';
import Alpaca from '../assets/images/exchanges/alpaca.png';
import Ascendex from '../assets/images/exchanges/ascendex.png';
import Bequant from '../assets/images/exchanges/bequant.png';
import Bigone from '../assets/images/exchanges/bigone.png';
import Binance from '../assets/images/exchanges/binance.png';
import Binancecoinm from '../assets/images/exchanges/binancecoinm.png';
import Binanceus from '../assets/images/exchanges/binanceus.png';
import Binanceusdm from '../assets/images/exchanges/binanceusdm.png';
import Bingx from '../assets/images/exchanges/bingx.png';
import Bit2c from '../assets/images/exchanges/bit2c.png';
import Bitbank from '../assets/images/exchanges/bitbank.png';
import Bitbns from '../assets/images/exchanges/bitbns.png';
import Bitfinex from '../assets/images/exchanges/bitfinex.png';
import Bitfinex2 from '../assets/images/exchanges/bitfinex2.png';
import Bitflyer from '../assets/images/exchanges/bitflyer.png';
import Bitforex from '../assets/images/exchanges/bitforex.png';
import Bitget from '../assets/images/exchanges/bitget.png';
import Bithumb from '../assets/images/exchanges/bithumb.png';
import Bitcoincom from '../assets/images/exchanges/bitcoincom.png';
import Bitmart from '../assets/images/exchanges/bitmart.png';
import Bitmex from '../assets/images/exchanges/bitmex.png';
import Bitbay from '../assets/images/exchanges/bitbay.png';
import Bybit from '../assets/images/exchanges/bybit.png';
import Bitpanda from '../assets/images/exchanges/bitpanda.png';
import Bkex from '../assets/images/exchanges/bkex.png';
import Bitopro from '../assets/images/exchanges/bitopro.png';
import Bitrue from '../assets/images/exchanges/bitrue.png';
import Bitso from '../assets/images/exchanges/bitso.png';
import Bitstamp from '../assets/images/exchanges/bitstamp.png';
import Bitstamp1 from '../assets/images/exchanges/bitstamp1.png';
import Bittrex from '../assets/images/exchanges/bittrex.png';
import Bitvavo from '../assets/images/exchanges/bitvavo.png';
import Bl3p from '../assets/images/exchanges/bl3p.png';
import Blockchaincom from '../assets/images/exchanges/blockchaincom.png';
import Btcalpha from '../assets/images/exchanges/btcalpha.png';
import Btcbox from '../assets/images/exchanges/btcbox.png';
import Btcmarkets from '../assets/images/exchanges/btcmarkets.png';
import Btctradeua from '../assets/images/exchanges/btctradeua.png';
import Btcturk from '../assets/images/exchanges/btcturk.png';
import Cex from '../assets/images/exchanges/cex.png';
import Coinbase from '../assets/images/exchanges/coinbase.png';
import Coinbaseprime from '../assets/images/exchanges/coinbaseprime.png';
import Coinbasepro from '../assets/images/exchanges/coinbasepro.png';
import Coincheck from '../assets/images/exchanges/coincheck.png';
import Coinex from '../assets/images/exchanges/coinex.png';
import Coinfalcon from '../assets/images/exchanges/coinfalcon.png';
import Coinmate from '../assets/images/exchanges/coinmate.png';
import Coinone from '../assets/images/exchanges/coinone.png';
import Coinsph from '../assets/images/exchanges/coinsph.png';
import Coinspot from '../assets/images/exchanges/coinspot.png';
import Cryptocom from '../assets/images/exchanges/cryptocom.png';
import Currencycom from '../assets/images/exchanges/currencycom.png';
import Delta from '../assets/images/exchanges/delta.png';
import Deribit from '../assets/images/exchanges/deribit.png';
import Digifinex from '../assets/images/exchanges/digifinex.png';
import Exmo from '../assets/images/exchanges/exmo.png';
import Fmfwio from '../assets/images/exchanges/fmfwio.png';
import Gate from '../assets/images/exchanges/gate.png';
import Gateio from '../assets/images/exchanges/gateio.png';
import Gemini from '../assets/images/exchanges/gemini.png';
import Hitbtc from '../assets/images/exchanges/hitbtc.png';
import Hitbtc3 from '../assets/images/exchanges/hitbtc3.png';
import Hollaex from '../assets/images/exchanges/hollaex.png';
import Huobi from '../assets/images/exchanges/huobi.png';
import Huobipro from '../assets/images/exchanges/huobipro.png';
import Huobijp from '../assets/images/exchanges/huobijp.png';
import Idex from '../assets/images/exchanges/idex.png';
import Independentreserve from '../assets/images/exchanges/independentreserve.png';
import Indodax from '../assets/images/exchanges/indodax.png';
import kraken from '../assets/images/exchanges/kraken.png';
import krakenfutures from '../assets/images/exchanges/krakenfutures.png';
import kucoin from '../assets/images/exchanges/kucoin.png';
import kucoinfutures from '../assets/images/exchanges/kucoinfutures.png';
import kuna from '../assets/images/exchanges/kuna.png';
import Latoken from '../assets/images/exchanges/latoken.png';
import Lbank from '../assets/images/exchanges/lbank.png';
import Lbank2 from '../assets/images/exchanges/lbank2.png';
import Luno from '../assets/images/exchanges/luno.png';
import Lykke from '../assets/images/exchanges/lykke.png';
import Mercado from '../assets/images/exchanges/mercado.png';
import Mexc from '../assets/images/exchanges/mexc.png';
import Mexc3 from '../assets/images/exchanges/mexc3.png';
import Ndax from '../assets/images/exchanges/ndax.png';
import Novadax from '../assets/images/exchanges/novadax.png';
import Oceanex from '../assets/images/exchanges/oceanex.png';
import Okcoin from '../assets/images/exchanges/okcoin.png';
import Okex from '../assets/images/exchanges/okex.png';
import Okex5 from '../assets/images/exchanges/okex5.png';
import Okx from '../assets/images/exchanges/okx.png';
import Paymium from '../assets/images/exchanges/paymium.png';
import Phemex from '../assets/images/exchanges/phemex.png';
import Poloniex from '../assets/images/exchanges/poloniex.png';
import Poloniexfutures from '../assets/images/exchanges/poloniexfutures.png';
import Probit from '../assets/images/exchanges/probit.png';
import Tidex from '../assets/images/exchanges/tidex.png';
import Timex from '../assets/images/exchanges/timex.png';
import Tokocrypto from '../assets/images/exchanges/tokocrypto.png';
import Upbit from '../assets/images/exchanges/upbit.png';
import Wavesexchange from '../assets/images/exchanges/wavesexchange.png';
import Wazirx from '../assets/images/exchanges/wazirx.png';
import Whitebit from '../assets/images/exchanges/whitebit.png';
import Woo from '../assets/images/exchanges/woo.png';
import Yobit from '../assets/images/exchanges/yobit.png';
import Zaif from '../assets/images/exchanges/zaif.png';
import Zonda from '../assets/images/exchanges/zonda.png';



export const Logos = {
  'ace': Ace,
  'alpaca': Alpaca,
  'ascendex': Ascendex,
  'bequant': Bequant,
  'bigone': Bigone,
  'binance': Binance,
  'binancecoinm': Binancecoinm,
  'binanceus': Binanceus,
  'binanceusdm': Binanceusdm,
  'bingx': Bingx,
  'bit2c': Bit2c,
  'bitbank': Bitbank,
  'bitbns': Bitbns,
  'bitfinex': Bitfinex,
  'bitfinex2': Bitfinex2,
  'bitflyer': Bitflyer,
  'bitforex': Bitforex,
  'bitget': Bitget,
  'bithumb': Bithumb,
  'bitmart': Bitmart,
  'bitmex': Bitmex,
  'bitbay': Bitbay,
  'bitpanda': Bitpanda,
  'bitopro': Bitopro,
  'bitrue': Bitrue,
  'bitso': Bitso,
  'bitstamp': Bitstamp,
  'bitstamp1': Bitstamp1,
  'bittrex': Bittrex,
  'bitvavo': Bitvavo,
  'bkex': Bkex,
  'bl3p': Bl3p,
  'blockchaincom': Blockchaincom,
  'btcalpha': Btcalpha,
  'btcbox': Btcbox,
  'btcmarkets': Btcmarkets,
  'btctradeua': Btctradeua,
  'btcturk': Btcturk,
  'bybit': Bybit,
  'bitcoincom': Bitcoincom,
  'cex': Cex,
  'coinbase': Coinbase,
  'coinbaseprime': Coinbaseprime,
  'coinbasepro': Coinbasepro,
  'coincheck': Coincheck,
  'coinex': Coinex,
  'coinfalcon': Coinfalcon,
  'coinmate': Coinmate,
  'coinone': Coinone,
  'coinsph': Coinsph,
  'coinspot': Coinspot,
  'cryptocom': Cryptocom,
  'currencycom': Currencycom,
  'delta': Delta,
  'deribit': Deribit,
  'digifinex': Digifinex,
  'exmo': Exmo,
  'fmfwio': Fmfwio,
  'gate': Gate,
  'gateio': Gateio,
  'gemini': Gemini,
  'hitbtc': Hitbtc,
  'hitbtc3': Hitbtc3,
  'hollaex': Hollaex,
  'huobi': Huobi,
  'huobipro': Huobipro,
  'huobijp': Huobijp,
  'idex': Idex,
  'independentreserve': Independentreserve,
  'indodax': Indodax,
  'kraken': kraken,
  'krakenfutures': krakenfutures,
  'kucoin': kucoin,
  'kucoinfutures': kucoinfutures,
  'kuna': kuna,
  'latoken': Latoken,
  'lbank': Lbank,
  'lbank2': Lbank2,
  'luno': Luno,
  'lykke': Lykke,
  'mercado': Mercado,
  'mexc': Mexc,
  'mexc3': Mexc3,
  'ndax': Ndax,
  'novadax': Novadax,
  'oceanex': Oceanex,
  'okcoin': Okcoin,
  'okex': Okex,
  'okex5': Okex5,
  'okx': Okx,
  'paymium': Paymium,
  'phemex': Phemex,
  'poloniex': Poloniex,
  'poloniexfutures': Poloniexfutures,
  'probit': Probit,
  'tidex': Tidex,
  'timex': Timex,
  'tokocrypto': Tokocrypto,
  'upbit': Upbit,
  'wavesexchange': Wavesexchange,
  'wazirx': Wazirx,
  'whitebit': Whitebit,
  'woo': Woo,
  'yobit': Yobit,
  'zaif': Zaif,
  'zonda': Zonda,
  }