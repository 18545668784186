import React, { useState } from 'react';
import MMLogo from "../../../assets/images/logo/logo.png"
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faAngleDown, faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
  const [auth] = useState(localStorage.getItem('auth'));
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [active, setActive] = useState(true);
  const toggleSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.replace('/');
  };

  const toggleClass = () => {
    setActive(!active);
  };

  return (
		<>
		{/* <header className='header main-ds-header'>
			<div className="container-fluid">
				<div className="row align-items-center">
				<div className="col-lg-3">
							<div className='logo'>
									<a href="/">CryptoBot</a>
							</div>
					</div>
					<div className="col-lg-6">
							<div className='main-menu-holder'>
									<ul className='main-menu'>
											{
												auth ?
												<li className='main-menu-list'>
													<Link to={'/dashboard'} className='main-menu-list-item'>
														Main
													</Link>
												</li>
												:
												null
											}
											<li className='main-menu-list'><a href="#/" className='main-menu-list-item'>About</a></li>
											<li className={`main-menu-list ${submenuVisible ? 'active' : ''}`}>
													<a href="#/" className='main-menu-list-item' onClick={toggleSubmenu}>
															Services <FontAwesomeIcon icon={faAngleDown} />
													</a>
													{submenuVisible && (
															<ul className='submenu'>
																	<li className='submenu-list'>
																		<a href="#/">Arbitrage</a>
																	</li>
																	<li className='submenu-list'>
																		<a href="#/">Automated Trading (APIs)</a>
																	</li>
																	<li className='submenu-list'>
																		<a href="#/">Portfolio Management</a>
																	</li>
																	<li className='submenu-list'>
																		<a href="#/">Individual Trading</a>
																	</li>
																	<li className='submenu-list'>
																		<a href="#/">Multi-Exchange Connectivity</a>
																	</li>
															</ul>
													)}
											</li>
											<li className='main-menu-list'><a href="#/" className='main-menu-list-item'>News & Feed</a></li>
											<li className='main-menu-list'><a href="#/" className='main-menu-list-item'>Support</a></li>
									</ul>
							</div>
					</div>
					<div className="col-lg-3 d-flex justify-content-between text-end">
						{
							!auth ?
							<>
								<Link to={'/login'}>
									<button className='btn  btn-fr-theme'>Login</button>
								</Link>
								<Link to={'/register'}>
									<button className='btn  btn-fr-theme'>Register</button>
								</Link>
							</>
							:
							<button className='btn  btn-fr-theme' onClick={e => handleLogout(e)}>Logout</button>
						}
					</div>
				</div>
			</div>
		</header> */}
		<header className='header main-ds-header'>
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-9">
						<div className="main-header-left">
							<div className="logo">
								<Link to="/"><img src={MMLogo} alt="Main Logo" /></Link>
							</div>
							<div className="main-menu-holder">
								<ul className='main-menu'>
									{
										auth ?
										<li className='main-menu-list'>
											<Link to={'/dashboard'} className='main-menu-list-item'>
												Main
											</Link>
										</li>
										:
										<li className='main-menu-list'>
											<Link to="/" className='main-menu-list-item'>
												Home
											</Link>
										</li>
									}
									<li className={`main-menu-list ${submenuVisible ? 'active' : ''}`}>
											<a href="#/" className='main-menu-list-item' onClick={toggleSubmenu}>
													Features <FontAwesomeIcon icon={faAngleDown} />
											</a>
											{submenuVisible && (
													<ul className='submenu'>
															<li className='submenu-list'>
																<Link to="/arbitrage">Arbitrage</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/automated-trading">Automated Trading (APIs)</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/portfolio-tracking">Portfolio Tracking</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/market-making-trading">Market Making Trading</Link>
															</li>
															<li className='submenu-list'>
																<Link to="#/">Multi-Exchange Connectivity</Link>
															</li>
													</ul>
											)}
									</li>
									<li className='main-menu-list'>
										<Link to="/about-us" className='main-menu-list-item'>About Us</Link>
									</li>
									<li className='main-menu-list'>
										<a href="/#Exchange" className='main-menu-list-item'>Exchanges</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Pricing" className='main-menu-list-item'>Pricing</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Affiliate" className='main-menu-list-item'>Affliliate</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Security" className='main-menu-list-item'>Security</a>
									</li>
									
								</ul>
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<ul className='header-btn'>
						{
							!auth ?
							<>
								<li>
									<Link to={'/login'}>
										<button className='btn  btn-outline-theme'>Login</button>
									</Link>
								</li>
								<li>
								<Link to={'/register'}>
									<button className='btn  btn-fr-theme'>Register</button>
								</Link>
								</li>
							</>
							:
							<li>
								<button className='btn  btn-fr-theme' onClick={e => handleLogout(e)}>Logout</button>
							</li>
						}
						</ul>
					</div>
				</div>
			</div>
		</header>
		<header className="mobile-header mobile-show">
			<div className="container">
				<div className="row mobile-row">
					<div className="col-lg-10 col-10 mobile-logo">
						<div className="logo">
							<NavLink to="/">Crypto Bot</NavLink>
						</div>
					</div>
					<div className="col-lg-6 text-right col-2 mobile-nav">
						<div className="mobile-nav-bar">
						<div id="mySidenav" className={active ? "sidenav side-nav-list" : "sidenav-toggle"}>
							<div className="mobile-menu-list side-nav-list-menu">
								<div className="mobile-nav-item">
									<button className="closebtn" onClick={toggleClass}>
										<FontAwesomeIcon icon={faClose} />
									</button>
								</div>
									<ul className='side-nav-list mobile-list-holder'>
									{
										auth ?
										<li className='main-menu-list'>
											<Link to={'/dashboard'} className='main-menu-list-item'>
												Main
											</Link>
										</li>
										:
										<li className='main-menu-list'>
											<Link to="/" className='main-menu-list-item'>
												Home
											</Link>
										</li>
									}
									<li className={`main-menu-list ${submenuVisible ? 'active' : ''}`}>
											<a href="#/" className='main-menu-list-item' onClick={toggleSubmenu}>
													Features <FontAwesomeIcon icon={faAngleDown} />
											</a>
											{submenuVisible && (
													<ul className='submenu'>
															<li className='submenu-list'>
																<Link to="/arbitrage">Arbitrage</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/automated-trading">Automated Trading (APIs)</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/portfolio-tracking">Portfolio Tracking</Link>
															</li>
															<li className='submenu-list'>
																<Link to="/market-making-trading">Market Making Trading</Link>
															</li>
															<li className='submenu-list'>
																<Link to="#/">Multi-Exchange Connectivity</Link>
															</li>
													</ul>
											)}
									</li>
									<li className='main-menu-list'>
										<Link to="/about-us" className='main-menu-list-item'>About Us</Link>
									</li>
									<li className='main-menu-list'>
										<a href="/#Exchange" className='main-menu-list-item'>Exchanges</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Pricing" className='main-menu-list-item'>Pricing</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Affiliate" className='main-menu-list-item'>Affliliate</a>
									</li>
									<li className='main-menu-list'>
										<a href="/#Security" className='main-menu-list-item'>Security</a>
									</li>
								{
									!auth ?
									<>
										<li>
										<Link to={'/login'}>
											<button className='btn  btn-fr-theme'>Login</button>
										</Link>
										</li>
										<li>
										<Link to={'/register'}>
											<button className='btn  btn-fr-theme'>Register</button>
										</Link>
										</li>
									</>
									:
									<li>
										<button className='btn  btn-fr-theme' onClick={e => handleLogout(e)}>Logout</button>
									</li>
								}
						</ul>
								</div>
							</div>
							<span className="mobile-menu-icon" onClick={toggleClass}>
								<FontAwesomeIcon icon={faBars} />
							</span>
						</div>
					</div>
				</div>
			</div>
		</header>
	</>
  )
}

export default Header