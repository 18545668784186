import React from 'react';
import _ from 'lodash';

const Pagination = ({ items, pageSize, currentPage, onPageChange }) => {
  const pageCount = items / pageSize;
  if (Math.ceil(pageCount) === 1) return null;
  const pages = _.range(1, pageCount + 1);

  return (
    <div className="row mb-40 mt-3">
      <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
              {/* <li className="page-item disabled">
                  <a className="page-link">Previous</a>
              </li> */}
              {
                pages.map((page, index) => {
                  return (
                    <li
                      key={index}
                      className={page === currentPage ? "page-item active" : "page-item"}
                    >
                      <a 
                        style={{ cursor: "pointer" }}
                        onClick={() => onPageChange(page)}
                        className="page-link"
                      >
                        {page}
                      </a>
                    </li>
                  )
                })
              }
              {/* <li className="page-item">
                  <a className="page-link" href="#">Next</a>
              </li> */}
          </ul>
      </nav>
  </div>
  );
};

export default Pagination;