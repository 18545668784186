import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      apiUrl: process.env.REACT_APP_API_URL,
      password: "",
      emailErr: "",
      passwordErr: "",
      credentialErr: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    if (email === "") {
      this.setState({
        emailErr: "Email is required!",
      });
    } else if (password === "") {
      this.setState({
        passwordErr: "Password is required!",
      });
    } else {
      const data = {
        email: email,
        password: password,
      };

      // console.log("data : ", data);

      axios
        .post(`${this.state.apiUrl}/login`, data)
        .then((res) => {
          // console.log(res.data);
          localStorage.setItem("userId", res.data.userId);
          localStorage.setItem("address", res.data.address);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("auth", res.data.token);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("mode", false); // unselected dark mode

          setTimeout(() => {
            this.setState({
              email: "",
              password: "",
            });

            // if (res.data.role === "admin") {
            window.location.replace("/");
            // } else {
            //   window.location.replace("/dasboard");
            // }
          }, 500);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            if (error.response.data) {
              this.setState({
                credentialErr: error.response.data.message,
                password: '',
              });
            }
          }
        });
    }
  };

  resetErrors = () => {
    this.setState({
      emailErr: "",
      passwordErr: "",
      credentialErr: "",
    });
  };
  render() {
    return (
      <div className="login-wrapper">
        <div className="login-left">
          <div className="login-logo">
            <a href="/">
              MarketMaking
            </a>
          </div>
          <div className="login-left-detail">
            <h2>Welcome to MarketMaking App</h2>
            <p>MarkingMaking is the platform for our members to manage their Trading through crypto bot.</p>
          </div>
        </div>
        <div className="login-right">
        <form action="" className="login-form">
            <h4>Login</h4>
            <div className="input-holder mb-20">
              <label htmlFor="">Email:</label>
              <input
                type="email"
                placeholder="Enter your Email"
                className="from-control"
                value={this.state.email}
                onClick={() =>
                  this.setState({
                    emailErr: "",
                  })
                }
                onChange={(e) =>
                  this.setState({
                    email: e.target.value,
                  })
                }
              />
            </div>
            {this.state.emailErr ? (
              <div className="text-danger">{this.state.emailErr}</div>
            ) : null}
            <div className="input-holder mb-40">
              <label htmlFor="">Password:</label>
              <input
                type="password"
                placeholder="Enter your Password"
                className="from-control"
                value={this.state.password}
                onClick={() =>
                  this.setState({
                    passwordErr: "",
                  })
                }
                onChange={(e) =>
                  this.setState({
                    password: e.target.value,
                  })
                }
              />
              {this.state.passwordErr ? (
                <div className="text-danger">{this.state.passwordErr}</div>
              ) : null}
              {this.state.credentialErr ? (
                <div className="text-danger mt-2 mb-0">
                  {this.state.credentialErr}
                </div>
              ) : null}
            </div>
            <div className="login-btn-form">
              <button
                className="btn btn-theme"
                onClick={(e) => this.handleSubmit(e)}
              >
                Login
              </button>
              <p>
                Create an account!{" "}
                <NavLink to="/register">Register</NavLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
