import React, { useContext, useEffect, useState } from 'react'
import Sidebar from '../../../components/ClientArea/sidebar/Sidebar'
import Header from '../../../components/ClientArea/header/Header'
import axios from 'axios'
import { AuthContext } from "../../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faHome, faIdCard, faPassport } from '@fortawesome/free-solid-svg-icons'
import SuccessMessageModal from '../../../components/ClientArea/Modals/SuccessModal';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Settings = ({
  toggleActive,
  setToggleActive,
  name,
  darkMode,

}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const context = useContext(AuthContext);
  const {
    alertDialogModalStatus,
    setAlertDialogModalStatus,
    setAlertDialogModalMessage
  } = context;
  const [auth] = useState(localStorage.getItem('auth'));
  const [userId] = useState(localStorage.getItem('userId'));
  const [role] = useState(localStorage.getItem('role'));
  const [passportDoc, setPassportDoc] = useState('');
  const [nationalIdDoc, setNationalIdDoc] = useState('');
  const [proofOfResidenceDoc, setProofOfResidenceDoc] = useState('');
  const [kycDocuments, setKycDocuments] = useState([]);
  const [documentId, setDocumentId] = useState(null);
  const [documentStatus, setDocumentStatus] = useState(null);
  const [passportDocErr, setPassportDocErr] = useState('');
  const [nationalIdDocErr, setNationalIdDocErr] = useState('');
  const [proofOfResidenceDocErr, setProofOfResidenceDocErr] = useState('');

  const kycUploadHandler = async (e, type) => {
    e.preventDefault();
  
    // Reset previous errors
    setPassportDocErr('');
    setNationalIdDocErr('');
    setProofOfResidenceDocErr('');
  
    // Choose the correct state and error state based on the document type
    let docState, docErrState;
    switch (type) {
      case 'Passport':
        docState = passportDoc;
        docErrState = setPassportDocErr;

        // set other doc to empty
        setNationalIdDoc('');
        setProofOfResidenceDoc('');
        break;
      case 'National ID':
        docState = nationalIdDoc;
        docErrState = setNationalIdDocErr;

        // set other doc to empty
        setPassportDoc('');
        setProofOfResidenceDoc('');
        break;
      case 'Proof of Residence':
        docState = proofOfResidenceDoc;
        docErrState = setProofOfResidenceDocErr;

        // set other doc to empty
        setPassportDoc('');
        setNationalIdDoc('');
        break;
      default:
        console.error('Invalid document type');
        return;
    }
  
    // Validate if a document is selected
    if (!docState) {
      docErrState('Please select a document to upload');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('name', docState.name);
      formData.append('type', type);
      formData.append('userId', userId);
      formData.append('file', docState)

      const response = await axios.post(`${apiUrl}/kyc-docs/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${auth}`,
        },
      });
  
      if(response.data) {
        setAlertDialogModalStatus(true);
        setAlertDialogModalMessage('Document uploaded successfully');
        getKycDocuments();
    
        // Reset the document state after successful upload
        switch (type) {
          case 'Passport':
            setPassportDoc('');
            break;
          case 'National ID':
            setNationalIdDoc('');
            break;
          case 'Proof of Residence':
            setProofOfResidenceDoc('');
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error('Error uploading document:', error);
  
      // Handle specific error cases if needed
      if (error.response) {
        docErrState(error.response.data.message);
      } else {
        docErrState('Error uploading document');
      }
    }
  };

  const handlePopupModal = (e, id, title, oldStatus, newStatus) => {
    e.preventDefault();

    // setting the id, title, new status for modals to use it
    setDocumentId(id);
    setDocumentStatus(newStatus)
  }

  const handleStatusChange = async (e) => {
    e.preventDefault();

    if(documentId && documentStatus) {
      try {
        // Make an API request to change the KYC document status
        const response = await axios.put(
          `${apiUrl}/kyc-docs/${documentId}/update`,
          { 
            role: role, 
            status: documentStatus 
          },
          {
            headers: {
              Authorization: `Bearer ${auth}`
            }
          }
        );
  
        if (response.data.success) {
          setAlertDialogModalStatus(true);
          setAlertDialogModalMessage('KYC document status updated successfully');
          resetValuesAndFetchLatestDocs();
          getKycDocuments();
        } else {
          // Handle failure
          console.error('Failed to update KYC document status');
        }
      } catch (error) {
        console.error('Error changing KYC document status:', error);
      }
    }
  };

  const handleDeletePermanently = async (e) => {
    e.preventDefault();
    
    try {
      // if (documentStatus === 'Deleted') {
      //   const confirmDelete = window.confirm('This document is already marked as Deleted. Do you want to remove it permanently?');
  
      //   if (!confirmDelete) {
      //     // If the user cancels the operation, do nothing
      //     return;
      //   }
      // }
  
      // Make a DELETE request to the server
      const response = await axios.delete(`${apiUrl}/kyc-docs/${documentId}/del?role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
  
      if (response.data.success) {
        setAlertDialogModalStatus(true);
        setAlertDialogModalMessage('KYC document removed permanently');
        resetValuesAndFetchLatestDocs();
        getKycDocuments();
      }
    } catch (error) {
      // Handling unexpected errors, e.g., to show a generic error message or log the error
      console.error('Unexpected error:', error);
    }
  };

  const resetValuesAndFetchLatestDocs = () => {
    setDocumentId('');
    setDocumentStatus('');
  }

  const getKycDocuments = async () => {
    axios.get(`${apiUrl}/kyc-docs?userId=${userId}&role=${role}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((response) => {
        if(response.data) {
          setKycDocuments(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching KYC documents:', error);
      });
  }

  const handleKycDocView = (e, docUrl) => {
    e.preventDefault();

    // Open the document in a new tab while uploading on the backend server
    // window.open(apiUrl + "/" + docUrl, '_blank');

    window.open(docUrl, '_blank');
  };  

  useEffect(() => {
    setAlertDialogModalStatus(false);
    getKycDocuments();
  }, [])

  // console.log('kyc docs:', kycDocuments);
  // console.log("apiUrl:", apiUrl);
  // console.log('documentId', documentId)
  // console.log('documentStatus', documentStatus)
  // console.log('documentTitle', documentTitle)
  return (
    <AuthContext.Consumer>
    {(context) => (
      <>
      {
        alertDialogModalStatus ? 
        <SuccessMessageModal />
        : null
      }
      <div className={`${toggleActive ? "main-wrapper z-aside-structure aside-active" : "main-wrapper z-aside-structure"} ${context.darkMode ? "dark-mode-wrapper" : ""}`}>
      <Sidebar 
        toggleActive={toggleActive}
        setToggleActive={setToggleActive}
      />
      <div className="z-theme-wrapper">
        <Header 
          toggleActive={toggleActive}
          setToggleActive={setToggleActive}
          name={name}
          darkMode={darkMode}
        />
          <div className='section-padding'>
            <div className="container-fluid">
              <div className="row mb-30">
                <div className="col-lg-12">
                  <div className="page-bradcrumbs">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">All KYC Requests</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              <div className='setting-right-main-card mb-50'>
                <div className="setting-right-main-card-header">
                  <h3>Document Upload</h3>
                  <p>To verify your identity, please upload any of your document.</p>
                </div>
                <div className="row mb-30">
                  <div className="col-lg-12">
                    <div className="kyc-tabs-holder">
                      <div className="subscription-inner-tabs">
                        <ul className="nav nav-pills portfolio-mng-table" id="pills-tab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-Passport-tab" data-bs-toggle="pill" data-bs-target="#pills-Passport" type="button" role="tab" aria-controls="pills-Passport" aria-selected="true">
                              <FontAwesomeIcon icon={faPassport} />
                              <span>Passport</span>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-nation-id-tab" data-bs-toggle="pill" data-bs-target="#pills-nation-id" type="button" role="tab" aria-controls="pills-nation-id" aria-selected="false">
                              <FontAwesomeIcon icon={faIdCard} />
                              <span>National ID</span>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-Proof-residence-tab" data-bs-toggle="pill" data-bs-target="#pills-Proof-residence" type="button" role="tab" aria-controls="pills-Proof-residence" aria-selected="false">
                              <FontAwesomeIcon icon={faHome} />
                              <span>Proof Of Residence</span>
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div className="tab-pane fade show active" id="pills-Passport" role="tabpanel" aria-labelledby="pills-Passport-tab" tabIndex="0">
                            <form onSubmit={e => kycUploadHandler(e, 'Passport')}>
                              <div className="kyc-tab-detail">
                                <div className="row mb-30">
                                  <div className="col-lg-8">
                                    <h3>To avoid delays when verifying account, Please make sure bellow:</h3>
                                    <ul>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Chosen credential must not be expaired.</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Document should be good condition and clearly visible</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Make sure that there is no light glare on the card.</span>
                                      </li>
                                    </ul>
                                    <p>If you have any issues in uploading your documents, kindly reach out to us.</p>
                                  </div>
                                  <div className="col-lg-4">
                                  <div className="input-holder upload-v-img">
                                    <label htmlFor="">Upload Image</label>
                                    <div className="upload-btn-wrapper">
                                      <button className="btn">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" className="svg-inline--fa fa-upload " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path></svg>
                                        Upload Passport </button>
                                        <input className="chakra-input form-control" type="file" onChange={e => setPassportDoc(e.target.files[0])} />
                                        {
                                          passportDoc ? 
                                          <div className='my-2'>
                                            <p>{passportDoc.name}</p>
                                          </div>
                                          :
                                          null
                                        }
                                        {
                                          passportDocErr ? 
                                          <div className='mt-4'>
                                            <p className='text-danger'>{passportDocErr}</p>
                                          </div>
                                          :
                                          null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <button className='btn btn-theme btn-lg' type='submit'>Upload</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="tab-pane fade" id="pills-nation-id" role="tabpanel" aria-labelledby="pills-nation-id-tab" tabIndex="0">
                            <form onSubmit={e => kycUploadHandler(e, 'National ID')}>
                              <div className="kyc-tab-detail">
                                <div className="row mb-30">
                                  <div className="col-lg-8">
                                    <h3>To avoid delays when verifying account, Please make sure bellow:</h3>
                                    <ul>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Chosen credential must not be expaired.</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Document should be good condition and clearly visible</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Make sure that there is no light glare on the card.</span>
                                      </li>
                                    </ul>
                                    <p>If you have any issues in uploading your documents, kindly reach out to us.</p>
                                  </div>
                                  <div className="col-lg-4">
                                  <div className="input-holder upload-v-img">
                                    <label htmlFor="">Upload Image</label>
                                    <div className="upload-btn-wrapper">
                                      <button className="btn">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" className="svg-inline--fa fa-upload " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path></svg>
                                        Upload National ID </button>
                                        <input className="chakra-input form-control" type="file" onChange={e => setNationalIdDoc(e.target.files[0])} />
                                        {
                                          nationalIdDoc ? 
                                          <div className='my-2'>
                                            <p>{nationalIdDoc.name}</p>
                                          </div>
                                          :
                                          null
                                        }
                                        {
                                          nationalIdDocErr ? 
                                          <div className='mt-4'>
                                            <p className='text-danger'>{nationalIdDocErr}</p>
                                          </div>
                                          :
                                          null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <button className='btn btn-theme btn-lg' type='submit'>Upload</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="tab-pane fade" id="pills-Proof-residence" role="tabpanel" aria-labelledby="pills-Proof-residence-tab" tabIndex="0">
                            <form onSubmit={e => kycUploadHandler(e, 'Proof of Residence')}>
                              <div className="kyc-tab-detail">
                                <div className="row mb-30">
                                  <div className="col-lg-8">
                                    <h3>To avoid delays when verifying account, Please make sure bellow:</h3>
                                    <ul>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Chosen credential must not be expaired.</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Document should be good condition and clearly visible</span>
                                      </li>
                                      <li>
                                        <div className='kyc-check-holder'>
                                          <FontAwesomeIcon icon={faCheck} />
                                        </div> 
                                        <span>Make sure that there is no light glare on the card.</span>
                                      </li>
                                    </ul>
                                    <p>If you have any issues in uploading your documents, kindly reach out to us.</p>
                                  </div>
                                  <div className="col-lg-4">
                                  <div className="input-holder upload-v-img">
                                    <label htmlFor="">Upload Image</label>
                                    <div className="upload-btn-wrapper">
                                      <button className="btn">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" className="svg-inline--fa fa-upload " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path></svg>
                                        Upload Proof of Residence </button>
                                        <input className="chakra-input form-control" type="file" onChange={e => setProofOfResidenceDoc(e.target.files[0])} />
                                        {
                                          proofOfResidenceDoc ? 
                                          <div className='my-2'>
                                            <p>{proofOfResidenceDoc.name}</p>
                                          </div>
                                          :
                                          null
                                        }
                                        {
                                          proofOfResidenceDocErr ? 
                                          <div className='mt-4'>
                                            <p className='text-danger'>{proofOfResidenceDocErr}</p>
                                          </div>
                                          :
                                          null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <button className='btn btn-theme btn-lg' type='submit'>Upload</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-inner-title">
                    <h2>All Kyc Requests</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="subscription-main-tabs">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-Pending-kyc-tab" data-bs-toggle="pill" data-bs-target="#pills-Pending-kyc" type="button" role="tab" aria-controls="pills-Pending-kyc" aria-selected="true">Pending</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-Verified-kyc-tab" data-bs-toggle="pill" data-bs-target="#pills-Verified-kyc" type="button" role="tab" aria-controls="pills-Verified-kyc" aria-selected="false">Verified</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-Rejected-kyc-tab" data-bs-toggle="pill" data-bs-target="#pills-Rejected-kyc" type="button" role="tab" aria-controls="pills-Rejected-kyc" aria-selected="false">Rejected</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-Deleted-kyc-tab" data-bs-toggle="pill" data-bs-target="#pills-Deleted-kyc" type="button" role="tab" aria-controls="pills-Deleted-kyc" aria-selected="false">Deleted</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-Pending-kyc" role="tabpanel" aria-labelledby="pills-Pending-kyc-tab" tabIndex="0">
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Pending Requests</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              kycDocuments
                              .filter(doc => doc.status === "Pending").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Title</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Type</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Verify</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {kycDocuments
                                    .filter(doc => doc.status === "Pending")
                                    .map((document, index) => (
                                    <tr key={index} className="tr-bg-dark">
                                      <td>{index + 1}</td>
                                      <td>{document.title}</td>
                                      <td>{document.type}</td>
                                      <td>
                                        <div className="account-type-td">
                                          <span className={`badge-${document.status === 'Pending' ? 'warning' : 'success'} badge`}>
                                            {document.status}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{moment(document.createdAt).format("LLL")}</td>
                                      <td>{moment(document.updatedAt).format("LLL")}</td>
                                      <td>
                                        <div className="connect-wallet-td">
                                          <ul>
                                          <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status, 'Verified')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Rejected')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Deleted')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="connect-wallet-td">
                                          <ul>
                                            <li>
                                              <button className='connect-btn' onClick={e => handleKycDocView(e, document.docUrl)}>View</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Verified-kyc" role="tabpanel" aria-labelledby="pills-Verified-kyc-tab" tabIndex="0">
                     <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Verified Requests</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              kycDocuments
                              .filter(doc => doc.status === "Verified").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Title</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Type</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Verify</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {kycDocuments
                                  .filter(doc => doc.status === "Verified")
                                  .map((document, index) => (
                                  <tr key={index} className="tr-bg-dark">
                                    <td>{index + 1}</td>
                                    <td>{document.title}</td>
                                    <td>{document.type}</td>
                                    <td>
                                      <div className="account-type-td">
                                        <span className={`badge-${document.status === 'Pending' ? 'warning' : 'success'} badge`}>
                                          {document.status}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{moment(document.createdAt).format("LLL")}</td>
                                    <td>{moment(document.updatedAt).format("LLL")}</td>
                                    <td>
                                      <div className="connect-wallet-td">
                                      <ul>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Rejected')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                            </button>
                                          </li>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Deleted')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="connect-wallet-td">
                                        <ul>
                                          <li>
                                            <button className='connect-btn' onClick={e => handleKycDocView(e, document.docUrl)}>View</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Rejected-kyc" role="tabpanel" aria-labelledby="pills-Rejected-kyc-tab" tabIndex="0">
                      <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Rejected Requests</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              kycDocuments
                              .filter(doc => doc.status === "Rejected").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Title</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Type</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Verify</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {kycDocuments
                                  .filter(doc => doc.status === "Rejected")
                                  .map((document, index) => (
                                  <tr key={index} className="tr-bg-dark">
                                    <td>{index + 1}</td>
                                    <td>{document.title}</td>
                                    <td>{document.type}</td>
                                    <td>
                                      <div className="account-type-td">
                                        <span className={`badge-${document.status === 'Pending' ? 'warning' : 'success'} badge`}>
                                          {document.status}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{moment(document.createdAt).format("LLL")}</td>
                                    <td>{moment(document.updatedAt).format("LLL")}</td>
                                    <td>
                                      <div className="connect-wallet-td">
                                        <ul>
                                          <li>
                                            <button className='connect-direct'  onClick={e => handlePopupModal(e, document._id, document.title, document.status, 'Verified')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                            </button>
                                          </li>
                                          <li>
                                            <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Deleted')} data-bs-toggle="modal" data-bs-target="#DeleteModal">
                                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="connect-wallet-td">
                                        <ul>
                                          <li>
                                            <button className='connect-btn' onClick={e => handleKycDocView(e, document.docUrl)}>View</button>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Deleted-kyc" role="tabpanel" aria-labelledby="pills-Deleted-kyc-tab" tabIndex="0">
                     <div className="row mb-50">
                        <div className="col-lg-12">
                          <div className="ds-card-title">
                            <h3>Deleted Requests</h3>
                          </div>
                          <div className="table-responsive theme-table" id="tabel-res-scroll-bar">
                            {
                              kycDocuments
                              .filter(doc => doc.status === "Deleted").length > 0 ?
                              <table  className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Sr.#	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Title</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Type</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Status</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Created At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Updated At	</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Action</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                    <th scope="col">
                                      <div className="table-head-th">
                                        <span>Verify</span>
                                        <button>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                            <path  d="M10.063 2.33301L10.0036 2.337C9.79008 2.36597 9.62553 2.54902 9.62553 2.77051L9.625 10.1742L7.74673 8.29724L7.69767 8.25489C7.52634 8.1279 7.28327 8.14213 7.12798 8.29748C6.95724 8.46845 6.95736 8.74542 7.12828 8.91622L9.75549 11.5412L9.80455 11.5836C9.97588 11.7106 10.2189 11.6963 10.3742 11.541L12.997 8.91598L13.0393 8.86687C13.1664 8.69554 13.1521 8.45247 12.9967 8.29724L12.9477 8.25489C12.7763 8.1279 12.5333 8.14213 12.378 8.29748L10.5 10.1765L10.5005 2.77051L10.4965 2.71114C10.4676 2.4976 10.2845 2.33301 10.063 2.33301ZM3.62507 2.46116L1.00313 5.08327L0.960774 5.13234C0.833695 5.30362 0.847823 5.54667 1.00315 5.70199L1.05222 5.74435C1.2235 5.87145 1.46655 5.85728 1.62187 5.70197L3.49825 3.82466L3.49864 11.2317L3.50263 11.2911C3.5316 11.5046 3.71465 11.6692 3.93614 11.6692L3.9955 11.6652C4.20905 11.6362 4.37364 11.4532 4.37364 11.2317L4.37325 3.82582L6.25328 5.70232L6.30239 5.74463C6.47378 5.87151 6.71679 5.85716 6.87196 5.70165C7.04264 5.53061 7.04235 5.2536 6.87132 5.08293L4.24346 2.46081L4.19439 2.41853C4.02311 2.29171 3.78027 2.30595 3.62507 2.46116Z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {kycDocuments
                                    .filter(doc => doc.status === "Deleted")
                                    .map((document, index) => (
                                    <tr key={index} className="tr-bg-dark">
                                      <td>{index + 1}</td>
                                      <td>{document.title}</td>
                                      <td>{document.type}</td>
                                      <td>
                                        <div className="account-type-td">
                                          <span className={`badge-${document.status === 'Pending' ? 'warning' : 'success'} badge`}>
                                            {document.status}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{moment(document.createdAt).format("LLL")}</td>
                                    <td>{moment(document.updatedAt).format("LLL")}</td>
                                      <td>
                                        <div className="connect-wallet-td">
                                        <ul>
                                            <li>
                                              <button className='connect-direct'  onClick={e => handlePopupModal(e, document._id, document.title, document.status, 'Verified')} data-bs-toggle="modal" data-bs-target="#ApprovedKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" className="svg-inline--fa fa-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Rejected')} data-bs-toggle="modal" data-bs-target="#RejectKyc">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" className="svg-inline--fa fa-ban" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg>
                                              </button>
                                            </li>
                                            <li>
                                              <button className='connect-direct' onClick={e => handlePopupModal(e, document._id, document.title, document.status,  'Deleted')} data-bs-toggle="modal" data-bs-target="#PermaDeleteModal">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" className="svg-inline--fa fa-trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3   22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="connect-wallet-td">
                                          <ul>
                                            <li>
                                              <button className='connect-btn' onClick={e => handleKycDocView(e, document.docUrl)}>View</button>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              :
                              <div className='text-center my-5'>
                                <p className='text-gray'>No data found.</p>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            <div className="modal fade" id="DeleteModal" tabIndex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="DeleteModalLabel">Mark KYC Request As Deleted</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure? You can't undo this action afterwards.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetValuesAndFetchLatestDocs()}>Cancel</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Modal */}
            {/* Delete Perma Modal */}
            <div className="modal fade" id="PermaDeleteModal" tabIndex="-1" aria-labelledby="PermaDeleteModalLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="PermaDeleteModalLabel">Permanently Remove KYC Request</h1>
                  </div>
                  <div className="modal-body">
                    <p>This document is already marked as Deleted. Do you want to remove it permanently?</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetValuesAndFetchLatestDocs()}>Cancel</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={e => handleDeletePermanently(e)}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Perma Modal */}
            {/* Reject Modal */}
            <div className="modal fade" id="RejectKyc" tabIndex="-1" aria-labelledby="RejectKycLabel" aria-hidden="true">
              <div className="modal-dialog delete-modal-holder">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="RejectKycLabel">Reject KYC Request</h1>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure? You can't undo this action afterwards.</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetValuesAndFetchLatestDocs()}>Cancel</button>
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>Reject</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Reject Modal */}
          </div>
        </div>
      </div>
      {/* Approved Modal */}
      <div className="modal fade" id="ApprovedKyc" tabIndex="-1" aria-labelledby="ApprovedKycLabel" aria-hidden="true">
        <div className="modal-dialog delete-modal-holder">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="ApprovedKycLabel">Approve KYC Request</h1>
            </div>
            <div className="modal-body">
              <p>Are you sure? You can't undo this action afterwards.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => resetValuesAndFetchLatestDocs()}>
                Cancel
              </button>
              <button type="button" className="btn btn-theme" data-bs-dismiss="modal" onClick={e => handleStatusChange(e)}>
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Approved Modal */}
      </>
      )}
    </AuthContext.Consumer>
  )
}

export default Settings