import React from "react";
import MarketMaking from "../../../components/ClientArea/market-making/MarketMaking-Two";
import "../../../css/Main.css";
import "../../../css/responsive.css";

function Home({
  toggleActive,
  setToggleActive,
  name,
}) {
  return (
    <div className="main-wrapper">
      <MarketMaking
        toggleActive={toggleActive}
        setToggleActive={setToggleActive}
        name={name}
      />
    </div>
  );
}

export default Home;
