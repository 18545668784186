import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import PortfolioTrackingBanner from "../../../assets/images/portfolio-tracking/portfolio-tracking.webp"
import AutoTrade from "../../../assets/images/auto-trading/auto-trade.svg"
import Analyze from "../../../assets/images/auto-trading/analyze.svg"
import PortfolioTrack3 from "../../../assets/images/portfolio-tracking/portfolio-tracking-3.webp"
import SupportAuto from "../../../assets/images/auto-trading/247.svg"
import BannerMainImage from "../../../assets/images/banner-coins/banner-laptop.png"
import AvailableAutoImg from "../../../assets/images/auto-trading/Screenshot_1.png"
import Loader from "../../../components/Frontend/loader/Loader";
import { Link } from "react-router-dom";


function PortfolioTracking() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />

      <Header />
      {/* Main Banner */}
      <section className="main-banner section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-caption-holder">
                <h2>The best home for your crypto portfolio.</h2>
                <p>Track portfolio performance and see the distribution of your funds across exchanges, accounts, and coins.</p>
                <div className="banner-btn">
                  <Link to="/login" className="btn btn-fr-theme btn-lg">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-main-img">
                <img src={PortfolioTrackingBanner} alt="Banner-Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main Banner */}

      {/* Exchange Arbitrage */}
			<section className="total-vol-holder section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={AutoTrade} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Copy</h4>
									<p>A pro</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={Analyze} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Analyze</h4>
									<p>Automatically</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={SupportAuto} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>24/7</h4>
									<p>In the cloud</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Exchange Arbitrage */}

      {/* Cross Exchange */}
			<section className="section-padding cross-exchange-holder">
				<div className="container">
					<div className="row mb-100 align-items-center justify-content-between">
						<div className="col-lg-6">
							<img src={BannerMainImage} alt="Cross Exchange" />
						</div>
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Manage exchanges with ease</h2>
								<p>Connect all your crypto exchanges and manage them in one place.</p>
								<ul className="main-heading-points">
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>See your holdings at a glance and watch your portfolio grow.</p>
									</li>
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>Switch easily between exchanges.
</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Seamless performance tracking</h2>
								<p>Powerful, user-friendly reporting tools to help you trade profitably:</p>
								<ul className="main-heading-points">
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>Access historical performance data.</p>
									</li>
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>See trade progress and results in real-time.</p>
									</li>
									<li>
										<span>
											<svg aria-hidden="true" className="sc-koXPp lhcufv sc-gFAWRd kSEcVi" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="ArrowRightCircleIcon"><mask fill="black" id="arrowSvgMask"><rect fill="white" height="100%" width="100%"></rect><path d="M20 22C20 21.4477 19.5523 21 19 21H13C12.4477 21 12 20.5523 12 20C12 19.4477 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18V14.4142C20 13.5233 21.0771 13.0771 21.7071 13.7071L27.2929 19.2929C27.6834 19.6834 27.6834 20.3166 27.2929 20.7071L21.7071 26.2929C21.0771 26.9229 20 26.4767 20 25.5858V22Z"></path></mask><circle cx="20" cy="20" fill="currentColor" mask="url(#arrowSvgMask)" r="20"></circle></svg>
										</span>
										<p>Track portfolio performance across your exchanges.</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="portfolio-track-img text-center">
								<img src={PortfolioTrack3} alt="Portfolio Tracking" />
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Cross Exchange */}

      {/* Avaiable Anytime */}
			<section className="section-padding available-auto-holder">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="main-heading text-center">
								<h2>Available anytime. Anywhere.</h2>
								<p>Hosted in the cloud, MarketMaking is available 24/7. Protect and monitor your assets, even while you’re logged out. Access your MarketMaking from any device, including web, phone, tablet and even your smartwatch.</p>
								<img src={AvailableAutoImg} alt="Available Auto Image" />
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Avaiable Anytime */}

      <Footer />
    </div>
  );
}

export default PortfolioTracking;
