import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import ArbitrageBanner from "../../../assets/images/arbitrage/arbitrage-banner.svg"
import ArbitrageExchange from "../../../assets/images/arbitrage/arb-exchange.svg"
import ArbitrageMarket from "../../../assets/images/arbitrage/arb-market.svg"
import ArbitrageProfit from "../../../assets/images/arbitrage/arb-profit.svg"
import CrossExchange from "../../../assets/images/arbitrage/cross-exchange.webp"
import ProfitExchangeArb from "../../../assets/images/arbitrage/profit-arbitrage.webp"
import Loader from "../../../components/Frontend/loader/Loader";
import { Link } from "react-router-dom";

function Arbitrage() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />
      <Header />
      {/* Main Banner */}
      <section className="main-banner section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-caption-holder">
                <h2>Exchange prices are inefficient. You can profit from that</h2>
                <p>Prices between exchanges and pairs differ. Profit from that. Without withdrawing your funds from your exchange.</p>
                <div className="banner-btn">
                  <Link to="/login" className="btn btn-fr-theme btn-lg">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-main-img">
                <img src={ArbitrageBanner} alt="Banner-Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main Banner */}

      {/* Exchange Arbitrage */}
			<section className="total-vol-holder section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={ArbitrageExchange} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Exchange</h4>
									<p>Arbitrage differences</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={ArbitrageMarket} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Market</h4>
									<p>Arbitrage</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={ArbitrageProfit} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Profit</h4>
									<p>From price</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Exchange Arbitrage */}

      {/* Cross Exchange */}
			<section className="section-padding cross-exchange-holder">
				<div className="container">
					<div className="row mb-80 align-items-center justify-content-between">
						<div className="col-lg-6">
							<img src={CrossExchange} alt="Cross Exchange" />
						</div>
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Exchange Arbitrage, without withdrawls</h2>
								<p>Arbitrage between exchanges without sending funds from one exchange to another. Connect exchanges where you have funds, enable Exchange Arbitrage, and let your Hopper search for Arbitrage opportunities.</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Profit from inefficiencies on your exchange</h2>
								<p>Market Arbitrage, also called triangular arbitrage, enables you to profit from price differences between pairs on the exchange itself.</p>
							</div>
						</div>
						<div className="col-lg-6">
							<img src={ProfitExchangeArb} alt="Cross Exchange" />
						</div>
					</div>
				</div>
			</section>
      {/* Cross Exchange */}

      {/* Arbitrage Feature */}
			<section className="arb-feature-section section-padding">
				<div className="container">
					<div className="row mb-50">
						<div className="col-lg-12">
							<div className="main-heading text-center">
								<h2>Other Features</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="AutomaticTradingIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M35.168 5.59v11.87a2.628 2.628 0 01-2.637 2.638h-8.574l-3.785 6.593a.198.198 0 01-.344 0l-3.785-6.593H7.469a2.628 2.628 0 01-2.637-2.637V5.59a2.628 2.628 0 012.637-2.637H32.53a2.628 2.628 0 012.637 2.637zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M27.016 10.363a.967.967 0 010 1.664c-1.45.883-4.047 2.137-7.016 2.137-2.969 0-5.566-1.254-7.016-2.137a.967.967 0 010-1.664c1.45-.883 4.047-2.136 7.016-2.136 2.969 0 5.566 1.253 7.016 2.136zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M20 14.164a2.97 2.97 0 10-.002-5.94 2.97 2.97 0 00.002 5.94zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M20 32.777a1.666 1.666 0 100-3.332 1.667 1.667 0 100 3.332zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M28.73 37.063a1.666 1.666 0 100-3.333 1.667 1.667 0 100 3.333zm0 0M11.27 28.492a1.667 1.667 0 10.001-3.334 1.667 1.667 0 00-.001 3.334zm0 0M1.668 33.887l1.11-1.11a4.71 4.71 0 016.667 0l1.11 1.11a4.704 4.704 0 006.668 0M23.332 28.332l.559-.555a4.706 4.706 0 016.664 0l1.113 1.11a4.699 4.699 0 006.664 0" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path></g></svg>
								<h4>Automatic Trading</h4>
								<p>Bots outperform humans</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="SocialTradingIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M3.965 28.008l7.773-7.778 4.375 4.375 7.774-7.777 4.375 4.375 7.773-7.773" stroke="#00b2c8" stroke-width="1.333332"></path><path d="M24.191 5.129L20.074 3.03a.129.129 0 00-.148 0L15.809 5.13 12.44 3.21a.12.12 0 00-.078-.027.124.124 0 00-.078.03c-.023.016-.035.044-.043.071a.146.146 0 00.012.082L13.71 7.75h12.578l1.457-4.383a.146.146 0 00.012-.082c-.008-.027-.02-.055-.043-.07a.124.124 0 00-.078-.031.12.12 0 00-.078.027zm0 0" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M36.035 13.875v16.523H23.887l-3.703 5.559a.237.237 0 01-.079.074.245.245 0 01-.21 0 .237.237 0 01-.079-.074l-3.703-5.52H6.891a2.919 2.919 0 01-2.918-2.914V11h29.156c.766 0 1.5.305 2.043.844.543.535.855 1.265.863 2.031zm0 0" stroke="currentColor" stroke-width="1.333332"></path></g></svg>
								<h4>Social Trading</h4>
								<p>Trade like a pro, without being one</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="TrailingOrdersIcon"><g fill="none" stroke-miterlimit="10" stroke-width="1.4"><path d="M20 20.996v-8.512" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M22.535 20.996h-5.07a.951.951 0 00-.95.953V35.48c0 .528.426.954.95.954h5.07c.524 0 .95-.426.95-.954V21.95a.951.951 0 00-.95-.954zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M8.047 36.434V20" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M20 4.605V3.566" stroke="#00b2c8" stroke-linecap="round" stroke-width="1.333332"></path><path d="M20 10.21a1.666 1.666 0 100-3.331 1.667 1.667 0 100 3.332zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M8.047 3.566v2.989" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M10.582 6.555H5.516a.951.951 0 00-.953.949V18.55c0 .523.425.949.953.949h5.066a.954.954 0 00.953-.95V7.505c0-.524-.43-.95-.953-.95zm0 0" stroke="currentColor" stroke-width="1.333332"></path><path d="M31.953 36.434V20M31.953 3.566v5.477" stroke="currentColor" stroke-linecap="round" stroke-width="1.333332"></path><path d="M34.484 9.543h-5.066a.954.954 0 00-.953.95v8.058c0 .523.43.949.953.949h5.066a.951.951 0 00.954-.95v-8.058a.951.951 0 00-.954-.949zm0 0" stroke="currentColor" stroke-width="1.333332"></path></g></svg>
								<h4>Trailing Orders</h4>
								<p>Better buys & sells, the easy way</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="arb-feature-card">
								<svg aria-hidden="true" className="sc-koXPp dkGycb" focusable="false" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" data-testid="DollarCostAveragingIcon"><path d="M10.375 13.379H2.75a.954.954 0 00-.953.953v21.29c0 .526.426.952.953.952h7.625a.952.952 0 00.953-.953V14.332a.954.954 0 00-.953-.953zm0 0" fill="none" stroke="#00b2c8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M23.813 23.973h-7.625a.951.951 0 00-.954.949v10.7c0 .526.426.952.954.952h7.625a.952.952 0 00.953-.953v-10.7a.951.951 0 00-.953-.948zm0 0" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M37.238 31.563h-7.625a.952.952 0 00-.953.953v3.113c0 .527.426.953.953.953h7.625a.952.952 0 00.953-.953v-3.113a.952.952 0 00-.953-.953zm0 0" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M7.648 2.848L29.141 20.43l6.347-6.348" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.333332"></path><path d="M36.883 12.64l-1.938 6.419a6.408 6.408 0 00-1.66-2.606 6.352 6.352 0 00-2.695-1.512zm0 0" fill="currentColor"></path><path d="M27.492 10.68a1.669 1.669 0 000-3.336 1.667 1.667 0 100 3.336zm0 0" fill="none" stroke="#00b2c8" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.333332"></path></svg>
								<h4>DCA</h4>
								<p>Don’t worry buying at the wrong moment</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* Arbitrage Feature */}


      <Footer />
    </div>
  );
}

export default Arbitrage;
