import React, { useEffect, useState } from "react";
import "../../../assets/css/style.css";
import Header from "../../../components/Frontend/header/Header";
import Footer from "../../../components/Frontend/footer/Footer"
import MarketMakingBanner from "../../../assets/images/market-making/market-making-banner.svg"
import ProvideImg from "../../../assets/images/market-making/provide.svg"
import ProfitImg from "../../../assets/images/market-making/profit.svg"
import MakeImg from "../../../assets/images/market-making/make.svg"
import IdealTrading from "../../../assets/images/market-making/ideal-trading.webp"
import AdaptMarket from "../../../assets/images/market-making/adapt-market.webp"
import DropTrade from "../../../assets/images/market-making/drop-order.webp"
import Loader from "../../../components/Frontend/loader/Loader";
import { Link } from "react-router-dom";


function MarketMaking() {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 50;

      if (scrollY > scrollThreshold && !showBoxShadow) {
        setShowBoxShadow(true);
      } else if (scrollY <= scrollThreshold && showBoxShadow) {
        setShowBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showBoxShadow]);
  return (
    <div className={`wrapper ${showBoxShadow ? 'box-shadow' : ''}`}>
      <Loader />
      <Header />
      {/* Main Banner */}
      <section className="main-banner section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="banner-caption-holder">
                <h2>Make the markets, then profit from it</h2>
                <p>Trade on the spread, make the markets. It’s a win-win for everybody.</p>
                <div className="banner-btn">
                  <Link to="/login " className="btn btn-fr-theme btn-lg">Get Started</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner-main-img">
                <img src={MarketMakingBanner} alt="Banner-Image" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Main Banner */}

      {/* Exchange Arbitrage */}
			<section className="total-vol-holder section-padding">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={ProvideImg} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Provide</h4>
									<p>Liquidity</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={ProfitImg} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Profit</h4>
									<p>From big spreads</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="arb-exchnage-card">
								<div className="arb-exc-left">
									<img src={MakeImg} alt="Arb Exchange" />
								</div>
								<div className="arb-exc-right">
									<h4>Make</h4>
									<p>The markets</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Exchange Arbitrage */}

      {/* Cross Exchange */}
			<section className="section-padding cross-exchange-holder">
				<div className="container">
					<div className="row mb-80 align-items-center justify-content-between">
						<div className="col-lg-6">
							<img src={AdaptMarket} alt="Cross Exchange" />
						</div>
						<div className="col-lg-5">
							<div className="main-heading">
								<h2>Adapt to changing markets</h2>
								<p>Market trends change, so does your trading strategy. Automatically switch between strategies with different market trends. Use indicators to recognize market trends, and configure different trading strategies per market trend.</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-between">
						<div className="col-lg-6">
							<div className="main-heading">
								<h2>Find your ideal trading opportunity. Automatically, 24/7</h2>
								<p>Don’t let the fear of a market shift keep you up at night. With our A.I., your bot can automatically recognise trends and switch to a better strategy, so you can rest easy. Create or download strategies and let your CryptoBot watch the markets for you, and buy or sell based on your parameters.</p>
							</div>
						</div>
						<div className="col-lg-6">
							<img src={IdealTrading} alt="Cross Exchange" />
						</div>
					</div>
				</div>
			</section>
      {/* Cross Exchange */}

      {/* Avaiable Anytime */}
			<section className="section-padding available-auto-holder drag-drop-holder">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<div className="main-heading text-center">
								<h2>Drag and drop order book.</h2>
								<p>Markets change fast, so you need to be able to change your order price faster. Easily create buy/sell orders, then drag and drop them to the desired place in the order book when things change.</p>
								<img src={DropTrade} alt="Available Auto Image" />
							</div>
						</div>
					</div>
				</div>
			</section>
      {/* Avaiable Anytime */}

      <Footer />
    </div>
  );
}

export default MarketMaking;
