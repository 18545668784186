import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../../../context/AuthContext'

const SuccessMessageModal = () => {
  const context = useContext(AuthContext);
  const {
    alertDialogModalStatus,
    setAlertDialogModalStatus,
    alertDialogModalMessage,
    setAlertDialogModalMessage
  } = context;

  // programmatically closing the alert message
  useEffect(() => {
    if(alertDialogModalStatus) {
      setTimeout(() => {
        setAlertDialogModalStatus(false);
        setAlertDialogModalMessage('');
      }, 5000);
    }
  }, [alertDialogModalStatus, alertDialogModalMessage])

  // click on the x will close the alert message
  const closeAlert = (e) => {
    e.preventDefault();

    setAlertDialogModalStatus(false);
    setAlertDialogModalMessage('');
  }

  return (
  <div className="alert alert-info mm-alert-box" role="success">
    <span className="mm-alert-message">{alertDialogModalMessage}</span>
    <button type="button" className="close alert-close-btn" aria-label="Close" onClick={e => closeAlert(e)}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  )
}

export default SuccessMessageModal